import { useCallback, useEffect, useMemo, useState } from "react";

import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";

import displayToast from "components/Toast/displayToast";

import useWorkspaceAppsApiConsumer from "consumers/useWorkspaceAppsApiConsumer";

import { setLoadingAction } from "context/App/App.actions";
import { useAppContext, useAppDispatch } from "context/App/App.context";
import { useAppSelectionContext } from "context/App/AppSelectionContext";

import { useUpdateSelectedWorkspace } from "layouts/views/WorkspaceRootLayout";

import { useAdministrationDeps } from "../Administration";
import { determineInitialSelectedAppId } from "../util/determineInitialSelectedApp";

export default function useAppsGeneralViewConsumer() {
  const { administrationService } = useAdministrationDeps();
  const { fetchAllApps } = useWorkspaceAppsApiConsumer();
  const { appKey } = useParams();
  const { updateSelectedWorkspace } = useUpdateSelectedWorkspace();
  const appDispatch = useAppDispatch();

  const { apps, loading: appsLoading } = useAppContext();
  const { selectedWorkspace, selectedApp: globalSelectedApp } = useAppSelectionContext();

  const selectedWorkspaceId = useMemo(() => selectedWorkspace?.id, [selectedWorkspace]);

  const [localSelectedAppId, setLocalSelectedAppId] = useState<string | undefined>(
    determineInitialSelectedAppId(globalSelectedApp, apps, window.location.search, appKey),
  );

  const localSelectedApp = useMemo(() => apps.find((app) => app.id === localSelectedAppId), [localSelectedAppId, apps]);

  const [saving, setSaving] = useState(false);
  const [loading, setLoading] = useState(false);

  const updateApp = useCallback(
    async (name: string, is_development?: boolean) => {
      try {
        if (!selectedWorkspace?.id || name.trim().length === 0 || !localSelectedApp) return;

        setSaving(true);
        await administrationService.updateApp(selectedWorkspace.id, localSelectedApp.id, { name, is_development });

        await fetchAllApps(selectedWorkspace.id);

        displayToast({
          title: "Fantastic!",
          content: "App has been updated",
        });
      } catch (error) {
        displayToast({
          title: "Something went wrong",
          content: "Please try again.",
        });
      } finally {
        setSaving(false);
      }
    },
    [localSelectedApp, selectedWorkspace, administrationService, fetchAllApps],
  );

  const updateAppLogo = useCallback(
    async (file: File, toggle: () => void) => {
      try {
        if (!selectedWorkspace?.id || !localSelectedApp || !file) return;

        setSaving(true);
        await administrationService.updateAppLogo(selectedWorkspace.id, localSelectedApp.id, file);

        await fetchAllApps(selectedWorkspace.id);

        displayToast({
          title: "Fantastic!",
          content: "App logo has been uploaded",
        });
      } catch (error) {
        displayToast({
          title: "Something went wrong",
          content: "Please try again.",
        });
      } finally {
        setSaving(false);
        toggle();
      }
    },
    [localSelectedApp, selectedWorkspace, administrationService, fetchAllApps],
  );

  const deleteAppLogo = useCallback(
    async (toggle: () => void) => {
      try {
        if (!selectedWorkspace?.id || !localSelectedApp) return;

        setSaving(true);
        await administrationService.deletAppLogo(selectedWorkspace.id, localSelectedApp.id);

        await fetchAllApps(selectedWorkspace.id);

        displayToast({
          title: "Fantastic!",
          content: "App logo has been removed",
        });
      } catch (error) {
        displayToast({
          title: "Something went wrong",
          content: "Please try again.",
        });
      } finally {
        setSaving(false);
        toggle();
      }
    },
    [localSelectedApp, selectedWorkspace, administrationService, fetchAllApps],
  );

  const activateInactivateApp = async (active: boolean) => {
    try {
      if (!selectedWorkspace?.id || !localSelectedApp) return;

      setSaving(true);

      /// This is tied exclusively to fetchApps request. In this case
      /// we need it "sooner" because of the sidebar links
      appDispatch(setLoadingAction(true));

      !active && (await administrationService.updateApp(selectedWorkspace.id, localSelectedApp.id, { active: true }));
      active && (await administrationService.deactivateApp(selectedWorkspace.id, localSelectedApp.id));

      const isActiveFinal = !active;

      // TODO - if final state is active. Socket will fire off and cause a duplicate request. No big deal for now.
      // Keeping it to ensure correct state even if sockets don't connect.
      await updateSelectedWorkspace(selectedWorkspace); /// Triggers app fetch

      const content = isActiveFinal ? "has been activated" : "has been archived";
      const contentShort = isActiveFinal ? "activated" : "archived";

      displayToast({
        title: `App ${contentShort}`,
        content: `${localSelectedApp.name} ${localSelectedApp.platform.name} ${content}`,
      });
    } catch (error) {
      /// This is tied exclusively to fetchApps request. In this case
      /// we need it "sooner" because of the sidebar links
      appDispatch(setLoadingAction(false));

      displayToast({
        title: "Something went wrong",
        content: "Please try again.",
      });
    } finally {
      setSaving(false);
    }
  };

  async function getRules() {
    try {
      if (!selectedWorkspace?.id || !localSelectedApp || appsLoading || !localSelectedApp.active) return [];
      const { data } = await administrationService.getRules(selectedWorkspace.id, localSelectedApp.id);

      return data;
    } catch (error) {
      displayToast({
        title: "Something went wrong",
        content: "There was an error while getting rules",
      });
    }
  }

  const deleteRule = async (ruleId: string) => {
    try {
      if (!selectedWorkspace?.id || !localSelectedApp) return;

      setLoading(true);

      await administrationService.deleteRule(selectedWorkspace.id, localSelectedApp.id, ruleId);

      await refetchRules();

      displayToast({
        content: "Rule has been successfully deleted!",
      });
    } catch (error) {
      displayToast({
        title: "Something went wrong",
        content: "There was an error while deleting rule",
      });
    } finally {
      setLoading(false);
    }
  };

  const addApiKey = async () => {
    try {
      if (!selectedWorkspace?.id || !localSelectedApp) return;
      setLoading(true);
      await administrationService.addApiKey(selectedWorkspace.id, localSelectedApp.id);
      await fetchAllApps(selectedWorkspace.id);
    } catch (error) {
      displayToast({
        title: "Something went wrong",
        content: "There was an error while generating API key",
      });
    } finally {
      setLoading(false);
    }
  };

  const deleteApiKey = async (apiKeyId: string) => {
    try {
      if (!selectedWorkspace?.id || !localSelectedApp) return;
      setLoading(true);
      await administrationService.deleteApiKey(selectedWorkspace.id, localSelectedApp.id, apiKeyId);
      await fetchAllApps(selectedWorkspace.id);
    } catch (error) {
      displayToast({
        title: "Something went wrong",
        content: "There was an error while deleting API key",
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!selectedWorkspace || localSelectedAppId) return;
    fetchAllApps(selectedWorkspace.id).then((allApps) => {
      setLocalSelectedAppId(determineInitialSelectedAppId(globalSelectedApp, allApps, window.location.search, appKey));
    });
  }, [selectedWorkspace, fetchAllApps, localSelectedAppId, globalSelectedApp, appKey]);

  useEffect(() => {
    if (appKey) {
      setLocalSelectedAppId(determineInitialSelectedAppId(globalSelectedApp, apps, window.location.search, appKey));
    }
  }, [appKey, apps, globalSelectedApp]);

  const { data: rules, refetch: refetchRules } = useQuery(
    [`rules`, { localSelectedAppId, selectedWorkspaceId }],
    getRules,
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      enabled:
        selectedWorkspace &&
        Boolean(selectedWorkspaceId) &&
        localSelectedApp &&
        localSelectedApp.active &&
        !appsLoading,
    },
  );

  return {
    updateApp,
    activateInactivateApp,
    allApps: apps,
    saving,
    localSelectedApp,
    updateAppLogo,
    deleteAppLogo,
    appsLoading,
    rules: rules ?? [],
    refetchRules,
    deleteRule,
    addApiKey,
    deleteApiKey,
    loading,
  };
}

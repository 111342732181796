import { freeEmailProviders } from "./FreeEmailProviders";
import { Validators } from "./Validators";

export const validateEmail = (value: string): Error | undefined => {
  if (!Validators.required(value)) {
    return new Error("Please enter your email");
  }
  if (!Validators.validEmail(value)) {
    return new Error("Whoopsie, that doesn't look like a valid email");
  }
};

export const validatePassword = (value: string): Error | undefined => {
  if (!Validators.minLength(value, 8)) {
    return new Error("Please enter a password with at least 8 characters");
  }
};

export const validateDomain = (domain: string): Error | undefined => {
  if (!domain || domain.length === 0 || !Validators.validDomain(domain)) return new Error("Invalid domain");
  const match = freeEmailProviders.find((provider) => provider === domain);
  if (match) return new Error("Please enter a non-public domain name you and your colleagues are a part of");
};

export const validateBundleId = (value: string): Error | undefined => {
  const bundleIdCharRegEx = new RegExp(/^[a-zA-Z0-9]([a-zA-Z0-9-_]+)*$/);

  if (!Validators.required(value)) {
    return new Error("Whoopsie, that doesn't look like a valid bundle ID.");
  }

  if (!value.includes(".")) {
    return new Error("Whoopsie, that doesn't look like a valid bundle ID.");
  }

  for (const segment of value.split(".")) {
    if (!segment.match(bundleIdCharRegEx)) {
      return new Error("Whoopsie, that doesn't look like a valid bundle ID.");
    }
  }
};

import React, { Fragment, useState } from "react";

import NoAssignee from "assets/images/unassigned-icon.png";

import { BaseDialog, useBaseDialog } from "components/Dialog/BaseDialog";
import { ConfirmDialogBody } from "components/Dialog/configurations/ConfirmDialogBody";
import { DropdownItem } from "components/Dropdown/Dropdown";
import * as StyledDropdown from "components/Dropdown/Dropdown.styles";
import { RowType } from "components/MasterTable/models/MasterTableModel";
import RoundedCanvas from "components/RoundedCanvas/RoundedCanvas";
import { MergeIntoModalBody } from "components/ShakeTable/ui/MultiactionsPopover/ModalBody/MergeIntoModalBody";
import Tooltip from "components/Tooltip/Tooltip";

import { useAppContext } from "context/App/App.context";
import { useAuthContext } from "context/Auth/Auth.context";

import { getUserInitials } from "models";
import { CrashReport } from "models/crashReporting";
import { Issue } from "models/issueTracking";
import { Member } from "models/Member.model";
import { TicketPriority } from "models/TicketPriority.model";
import { TicketStatus } from "models/TicketStatus.model";

import { getRandomColor } from "pages/shared/helpers/getRandomColorHelper";
import { getPriorityIcon, getStatusIcon } from "pages/shared/icons/getIconsHelper";
import * as Icon from "pages/shared/icons/icons";

import { Flex } from "styles/reusable/Flex/Flex.styles";

import identifiers from "util/identifiers.json";

import { fireConfetti } from "../../util/confetti";
import * as Styled from "./DetailsBar.styles";
import { getTestIdForPriority, getTestIdForStatus } from "./getDetailsBarTestIdentifiers";

interface DetailsBarProps {
  details: Issue | CrashReport;
  changeAssignee: (assigneeId: string | null) => void;
  changeStatus: (status: TicketStatus) => void;
  changePriority: (priority: TicketPriority) => void;
  deleteTicket: (valueId: string) => void;
  onMergeAction?: (issues: Array<Issue | CrashReport>, issue: Issue | CrashReport) => void;
  clearMerge?: (issues: Array<Issue | CrashReport>, issueId: string) => void;
  showLock?: boolean;
  type: RowType;
}

export const DetailsBar: React.FC<DetailsBarProps> = ({
  details,
  changeAssignee,
  changePriority,
  changeStatus,
  deleteTicket,
  onMergeAction,
  showLock,
  type,
  clearMerge,
}) => {
  const { wsTeamMembers } = useAppContext();
  const { userData } = useAuthContext();
  const [isStatusDropdownOpened, setIsStatusDropdownOpened] = useState(false);
  const [isPriorityDropdownOpened, setIsPriorityDropdownOpened] = useState(false);
  const [isAssigneeDropdownOpened, setIsAssigneeDropdownOpened] = useState(false);

  const mergeModal = useBaseDialog({});
  const deleteTicketModal = useBaseDialog({});

  if (!(wsTeamMembers || !details || (details.status && details.priority && details.assignee))) {
    return <Fragment />;
  }

  const assignee = details.assignee && getAssignee(details.assignee.assignee_id, wsTeamMembers);
  const iconColor = assignee && getRandomColor(assignee.user.id);

  const handleChangeStatus = (status: TicketStatus) => {
    if (status !== details.status.status) changeStatus(status);
    setIsStatusDropdownOpened(false);
    if (status === TicketStatus.CLOSED) {
      fireConfetti();
    }
  };

  const handleChangePriority = (priority: TicketPriority) => {
    if (priority !== details.priority.priority) changePriority(priority);
    setIsPriorityDropdownOpened(false);
  };

  const handleChangeAssignee = (assigneeId: string | null) => {
    if (
      (assigneeId && assigneeId !== assignee?.id) ||
      Boolean(!assigneeId && assignee) ||
      Boolean(assigneeId && !assignee)
    )
      changeAssignee(assigneeId);
    setIsAssigneeDropdownOpened(false);
  };

  const sortedTeamMembers = () => {
    return (
      wsTeamMembers
        ?.filter((member) => member.user.id !== userData?.id)
        .sort((a, b) => a.user.name.localeCompare(b.user.name)) ?? []
    );
  };

  return (
    <>
      <Styled.TicketDetailsBar>
        <Flex
          $gap={1}
          $alignItems="center"
        >
          <Styled.AssigneeDropdown
            wide
            buttonElement={
              <Tooltip
                text={getAssigneeTooltipText(assignee)}
                position="top"
              >
                <RoundedCanvas
                  letter={assignee ? getUserInitials(assignee.user.name) : undefined}
                  bgColor={iconColor && iconColor.background}
                  txtColor={iconColor && iconColor.text}
                  image={assignee ? (assignee?.user.picture ? assignee?.user.picture : undefined) : NoAssignee}
                />
              </Tooltip>
            }
            position="left"
            isOpened={isAssigneeDropdownOpened}
            setIsOpened={setIsAssigneeDropdownOpened}
            testId={identifiers["central.column.assignee.icon"]}
          >
            <StyledDropdown.DropdownScroll cx={Styled.dropdownScroll}>
              <Styled.DropdownItem
                $clicked={assignee?.user.id === userData?.id}
                onClick={() =>
                  handleChangeAssignee(wsTeamMembers?.find((member) => member.user.id === userData?.id)?.id ?? null)
                }
                testId={identifiers["central.column.assignee.dropdown.you"]}
              >
                <Flex>
                  <Styled.Checkmark>
                    {assignee?.user.id === userData?.id && (
                      <Styled.CheckmarkIcon
                        width={16}
                        height={16}
                      />
                    )}
                  </Styled.Checkmark>
                  <p>You</p>
                </Flex>
              </Styled.DropdownItem>
              <Styled.DropdownItem
                $clicked={!assignee}
                onClick={() => handleChangeAssignee(null)}
                testId={identifiers["central.column.assignee.dropdown.unassigned"]}
              >
                <Flex>
                  <Styled.Checkmark>
                    {!assignee && (
                      <Styled.CheckmarkIcon
                        width={16}
                        height={16}
                      />
                    )}
                  </Styled.Checkmark>
                  <p>Unassigned</p>
                </Flex>
              </Styled.DropdownItem>
              {sortedTeamMembers().map((member) => {
                return (
                  <Styled.DropdownItem
                    $clicked={assignee?.id === member.id}
                    key={member.id}
                    onClick={() => handleChangeAssignee(member.id)}
                    testId={identifiers["central.column.assignee.dropdown.assigneeListing"]}
                  >
                    <Flex>
                      <Styled.Checkmark>
                        {assignee?.id === member.id && (
                          <Styled.CheckmarkIcon
                            width={16}
                            height={16}
                          />
                        )}
                      </Styled.Checkmark>
                      <p>{member.user.name}</p>
                    </Flex>
                  </Styled.DropdownItem>
                );
              })}
            </StyledDropdown.DropdownScroll>
          </Styled.AssigneeDropdown>

          {details.status && (
            <Tooltip
              position="top"
              text="Set status"
              disabled={details.status.status === TicketStatus.LOCKED}
            >
              <Styled.StatusPriorityDropdown
                label={getDetailsStatus(details.status.status)}
                iconComponent={statusIconComponent(details.status.status)}
                buttonElement={<Fragment />}
                position="left"
                isOpened={isStatusDropdownOpened}
                setIsOpened={setIsStatusDropdownOpened}
                disabled={details.status.status === TicketStatus.LOCKED}
                testId={identifiers["central.column.status.icon"]}
              >
                {Object.values(TicketStatus)
                  .filter((a) => a !== TicketStatus.LOCKED)
                  .map((status) => {
                    return (
                      <DropdownItem
                        key={status}
                        onClick={() => handleChangeStatus(status)}
                        testId={getTestIdForStatus(status as Exclude<TicketStatus, TicketStatus.LOCKED>)}
                        sameIconColor
                      >
                        {statusIconComponent(status)}
                        {status}
                      </DropdownItem>
                    );
                  })}
              </Styled.StatusPriorityDropdown>
            </Tooltip>
          )}

          {details.priority && (
            <Tooltip
              position="top"
              text="Set priority"
            >
              <Styled.StatusPriorityDropdown
                label={details.priority.priority}
                iconComponent={priorityIconComponent(details.priority.priority as TicketPriority)}
                buttonElement={<Fragment />}
                position="left"
                isOpened={isPriorityDropdownOpened}
                setIsOpened={setIsPriorityDropdownOpened}
                testId={identifiers["central.column.priority.button"]}
              >
                {Object.values(TicketPriority).map((priority) => {
                  return (
                    <DropdownItem
                      key={priority}
                      onClick={() => handleChangePriority(priority)}
                      testId={getTestIdForPriority(priority)}
                      sameIconColor
                    >
                      {priorityIconComponent(priority)}
                      {priority}
                    </DropdownItem>
                  );
                })}
              </Styled.StatusPriorityDropdown>
            </Tooltip>
          )}

          <Styled.DeleteMergeContainer>
            {showLock && (
              <Styled.LockIcon
                data-testid={identifiers["central.column.icon.lock"]}
                $disabled={
                  details.status.status !== TicketStatus.CLOSED && details.status.status !== TicketStatus.LOCKED
                }
              >
                {getCrashLockStatusIcon(changeStatus, details)}
              </Styled.LockIcon>
            )}

            <>
              <Styled.DuplicateContainer
                onClick={() =>
                  details.parent && details.parent_id
                    ? clearMerge?.([details], details.parent_id)
                    : mergeModal.setOpen(true)
                }
              >
                <Tooltip
                  text={details.parent ? "Clear duplicate status" : "Mark as duplicate of ..."}
                  position="top"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  {details.parent ? <Icon.ClearDuplicateIcon /> : <Icon.DuplicateIcon />}
                </Tooltip>
              </Styled.DuplicateContainer>
            </>

            <Styled.DeleteContainer onClick={() => deleteTicketModal.setOpen(true)}>
              <Tooltip
                text="Delete ticket"
                position="top"
                style={{ display: "flex", alignItems: "center", width: "100%", justifyContent: "center" }}
              >
                <Icon.DeleteIcon
                  width={20}
                  height={20}
                  style={{ margin: "0.8rem" }}
                />
              </Tooltip>
            </Styled.DeleteContainer>
          </Styled.DeleteMergeContainer>
        </Flex>
      </Styled.TicketDetailsBar>

      {onMergeAction && (
        <BaseDialog
          {...mergeModal}
          noPadding
        >
          <MergeIntoModalBody
            title={`Mark as duplicate of`}
            confirmAction={onMergeAction}
            close={() => mergeModal.setOpen(false)}
            selectedRows={[details]}
            type={type}
          />
        </BaseDialog>
      )}

      <BaseDialog {...deleteTicketModal}>
        <ConfirmDialogBody
          title="Delete ticket?"
          subtitle={`Are you sure you want to delete this ticket? This can't be undone.`}
          confirmAction={() => {
            deleteTicket(details.id);
            deleteTicketModal.setOpen(false);
          }}
          buttonText="Yes, delete"
        />
      </BaseDialog>
    </>
  );
};

const getDetailsStatus = (status: TicketStatus) => {
  if (status === TicketStatus.LOCKED) {
    return TicketStatus.CLOSED;
  }
  return status;
};

const getAssignee = (assigneeId: string | null, members?: Member[]) => {
  if (!members) return;
  return members.find((member) => member.id === assigneeId);
};

const getCrashLockStatusIcon = (changeStatus: (status: TicketStatus) => void, crash?: Issue | CrashReport) => {
  if (!crash || !crash.status) return;
  if (crash?.status?.status === TicketStatus.LOCKED) {
    return (
      <Tooltip
        text="Unlock this crash so it re-opens if it's reported in a newer version."
        position="top"
        style={{ height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <Icon.LockedIcon
          onClick={() => changeStatus(TicketStatus.CLOSED)}
          width={20}
          height={20}
        />
      </Tooltip>
    );
  }

  const isCrashClosed = crash.status.status === TicketStatus.CLOSED;

  const unlockedIconTooltipText = !isCrashClosed
    ? "Close the crash first in order to lock it."
    : "Lock this crash to prevent it from re-opening if it's reported in a newer version.";

  return (
    <Tooltip
      text={unlockedIconTooltipText}
      position="top"
      style={{ height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}
    >
      <Styled.UnlockedIcon
        $disabled={!isCrashClosed}
        onClick={() => {
          if (isCrashClosed) changeStatus(TicketStatus.LOCKED);
        }}
      />
    </Tooltip>
  );
};

const getAssigneeTooltipText = (assignee?: Member) => {
  if (!assignee) return "Unassigned";

  return `Assigned to ${assignee?.user.name}`;
};

const statusIconComponent = (status: TicketStatus) => {
  return <Styled.IconContainer>{getStatusIcon(status)}</Styled.IconContainer>;
};

const priorityIconComponent = (priority: TicketPriority) => {
  return <Styled.IconContainer>{getPriorityIcon(priority)}</Styled.IconContainer>;
};

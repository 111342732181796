import React, { lazy, Suspense, useState } from "react";

import { components, GroupBase, OptionProps, SingleValueProps } from "react-select";
import { AsyncPaginate } from "react-select-async-paginate";

const Select = lazy(() => import("react-select"));

import { Spinner } from "components/Spinner/Spinner";

import { Flex } from "styles/reusable/Flex/Flex.styles";

import * as Styled from "./SelectDropdown.styles";

export interface Option {
  label: string;
  value: string;
  subtitle?: string;
  icon?: string;
}

export interface GroupedOptions {
  label: string;
  options: Option[];
}

interface SelectDropdownProps {
  name?: string;
  options?: Option[];
  groupedOptions?: GroupedOptions[];
  value?: Option;
  defaultValue?: Option;
  label: string;
  placeholder?: string;
  onChange?: (option: Option, name: string | undefined) => void;
  isSearchable?: boolean;
  className?: string;
  disabled?: boolean;
  testId?: string;
  hasSubtitles?: boolean;
  // eslint-disable-next-line
  loadOptions?: any;
}

const SelectDropdown: React.FC<SelectDropdownProps> = ({
  name,
  options,
  groupedOptions,
  label,
  placeholder,
  value,
  defaultValue,
  onChange,
  isSearchable,
  className,
  disabled,
  testId,
  hasSubtitles,
  loadOptions,
}) => {
  const [focused, setFocused] = useState(false);
  return (
    <Styled.SelectDropdownWrap
      className={className}
      $label={label}
      $disabled={disabled}
      $searchable={isSearchable}
      $isFocused={focused}
    >
      <div
        data-testid={testId}
        id="modal_scroll"
      >
        {label && <label>{label}</label>}
        <Suspense fallback={() => <Spinner />}>
          {!loadOptions ? (
            <Select
              hideSelectedOptions={true}
              name={name}
              options={options ?? groupedOptions}
              value={value}
              styles={Styled.customStyles}
              isSearchable={isSearchable}
              placeholder={placeholder}
              defaultValue={defaultValue}
              isDisabled={disabled}
              classNamePrefix="react-select"
              onChange={(option) => {
                onChange && onChange(option as { value: string; label: string }, name);
              }}
              onMenuOpen={() => setFocused(true)}
              onMenuClose={() => setFocused(false)}
              isMulti={false}
              menuShouldBlockScroll
              menuPortalTarget={document.body.parentElement}
              components={
                hasSubtitles ||
                options?.find((a) => a.icon) ||
                groupedOptions?.find((a) => a.options.find((b) => b.icon))
                  ? { SingleValue, Option }
                  : undefined
              }
            />
          ) : (
            <AsyncPaginate
              hideSelectedOptions={true}
              loadOptions={() => loadOptions()}
              name={name}
              // eslint-disable-next-line
              getOptionValue={(option: any) => {
                return option.value;
              }}
              loadOptionsOnMenuOpen={false}
              options={options}
              // eslint-disable-next-line
              getOptionLabel={(option: any) => option.label}
              value={value}
              styles={Styled.customStyles}
              isSearchable={isSearchable}
              placeholder={placeholder}
              defaultValue={defaultValue}
              isDisabled={disabled}
              classNamePrefix="react-select"
              onChange={(option) => {
                onChange && onChange(option as { value: string; label: string }, name);
              }}
              additional={{
                page: 1,
              }}
              debounceTimeout={500}
              onMenuOpen={() => setFocused(true)}
              onMenuClose={() => setFocused(false)}
              isMulti={false}
              menuShouldBlockScroll
              menuPortalTarget={document.body.parentElement}
              components={
                hasSubtitles ||
                options?.find((a) => a.icon) ||
                groupedOptions?.find((a) => a.options.find((b) => b.icon))
                  ? { SingleValue, Option }
                  : undefined
              }
            />
          )}
        </Suspense>
      </div>
    </Styled.SelectDropdownWrap>
  );
};

export default SelectDropdown;

const SingleValue = (props: SingleValueProps<unknown, boolean, GroupBase<unknown>>) => {
  const { label, subtitle, icon } = props.getValue()[0] as Option;

  return (
    <components.SingleValue {...props}>
      <OptionComponent
        label={label}
        subtitle={subtitle}
        icon={icon}
        isSingleValue
      />
    </components.SingleValue>
  );
};

const Option = (props: OptionProps<unknown, boolean, GroupBase<unknown>>) => {
  const { label, subtitle, icon } = props.data as Option;

  return (
    <components.Option {...props}>
      <OptionComponent
        label={label}
        subtitle={subtitle}
        icon={icon}
      />
    </components.Option>
  );
};

const OptionComponent = ({
  label,
  subtitle,
  icon,
  isSingleValue,
}: {
  label: string;
  subtitle?: string;
  icon?: string;
  isSingleValue?: boolean;
}) => {
  return (
    <Styled.OptionContainer $withSubtitle={!!subtitle}>
      {icon ? (
        <Flex
          $alignItems="center"
          $gap={0.8}
          style={{ marginTop: "0.6rem" }}
        >
          <img
            src={icon}
            width={24}
            height={24}
            style={{ borderRadius: "0.8rem", objectFit: "cover" }}
          />
          <span>{label}</span>
        </Flex>
      ) : (
        <span>{label}</span>
      )}

      {subtitle && (
        <div style={{ wordBreak: "break-all" }}>
          <Styled.Subtitle $singleValue={isSingleValue}>{subtitle}</Styled.Subtitle>
        </div>
      )}
    </Styled.OptionContainer>
  );
};

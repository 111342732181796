import { useCallback, useEffect } from "react";

interface Props {
  onArrowUp?: () => void;
  onArrowDown?: () => void;
}

export function useArrowUpDownKeyPress({ onArrowUp, onArrowDown }: Props) {
  const eventCallback = useCallback(
    (keyboardEvent: KeyboardEvent) => {
      keyboardEvent.preventDefault();

      if (keyboardEvent.code === "ArrowUp" && onArrowUp) {
        onArrowUp();
        return;
      }

      if (keyboardEvent.code === "ArrowDown" && onArrowDown) {
        onArrowDown();
        return;
      }
    },
    [onArrowUp, onArrowDown],
  );

  useEffect(() => {
    document.addEventListener("keydown", eventCallback);
    return () => {
      document.removeEventListener("keydown", eventCallback);
    };
  }, [eventCallback]);
}

import { useCallback } from "react";

import Shake from "@shakebugs/browser";
import { useAuthDeps } from "App.dependencies";

import displayToast from "components/Toast/displayToast";

import { setJoinableWorkspaces, setWorkspacesAction, setWorkspacesLoading } from "context/App/App.actions";
import { useAppDispatch } from "context/App/App.context";
import { setUserDataAction } from "context/Auth/Auth.actions";
import { useAuthDispatch } from "context/Auth/Auth.context";

export const useWorkspacesAPI = () => {
  const { authService } = useAuthDeps();
  const authDispatch = useAuthDispatch();
  const appDispatch = useAppDispatch();

  const fetchWorkspacesAndDispatch = useCallback(async () => {
    try {
      appDispatch(setWorkspacesLoading(true));
      const { data } = await authService.getMe();
      Shake.setMetadata("user_id", data.id);
      Shake.setMetadata("user_email", data.email);
      Shake.setMetadata("user_name", data.name);
      const { teams: workspaces, joinable_teams: joinableWorkspaces, ...userData } = data;
      authDispatch(setUserDataAction(userData));
      appDispatch(setJoinableWorkspaces(joinableWorkspaces));
      appDispatch(setWorkspacesAction(workspaces));
      return { userData, workspaces };
    } catch (error) {
      window.location.href = "https://status.shakebugs.com/";
      displayToast({
        title: "Something went wrong",
        content: error?.response?.data?.message ?? "Please try again.",
      });
      throw error;
    } finally {
      appDispatch(setWorkspacesLoading(false));
    }
  }, [authService, authDispatch, appDispatch]);

  return { fetchWorkspacesAndDispatch };
};

import styled from "styled-components";

import addAppBackground from "assets/images/empty-state/learn-more-visual.png";

import Paragraph from "components/Paragraph/Paragraph";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 2.8rem;
  margin-bottom: 6.4rem;
  align-items: center;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: default;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 37rem 31rem;
  width: 37rem;
  height: 31rem;
  background-image: url(${addAppBackground});
`;

export const Title = styled(Paragraph)`
  text-align: center;
  height: 10rem;
`;

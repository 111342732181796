import React, { Fragment, useState } from "react";

import Linkify from "linkify-react";

import { ReactComponent as CommonCommentIcon } from "assets/images/shared-icons/common-comments-icon.svg";

import { AdministrationCard } from "components/AdministrationCard/AdministrationCard";
import Button from "components/Button/Button";
import Dropdown, { DropdownItem } from "components/Dropdown/Dropdown";
import { CellEnd, CenteredCell, GridTable } from "components/GridTable/GridTable";
import { useModal } from "components/Modal/Modal";
import Paragraph from "components/Paragraph/Paragraph";

import { useAppSelectionContext } from "context/App/AppSelectionContext";

import { CommonComment } from "models/CommonComment.model";
import { isGuest } from "models/Member.model";

import { vars } from "styles";

import { CommonCommentModalBody } from "../ModalBody/CommonCommentModalBody";
import * as Styled from "./CommonCommentCard.styles";

interface Props {
  commonComments?: CommonComment[];
  getCommonComments: () => Promise<void>;
  deleteCommonComment: (id: string) => Promise<void>;
  disabled?: boolean;
}

export default function CommonCommentsCard({
  commonComments,
  getCommonComments,
  deleteCommonComment,
  disabled,
}: Props) {
  const { selectedWorkspace } = useAppSelectionContext();
  const [selectedCommonComment, setSelectedCommonComment] = useState<CommonComment>();
  const {
    Modal: CommonCommentModal,
    toggle: toggleCommonComment,
    modalProps: commonCommentInternal,
    active: modalActive,
  } = useModal({});

  const {
    Modal: RemoveCommonCommentModal,
    modalProps: removeCommonCommentInternal,
    toggle: toggleRemove,
    active: removeActive,
  } = useModal({ size: "narrow" });

  const handleRemove = (selectedCommonComment: CommonComment) => {
    deleteCommonComment(selectedCommonComment.id).then(() => {
      toggleRemove();
    });
  };

  return (
    <AdministrationCard
      title="Manage common comments"
      subtitle="Add, edit and remove them — they are available to anyone."
      topLeftAccessory={
        <Button
          disabled={disabled || isGuest(selectedWorkspace?.role)}
          onClick={() => {
            setSelectedCommonComment(undefined);
            toggleCommonComment();
          }}
          size="small"
          tooltip={{
            disabled: disabled || !isGuest(selectedWorkspace?.role),
            text: isGuest(selectedWorkspace?.role) ? "Only admins and members can add new common comment" : "",
            position: "top",
          }}
        >
          Add new
        </Button>
      }
    >
      {commonComments && Boolean(commonComments.length) && (
        <GridTable gridTemplateColumns="repeat(3, auto)">
          {commonComments.map((commonComment) => {
            return (
              <Fragment key={commonComment.id}>
                <CenteredCell>
                  <CommonCommentIcon />

                  <Styled.Paragraph color={vars.colors.grey30}>{commonComment.title}</Styled.Paragraph>
                </CenteredCell>
                <CenteredCell maxWidth="270">
                  <Paragraph>
                    <Linkify>{commonComment.message}</Linkify>
                  </Paragraph>
                </CenteredCell>
                <CellEnd>
                  {!isGuest(selectedWorkspace?.role) && (
                    <Dropdown isOpened={modalActive || removeActive ? false : undefined}>
                      <DropdownItem
                        onClick={() => {
                          setSelectedCommonComment(commonComment);
                          toggleCommonComment();
                        }}
                      >
                        Edit
                      </DropdownItem>
                      <DropdownItem
                        onClick={() => {
                          setSelectedCommonComment(commonComment);
                          toggleRemove();
                        }}
                        error
                      >
                        Remove
                      </DropdownItem>
                    </Dropdown>
                  )}
                </CellEnd>
              </Fragment>
            );
          })}
        </GridTable>
      )}

      <CommonCommentModalBody
        commonComment={selectedCommonComment}
        Modal={CommonCommentModal}
        internalProps={commonCommentInternal}
        closeModal={toggleCommonComment}
        getCommonComments={getCommonComments}
      />

      {selectedCommonComment && (
        <RemoveCommonCommentModal
          {...removeCommonCommentInternal}
          heading={`Remove ${selectedCommonComment.title}?`}
          subtitle={`If you're sure you want to remove ${selectedCommonComment.title} from this workspace, just click the magic button below.`}
          buttonElement={
            <Button
              onClick={() => handleRemove(selectedCommonComment)}
              size="small"
              color="red"
              disabled={disabled}
              loadingText={disabled && "Removing..."}
            >
              Yes, remove
            </Button>
          }
        />
      )}
    </AdministrationCard>
  );
}

import { AxiosResponse } from "axios";

import { App, BasicWorkspace, Platform, WorkspaceData } from "models";
import { Customer } from "models/billing";
import { Member } from "models/Member.model";

import ApiService from "./Api.service";

export default class AppsService {
  private static instance?: AppsService;
  private readonly apiService: ApiService;

  private constructor() {
    this.apiService = ApiService.getInstance();
  }

  public static getInstance(): AppsService {
    if (!this.instance) {
      this.instance = new AppsService();
    }
    return this.instance;
  }

  addApp(
    workspaceId: string,
    appName: string | null,
    bundleId: string,
    platformId: string,
    isDevelopment: boolean,
  ): Promise<App> {
    return this.apiService
      .post<App>({
        resource: `/issue_tracking/${workspaceId}/apps`,
        data: {
          app_name: appName,
          bundle_id: bundleId,
          platform_id: platformId,
          is_development: isDevelopment,
        },
      })
      .then(({ data }) => data);
  }

  getApps(workspaceId: string, signal?: AbortSignal): Promise<App[]> {
    return this.apiService
      .get<App[]>({
        resource: `issue_tracking/${workspaceId}/apps?return_all=true&deleted=true`,
        config: { signal },
      })
      .then(({ data }) => data);
  }

  updateApp(workspaceId: string, appId: string, updateData: Partial<App>): Promise<AxiosResponse<App>> {
    return this.apiService.patch({
      resource: `issue_tracking/${workspaceId}/apps/${appId}`,
      data: updateData,
    });
  }

  getPlatforms(abortSignal?: AbortSignal): Promise<AxiosResponse<Platform[]>> {
    return this.apiService.get({
      resource: "issue_tracking/platforms",
      config: { signal: abortSignal },
    });
  }

  getJoinableWorkspaces(abortSignal?: AbortSignal): Promise<AxiosResponse<BasicWorkspace[]>> {
    return this.apiService.get({
      resource: `accounts/teams/joinable_teams`,
      config: { signal: abortSignal },
    });
  }

  getCustomerInfo(selectedWorkspaceId: string, abortSignal?: AbortSignal) {
    return this.apiService.get<Customer>({
      resource: `/billing/${selectedWorkspaceId}/customer`,
      config: { signal: abortSignal },
    });
  }

  getTeamData(workspaceId: string, abortSignal?: AbortSignal): Promise<AxiosResponse<WorkspaceData>> {
    return this.apiService.get<WorkspaceData>({
      resource: `accounts/teams/${workspaceId}`,
      config: { signal: abortSignal },
    });
  }

  getMembers(workspaceId: string, abortSignal?: AbortSignal): Promise<AxiosResponse<Member[]>> {
    return this.apiService.get<Member[]>({
      resource: `accounts/teams/${workspaceId}/members`,
      config: { signal: abortSignal },
    });
  }
}

AppsService.getInstance();

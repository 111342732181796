import React, { ReactNode } from "react";

import Dropdown, { DropdownItem } from "components/Dropdown/Dropdown";
import { CellEnd, CenteredCell } from "components/GridTable/GridTable";
import Paragraph from "components/Paragraph/Paragraph";

import { getIntegrationTypeName, Integration, IntegrationType } from "models/integrations";

import { getIntegrationTypeLogo } from "pages/Administration/util/getIntegrationTypeLogo";

import { vars } from "styles";

import identifiers from "util/identifiers.json";

import { App, Workspace } from "../../../../models";
import { useRedirectConsumer } from "../ModalBody/UserFeedbackIntegrations/consumers/useRedirectConsumer";
import * as Styled from "./UserFeedbackIntegrationsRow.styles";

interface Props {
  onDisconnect: () => void;
  integration: Integration | null;
  onSettingsSelect: () => void;
  type: IntegrationType;
  showSettings?: boolean;
  toggle: () => void;
  app: App;
  workspace?: Workspace;
  setSelectedIntegrationType: (type: IntegrationType) => void;
  tooltip: string;
  disabled: boolean;
}

export const UserFeedbackIntegrationsRow = ({
  onDisconnect,
  onSettingsSelect,
  integration,
  type,
  toggle,
  app,
  workspace,
  setSelectedIntegrationType,
  tooltip,
  disabled,
}: Props) => {
  const { startRedirection } = useRedirectConsumer({ workspace, selectedAppId: app.id });
  const handleAddIntegration = (integrationType: IntegrationType) => {
    setSelectedIntegrationType(integrationType);

    if (
      integrationType !== IntegrationType.SLACK &&
      integrationType !== IntegrationType.ZAPIER &&
      integrationType !== IntegrationType.AZURE_DEVOPS &&
      integrationType !== IntegrationType.JIRA_SERVER &&
      integrationType !== IntegrationType.WEBHOOK
    ) {
      startRedirection(integrationType);
    } else {
      toggle?.();
    }
  };

  const TooltipWrap = ({ children }: { children: ReactNode }) => {
    return tooltip && disabled ? (
      <Styled.Tooltip
        position="top"
        text={tooltip}
      >
        {children}
      </Styled.Tooltip>
    ) : (
      <>{children}</>
    );
  };
  const DropdownButton = () => {
    return (
      <CellEnd>
        <TooltipWrap>
          <Dropdown
            testId={identifiers["integrations.icon.options"]}
            disabled={disabled}
            numTooltipPos={"top-right"}
            numTooltipText={tooltip}
          >
            {integration && integration.active ? (
              <>
                <DropdownItem
                  onClick={onSettingsSelect}
                  testId={identifiers["integrations.icon.dropdown.settings"]}
                >
                  <span>Settings</span>
                </DropdownItem>
                <DropdownItem
                  onClick={() => onDisconnect()}
                  testId={identifiers["integrations.icon.dropdown.disconnect"]}
                  error
                >
                  Disconnect
                </DropdownItem>
              </>
            ) : (
              <DropdownItem
                onClick={() => handleAddIntegration(type)}
                testId={identifiers["integrations.icon.dropdown.disconnect"]}
                disabled={disabled}
              >
                Connect
              </DropdownItem>
            )}
          </Dropdown>
        </TooltipWrap>
      </CellEnd>
    );
  };

  return (
    <>
      <CenteredCell gap={16}>
        <Styled.Logo
          src={getIntegrationTypeLogo(type)}
          alt="Integration Logo"
        />

        <Paragraph color={vars.colors.grey30}>{getIntegrationTypeName(type)}</Paragraph>
      </CenteredCell>
      <CenteredCell>
        {integration && integration.active ? (
          <>
            <Styled.ConnectedIcon />
            <Styled.Connected>Connected</Styled.Connected>
          </>
        ) : (
          <>
            <Styled.DisconnectedIcon />
            <Styled.Disconnected>Not connected</Styled.Disconnected>
          </>
        )}
      </CenteredCell>
      <DropdownButton />
    </>
  );
};

import { useCallback, useEffect } from "react";

export function useEscapeKey(setActive: (active: boolean) => void) {
  const handleEscKey = useCallback(
    (event) => {
      if (event.key === "Escape") {
        setActive(false);
      }
    },
    [setActive],
  );

  useEffect(() => {
    document.addEventListener("keyup", handleEscKey, false);

    return () => {
      document.removeEventListener("keyup", handleEscKey, false);
    };
  }, [handleEscKey]);
}

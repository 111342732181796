import React from "react";

import { AdministrationCard } from "components/AdministrationCard/AdministrationCard";
import Button from "components/Button/Button";
import PageWrap from "components/PageWrap/PageWrap";
import { Spinner } from "components/Spinner/Spinner";

import { useAppContext } from "context/App/App.context";
import { useAppSelectionContext } from "context/App/AppSelectionContext";

import NoAppsView from "pages/Administration/components/NoAppsView";
import useAppsFeedbackViewConsumer from "pages/Administration/consumers/useAppsFeedbackViewConsumer";

import { isFreePlan } from "util/workspaceUtil";

import ImportCard from "../../components/AppsCards/ImportCard";
import { UserFeedbackIntegrationsCard } from "../../components/AppsCards/UserFeedbackIntegrationsCard";

export default function AppsFeedbackView(): JSX.Element {
  const { loading: appsLoading } = useAppContext();
  const { selectedWorkspace } = useAppSelectionContext();

  const {
    loading,
    disconnectIntegration,
    allApps,
    preSelectedIntegrationType,
    clearPreSelectedIntegrationType,
    exportTickets,
    localSelectedApp,
  } = useAppsFeedbackViewConsumer();

  if (!appsLoading && allApps.length === 0) {
    <NoAppsView selectedWorkspace={selectedWorkspace} />;
  }

  if (!localSelectedApp) {
    return <Spinner />;
  }

  const localLoading = appsLoading || loading;

  return (
    <PageWrap style={{ marginTop: "4rem" }}>
      <UserFeedbackIntegrationsCard
        app={localSelectedApp}
        disabled={!localSelectedApp.active}
        loading={localLoading}
        disconnectIntegration={disconnectIntegration}
        preSelectedIntegrationType={preSelectedIntegrationType}
        clearPreselectedIntegrationType={clearPreSelectedIntegrationType}
        tooltip={{
          text: getAddIntegrationTooltipText(localSelectedApp.active),
        }}
      />
      {!Boolean(localSelectedApp?.is_sample) && (
        <ImportCard
          app={localSelectedApp}
          disabled={localLoading}
        />
      )}
      <AdministrationCard
        title="Export tickets"
        subtitle="Export all user feedback tickets into a .csv file. Succesfully exported file will be forwarded to your email."
        isEmpty
        topLeftAccessory={
          <Button
            size="small"
            onClick={() => exportTickets()}
            disabled={isFreePlan(selectedWorkspace)}
            tooltip={{
              text: "Upgrade your plan to download tickets",
              disabled: !isFreePlan(selectedWorkspace),
              position: "top",
            }}
          >
            Download
          </Button>
        }
      />
    </PageWrap>
  );
}

const getAddIntegrationTooltipText = (isActive: boolean) => {
  if (!isActive) {
    return "Integrations can be added on active apps only";
  }
  return "";
};

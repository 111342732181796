import React, { useState } from "react";

import AppPlaceholderPhoto from "assets/images/shared-icons/app-icon-placeholder.svg";

import { AdministrationCard } from "components/AdministrationCard/AdministrationCard";
import Button from "components/Button/Button";
import { GridTable } from "components/GridTable/GridTable";
import Input from "components/Input/Input";

import { PlatformName } from "models";
import { App } from "models/App.model";

import GeneralCardRow from "pages/Administration/views/GeneralView/GeneralClientRow";
import LogoComponent from "pages/shared/components/LogoComponent/LogoComponent";

import { Flex } from "styles/reusable/Flex/Flex.styles";

import { getAppPlatformOsLabel } from "util/contentResolvers";
import identifiers from "util/identifiers.json";

import { ToggleSection } from "../../../../../components/ToggleSection/ToggleSection";
import * as Styled from "./EssentialsCard.styles";

interface Props {
  selectedApp: App;
  appName: string;
  updateApp: (newName: string, isDevelopment: boolean) => Promise<void>;
  updateAppLogo: (file: File, toggle: () => void) => void;
  deleteAppLogo: (toggle: () => void) => void;
  loading: boolean;
  saving: boolean;
  uploading: boolean;
}

export function EssentialsCard({
  selectedApp,
  appName,
  updateApp,
  loading,
  saving,
  updateAppLogo,
  deleteAppLogo,
  uploading,
}: Props) {
  const [newAppName, setNewAppName] = useState<string>(appName);
  const [isDevelopment, setIsDevelopment] = useState<boolean>(selectedApp.is_development);

  const isButtonDisabled =
    (selectedApp.name === newAppName && selectedApp.is_development === isDevelopment) || !newAppName.trim().length;
  const tooltipText =
    selectedApp.name === newAppName ? "Please change the app name first" : "Please enter app name first";

  return (
    <AdministrationCard
      title="Essentials"
      subtitle="If you don't like the read-only essentials listed below, mark this app as inactive and create a new app."
      testId={identifiers["essentials.header"]}
    >
      <Styled.InputContainer>
        <Flex
          $column
          $gap={2}
        >
          <LogoComponent
            title="App icon"
            subtitle="Optional • Visible to workspace members only."
            image={selectedApp?.logo_url}
            placeholderImage={AppPlaceholderPhoto}
            onUpdatePicture={updateAppLogo}
            onDeletePicture={deleteAppLogo}
            loading={uploading}
            squareCanvas
            canChange={true}
          />
          <Input
            label="App name"
            value={newAppName}
            onChange={(e: React.FormEvent<HTMLInputElement>) => setNewAppName(e.currentTarget.value)}
            testId={identifiers["essentials.input.appName"]}
          />
          {selectedApp.platform.name === PlatformName.WEB && (
            <ToggleSection
              onChange={(checked: boolean) => setIsDevelopment(checked)}
              disabled={loading || saving}
              title="Localhost build"
              subtitle="Turn on only for localhost version. Otherwise leave turned off"
              checked={isDevelopment}
              testId={identifiers["status.toggle"]}
            />
          )}
          <GridTable gridTemplateColumns="repeat(3, auto)">
            {!Boolean(selectedApp?.is_sample) && (
              <GeneralCardRow
                title={selectedApp.platform.name === PlatformName.WEB ? "Domain" : "Bundle ID"}
                value={selectedApp?.bundle_id ?? ""}
                toastText={
                  selectedApp.platform.name === PlatformName.WEB
                    ? "Domain is copied to clipboard"
                    : "Bundle ID copied to clipboard"
                }
                testId={identifiers["essentials.input.bundleId"]}
              />
            )}
            <GeneralCardRow
              title="Platform"
              value={selectedApp ? getAppPlatformOsLabel(selectedApp?.platform) : ""}
              toastText="Platform copied to clipboard"
              testId={identifiers["essentials.input.platform"]}
            />
          </GridTable>
        </Flex>
      </Styled.InputContainer>

      <Button
        type="submit"
        size="small"
        disabled={isButtonDisabled || loading}
        onClick={() => updateApp(newAppName, isDevelopment)}
        testId={identifiers["essentials.button.save"]}
        tooltip={{
          position: "top",
          text: tooltipText,
          disabled: !isButtonDisabled,
        }}
        loadingText={saving && "Saving..."}
      >
        Save changes
      </Button>
    </AdministrationCard>
  );
}

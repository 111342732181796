import React from "react";

import { generatePath, Link } from "react-router-dom";

import { ReactComponent as CloseIcon } from "assets/images/close-icon.svg";
import SparkleEmoji from "assets/images/ticket-icons/sparkle-icon.png";

import Paragraph from "components/Paragraph/Paragraph";
import Tooltip from "components/Tooltip/Tooltip";

import { useAppSelectionContext } from "context/App/AppSelectionContext";

import { Issue } from "models/issueTracking";

import * as Icon from "pages/shared/icons/icons";

import { RoutePaths } from "router/config/routePaths";

import { vars } from "styles";
import { Flex } from "styles/reusable/Flex/Flex.styles";

import * as Styled from "./SmartSuggestion.styles";

interface Props {
  onMerge?: () => void;
  onRemove?: (issueId: string) => void;
  suggestion: Issue;
}

export default function SmartSuggestion({ onMerge, onRemove, suggestion }: Props) {
  const { selectedWorkspace, selectedApp } = useAppSelectionContext();
  return (
    <Flex
      $alignItems="center"
      style={{ marginTop: "3rem", marginBottom: "1.2rem" }}
    >
      <Tooltip
        text="Shake AI"
        position="top"
        style={{ width: "unset", paddingRight: "0.4rem" }}
      >
        <img
          width={18}
          height={18}
          src={SparkleEmoji}
        />
      </Tooltip>
      <Paragraph
        color={vars.colors.white}
        fontSize={14}
      >
        {`Hmmm... Mark this ticket as a duplicate of `}
        <Link
          to={generatePath(RoutePaths.USER_FEEDBACK_DETAILS, {
            workspaceSlug: selectedWorkspace?.slug,
            appKey: selectedApp?.key,
            userFeedbackKey: suggestion.key.toLocaleString(),
          })}
        >
          {suggestion.pretty_title}?
        </Link>
      </Paragraph>

      <Styled.Buttons>
        <Icon.CheckmarkIcon onClick={onMerge} />
        <CloseIcon onClick={onRemove} />
      </Styled.Buttons>
    </Flex>
  );
}

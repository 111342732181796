import React, { lazy, Suspense, useEffect } from "react";

import ErrorImage from "assets/images/FTUX/not-found-graphic.png";

import { FeedbackPreviewDialogBody } from "components/Dialog/configurations/FeedbackPreviewDialogBody";
import { SpaceToPreviewDialog } from "components/Dialog/SpaceToPreviewDialog";
import EmptyStateElement from "components/EmptyStateElement/components/EmptyStateElement";
import PageWrap from "components/PageWrap/PageWrap";
import { Spinner } from "components/Spinner/Spinner";

import { useAppSelectionContext } from "context/App/AppSelectionContext";

import { useFeedbackAttributes } from "hooks/filtering/modules/useFeedbackAttributes";
import { Attributes } from "hooks/filtering/ui/Attributes";
import { AttributesContainer } from "hooks/filtering/ui/Attributes.styles";

import { PlatformName } from "models";

import { EmptyStateViewConf, EmptyTableView } from "pages/shared/components/EmptyTableView/EmptyTableView";
import { FeedbackModuleEmptyState } from "pages/shared/components/ModuleEmptyState/FeedbackModuleEmptyState";
import { SdkSetupSteps } from "pages/shared/components/SDKSetup/SdkSetupSteps";
import {
  FeedbackModuleFreePlanFooter,
  FeedbackModuleSampleAppFooter,
} from "pages/shared/components/TableFooter/TableFooter";
import { resolveEmptyStateView } from "pages/shared/helpers/resolveEmptyStateView";
import useUserFeedbackApiConsumer from "pages/UserFeedback/consumers/useUserFeedbackApiConsumer";

import { isFreePlan } from "util/workspaceUtil";

import useSearchFieldsApiConsumer from "../consumers/useSearchFieldsApiConsumer";

const Table = lazy(() => import("components/ShakeTable/BaseTable").then((module) => ({ default: module.BaseTable })));

const renderLoader = () => <Spinner />;

export const UserFeedbackListPageView: React.FC = () => {
  const { selectedApp, selectedWorkspace } = useAppSelectionContext();

  const { customFields, metadataFields } = useSearchFieldsApiConsumer();

  /// Attributes filter logic
  const feedbackAttributeState = useFeedbackAttributes(false, metadataFields, customFields);

  const { items, loading, hasMore, total, error, refetchData, feedbackTableProps, spaceToPreviewDialogProps } =
    useUserFeedbackApiConsumer({
      attributesProps: feedbackAttributeState,
    });

  const hasAttributes = Boolean(feedbackAttributeState.validAttributes.length);
  const isError = Boolean(error);

  useEffect(() => {
    if (feedbackAttributeState.validAttributes) {
      feedbackTableProps.table.resetRowSelection();
    }
    // eslint-disable-next-line
  }, [feedbackAttributeState.validAttributes]);

  // Show SDK not added screen
  if (!selectedApp?.is_approved) {
    return <SdkSetupSteps />;
  }

  // Show error screen
  if (error && !hasAttributes) {
    return (
      <EmptyStateElement
        title="There was an error loading issues. Try refreshing."
        subtitle="The link you followed may be broken, or the app may have been removed."
        imageSrc={ErrorImage}
      />
    );
  }

  const isInitialLoading = typeof items == "undefined" || loading;

  // Show data empty screen
  const hasItems = (items && items.length > 0) || total > 0;
  if (!isInitialLoading && !hasAttributes && !isError && !hasItems) {
    return (
      <>
        <PageWrap style={{ height: "100%", display: "flex", flexDirection: "column" }}>
          <FeedbackModuleEmptyState />
        </PageWrap>
      </>
    );
  }

  const emptyViewConfig = resolveEmptyStateView(
    loading,
    "feedback",
    hasItems,
    selectedApp,
    Boolean(feedbackAttributeState.validAttributes.length),
    Boolean(error),
    refetchData,
  ) as EmptyStateViewConf | undefined;

  const buildTableFooter = () => {
    if (Boolean(selectedApp?.is_sample)) return <FeedbackModuleSampleAppFooter />;
    if (isFreePlan(selectedWorkspace) && !Boolean(hasMore) && selectedApp.platform.name !== PlatformName.WEB)
      return <FeedbackModuleFreePlanFooter />;
    return null;
  };

  return (
    <>
      <PageWrap style={{ height: "100%", display: "flex", flexDirection: "column", overflow: "hidden" }}>
        <AttributesContainer>
          <Attributes
            {...feedbackAttributeState}
            searchField="title"
          />
        </AttributesContainer>
        {emptyViewConfig && (
          <EmptyTableView
            withFilters
            {...emptyViewConfig}
          />
        )}
        {isInitialLoading && <Spinner />}
        {!emptyViewConfig && !isInitialLoading && (
          <Suspense fallback={renderLoader()}>
            {
              // eslint-disable-next-line
              // @ts-ignore
              <Table
                {...feedbackTableProps}
                footer={buildTableFooter()}
                isFetchingNext={feedbackTableProps.isFetchingNext}
              />
            }
          </Suspense>
        )}
      </PageWrap>
      <SpaceToPreviewDialog {...spaceToPreviewDialogProps}>
        <FeedbackPreviewDialogBody />
      </SpaceToPreviewDialog>
    </>
  );
};

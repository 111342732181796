import styled, { css } from "styled-components";

import { SupportedFileTypes } from "models";

import { PlusIcon } from "pages/shared/icons/icons";

import { vars } from "styles";

export const MainContainer = styled.div`
  margin-bottom: 2.8rem;
`;

export const Grid = styled.div`
  margin: auto;
  display: grid;
  grid-gap: 1.6rem;
  grid-template-columns: repeat(auto-fit, 12.3rem);
  grid-auto-rows: 12.3rem;
  grid-auto-flow: dense;
  justify-content: flex-start;
`;

export const Card = styled.div<{ $type?: SupportedFileTypes; $isEmpty?: boolean; $isHelper?: boolean }>`
  grid-row: auto / span 1;
  grid-column: auto / span 1;
  border: 0.1rem solid ${vars.colors.grey90};
  border-radius: 1rem;

  ${(props) =>
    (props.$type === SupportedFileTypes.IMAGE || props.$type === SupportedFileTypes.VIDEO) &&
    css`
      grid-row: auto / span 2;
    `}

  ${(props) =>
    props.$isEmpty &&
    !props.$isHelper &&
    css`
      border: 0.1rem dashed ${vars.colors.grey100};
      border-radius: 1rem;
    `}

    ${(props) =>
    props.$isHelper &&
    css`
      border-radius: 1rem;
      border: 0rem solid transparent;
    `}

  ${(props) =>
    props.$type === SupportedFileTypes.TXT &&
    css`
      grid-column: auto / span 2;
      border-color: ${vars.colors.grey100};
    `}
`;

export const Helper = styled.div`
  padding: 1.4rem;
  border: 1px dashed ${vars.colors.grey100};
  border-radius: 1rem;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  > div {
    width: 2.4rem;
    height: 2.4rem;
  }
`;

export const Plus = styled(PlusIcon)`
  margin-top: 0.4rem;
  height: 2.4rem;
  width: 2.4rem;
  cursor: pointer;
  path {
    stroke: ${vars.colors.grey80};
  }
`;

import styled from "styled-components";

export const ImageContainer = styled.div`
  width: 12.1rem;
  height: 26rem;
  border-radius: 1rem;
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: inherit;
  opacity: 1;

  cursor: zoom-in;
`;

export const ModalImage = styled.img`
  max-height: 90vh;
  max-width: 100rem;

  cursor: zoom-out;

  border-radius: 1.5rem;
`;

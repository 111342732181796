import React from "react";

import { CenteredForm } from "components/CenteredForm/CenteredForm";
import { Spinner } from "components/Spinner/Spinner";

import { useAppContext } from "context/App/App.context";

import WorkspaceJoinElement from "../components/WorkspaceJoinElement/WorkspaceJoinElement";
import * as Styled from "./WorkspaceJoinView.styles";

export default function WorkspaceJoinView() {
  const { workspacesLoading } = useAppContext();

  if (workspacesLoading) {
    return (
      <Styled.CenteredContainer>
        <Spinner />
      </Styled.CenteredContainer>
    );
  }

  return (
    <CenteredForm>
      <WorkspaceJoinElement />
    </CenteredForm>
  );
}

import styled from "styled-components";

import { vars } from "styles";

export const UsefulLinksContainer = styled.div`
  margin-top: 1.6rem;
  width: 88rem;
  height: 36.8rem;
  border-radius: 1rem;
  border: 1px solid ${vars.colors.grey100};
  display: grid;
  grid-template-columns: auto auto auto;
`;

export const UsefulLink = styled.a`
  margin: 0.4rem;
  border-radius: 1rem;
  min-width: 22.8rem;
  height: 17.4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  text-decoration: none;

  user-select: none;

  &:active,
  &:focus-within,
  &:focus {
    text-decoration: none;
  }

  &:hover {
    text-decoration: none;
    background: ${vars.colors.grey100};
  }

  p {
    cursor: pointer;
    user-select: none;
  }
`;

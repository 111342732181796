import React from "react";

import { generatePath } from "react-router-dom";

import { useAppSelectionContext } from "context/App/AppSelectionContext";

import { RoutePaths } from "router/config/routePaths";

import identifiers from "util/identifiers.json";
import { t } from "util/translator";

import * as Styled from "./Sidebar.styles";
import SidebarMenuItem, { SidebarMenuDelayedLinkItem } from "./SidebarMenuItem";

interface SidebarMenuUserProfileProps {
  active: boolean;
  toggleVisibility: () => void;
  handleSignOut: () => Promise<void>;
  userName: string;
}

export default function SidebarMenuUserProfile({
  active,
  toggleVisibility,
  handleSignOut,
  userName,
}: SidebarMenuUserProfileProps): JSX.Element {
  const { selectedWorkspace } = useAppSelectionContext();

  return (
    <Styled.SidebarMenu
      $active={active}
      $account
    >
      <Styled.SidebarMenuHeading>{userName}</Styled.SidebarMenuHeading>

      <SidebarMenuItem
        to={generatePath(RoutePaths.ACCOUNT_PERSONAL_INFO, { workspaceSlug: selectedWorkspace?.slug })}
        onClick={toggleVisibility}
        text={t("sidebar.account-canvas.my-account")}
        testId={identifiers["main.link.myAccount"]}
      />
      <SidebarMenuDelayedLinkItem
        requestBeforeReroute={handleSignOut}
        to={generatePath(RoutePaths.SIGNIN)}
        text={t("sidebar.account-canvas.sign-out")}
        testId={identifiers["main.link.signOut"]}
      />
    </Styled.SidebarMenu>
  );
}

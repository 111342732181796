import React from "react";

import background from "assets/images/empty-state/crashes-module-empty-heading.png";

import CodeBlock, { Tab } from "components/CodeBlock/CodeBlock";
import {
  TabController,
  useAndroidTabController,
  useIosTabController,
} from "components/CodeBlock/useCodeTabsController";
import Paragraph from "components/Paragraph/Paragraph";

import { useAppSelectionContext } from "context/App/AppSelectionContext";

import { PlatformName } from "models";

import { CrashesModuleDescriptionBox } from "pages/shared/components/ModuleDescriptionBox/ModuleDescriptionBox";
import {
  androidCrashesSetup,
  iosCrashesSetup,
} from "pages/shared/components/ModuleEmptyState/helpers/crashesSetupSteps";

import { docLink } from "util/docs";

import { SetupStep } from "../SetupStep/SetupStep";
import * as Styled from "./ModuleEmptyState.styles";

export const CrashesModuleEmptyState = () => {
  const { selectedApp } = useAppSelectionContext();

  const androidTabController = useAndroidTabController();
  const iosTabController = useIosTabController();

  switch (selectedApp?.platform?.name) {
    case PlatformName.ANDROID:
      return (
        <CrashesEmptyStateView
          codeBlock1Tabs={androidCrashesSetup.enableCrashesCode}
          codeBlock2Tabs={androidCrashesSetup.testCrashesCode}
          tabController={androidTabController}
        />
      );
    case PlatformName.IOS:
      return (
        <CrashesEmptyStateView
          codeBlock1Tabs={iosCrashesSetup.enableCrashesCode}
          codeBlock2Tabs={iosCrashesSetup.testCrashesCode}
          tabController={iosTabController}
        />
      );
    default:
      return null;
  }
};

export interface Props {
  codeBlock1Tabs: Tab[];
  codeBlock2Tabs: Tab[];
  tabController: TabController;
}

const CrashesEmptyStateView = ({ codeBlock1Tabs, codeBlock2Tabs, tabController }: Props) => {
  const { selectedApp } = useAppSelectionContext();

  return (
    <Styled.BackgroundContainer style={{ backgroundImage: `url("${background}")` }}>
      <Styled.Container>
        <Styled.HeadingBoxWrapper>
          <CrashesModuleDescriptionBox />
        </Styled.HeadingBoxWrapper>
        <SetupStep
          number={1}
          title={"Enable crash reports"}
        />
        <Styled.StepContent>
          <CodeBlock
            tabController={tabController}
            tabs={codeBlock1Tabs}
          />
        </Styled.StepContent>
        <SetupStep
          number={2}
          title={"Add deobfuscation files"}
        />
        <Styled.StepContent>
          <Paragraph lineHeight={26}>
            To ensure that crash reports are readable, you should upload the necessary symbolication or mapping files to
            Shake. Please refer to the documentation for{" "}
            {<a href={docLink(selectedApp?.platform.name).deobfuscation}>instructions on how to upload these files</a>}.
          </Paragraph>
        </Styled.StepContent>
        <SetupStep
          number={3}
          title={"Test it"}
        />
        <Styled.StepContent>
          <Paragraph
            lineHeight={26}
            marginBottom={12}
          >
            {"Now crash your app to see what the crash report looks like on your Shake dashboard."}
          </Paragraph>
          <CodeBlock
            tabController={tabController}
            tabs={codeBlock2Tabs}
          />
          <Paragraph
            lineHeight={26}
            marginTop={12}
            marginBottom={60}
          >
            {"Reopen your app and the crash report will be sent to you instantly and silently."}
          </Paragraph>
        </Styled.StepContent>
      </Styled.Container>
    </Styled.BackgroundContainer>
  );
};

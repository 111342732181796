import { AxiosResponse } from "axios";

import { PlatformOs } from "models";
import { App } from "models/App.model";
import { CheckoutRes, CreditCard, Customer, Invoice, Plan } from "models/billing";
import { Certificate } from "models/Certificate.model";
import { CommonComment } from "models/CommonComment.model";
import {
  AzureAuthorizePayload,
  AzureIntegrationPayload,
  IntegrationPayload,
  IntegrationType,
  JiraIntegrationUpdatePayload,
  StartIntegrationPayload,
} from "models/integrations";
import { MappingFile } from "models/MappingFile.model";
import { Member, MemberRole } from "models/Member.model";
import { ApprovedDomain, Whitelabel } from "models/organization";
import { AttributeRuleForRequest, AvailableRule, Rule, RulesModule, TriggerForRequest } from "models/Rule.model";
import { WorkspaceData } from "models/WorkspaceData.model";

import ApiService from "services/Api.service";

import app from "../../../App";
import { ApiKey } from "../../../models/ApiKey.model";

export default class AdministrationService {
  private apiService: ApiService;

  constructor() {
    this.apiService = ApiService.getInstance();
  }

  getApprovedDomains(workspaceId: string, abortSignal?: AbortSignal) {
    return this.apiService.get<[ApprovedDomain]>({
      resource: `/accounts/teams/${workspaceId}/approved_domain`,
      config: { signal: abortSignal },
    });
  }

  addApprovedDomain(workspaceId: string, domain: string, verificationEmail: string) {
    return this.apiService.post<ApprovedDomain>({
      resource: `/accounts/teams/${workspaceId}/approved_domain`,
      data: {
        name: domain,
        verification_email: verificationEmail,
      },
    });
  }

  deleteApprovedDomain(workspaceId: string, approvedDomainId: string): Promise<AxiosResponse<void>> {
    return this.apiService.delete({
      resource: `/accounts/teams/${workspaceId}/approved_domain/${approvedDomainId}`,
    });
  }

  inviteMembers(workspaceId: string, members: string[], role: MemberRole, appIds?: string[]): Promise<AxiosResponse> {
    return this.apiService.post({
      resource: `/accounts/teams/${workspaceId}/invite`,
      data: {
        emails: members,
        role: role,
        apps: appIds,
      },
    });
  }

  resendInvite(workspaceId: string, inviteId: string): Promise<AxiosResponse> {
    return this.apiService.post({
      resource: `accounts/teams/${workspaceId}/invite/${inviteId}`,
    });
  }

  deleteMember(workspaceId: string, userId: string): Promise<AxiosResponse<void>> {
    return this.apiService.delete({
      resource: `/accounts/teams/${workspaceId}/members/${userId}`,
    });
  }

  deleteMemberInvitation(workspaceId: string, workspaceInvitationId: string): Promise<AxiosResponse<void>> {
    return this.apiService.delete({
      resource: `/accounts/teams/${workspaceId}/invite/${workspaceInvitationId}
      `,
    });
  }

  updateTeamSettings(workspaceId: string, data: Partial<WorkspaceData>) {
    return this.apiService.patch<WorkspaceData>({
      resource: `accounts/teams/${workspaceId}`,
      data,
    });
  }

  updateTeamLogo(workspaceId: string, file: File) {
    const form = new FormData();
    if (file) form.append("file", file);

    return this.apiService.post<WorkspaceData>({
      resource: `accounts/teams/${workspaceId}/logo`,
      data: form,
    });
  }

  deleteTeamLogo(workspaceId: string) {
    return this.apiService.delete<WorkspaceData>({
      resource: `accounts/teams/${workspaceId}/logo`,
    });
  }

  deleteTeamById(workspaceId: string) {
    return this.apiService.delete<WorkspaceData>({
      resource: `accounts/teams/${workspaceId}`,
    });
  }

  activateApprovedDomain(code: string) {
    return this.apiService.post<ApprovedDomain>({
      resource: `/accounts/teams/approved_domain_activation`,
      data: {
        code,
      },
    });
  }

  customizeTeamMember(workspaceId: string, userId: string, role: MemberRole, apps: string[]) {
    return this.apiService.put<Member>({
      resource: `accounts/teams/${workspaceId}/members/${userId}`,
      data: {
        role,
        apps,
      },
    });
  }

  updateApp(teamId: string, appId: string, data: Partial<App>) {
    return this.apiService.patch<App>({
      resource: `issue_tracking/${teamId}/apps/${appId}`,
      data,
    });
  }

  updateAppLogo(teamId: string, appId: string, file: File) {
    const form = new FormData();
    if (file) form.append("file", file);

    return this.apiService.post<App>({
      resource: `issue_tracking/${teamId}/apps/${appId}/logo`,
      data: form,
    });
  }

  deletAppLogo(teamId: string, appId: string) {
    return this.apiService.delete<App>({
      resource: `issue_tracking/${teamId}/apps/${appId}/logo`,
    });
  }

  deactivateApp(teamId: string, appId: string) {
    return this.apiService.delete<App>({
      resource: `issue_tracking/${teamId}/apps/${appId}`,
    });
  }

  addMappingFile(
    workspaceId: string,
    appId: string,
    versionName: string,
    versionCode: string,
    platform: string,
    os: string,
    file: File | null,
  ) {
    const form = new FormData();
    form.append("app_version_name", versionName);
    form.append("app_version_code", versionCode);
    form.append("os", os);
    form.append("platform", platform);
    if (file) form.append("file", file);

    return this.apiService.post<MappingFile>({
      resource: `/crash_reporting/${workspaceId}/apps/${appId}/app_debug_file`,
      data: form,
    });
  }

  getMappingFiles(workspaceId: string, appId: string, os: PlatformOs) {
    return this.apiService.get<MappingFile[]>({
      resource: `/crash_reporting/${workspaceId}/apps/${appId}/${this.resolveGetMappingFilesEndpoint(os)}`,
    });
  }

  deleteMappingFile(workspaceId: string, appId: string, fileId: string) {
    return this.apiService.delete<MappingFile[]>({
      resource: `/crash_reporting/${workspaceId}/apps/${appId}/app_debug_file/${fileId}`,
    });
  }

  disconnectSentry(workspaceId: string) {
    return this.apiService.delete<WorkspaceData>({
      resource: `/service_hooks/${workspaceId}/sentry`,
    });
  }

  disconnectIntegrationByType(workspaceId: string, appId: string, integrationType: IntegrationType) {
    if (integrationType === IntegrationType.SENTRY) return this.disconnectSentry(workspaceId);
    return this.apiService.delete<WorkspaceData>({
      resource: `/service_hooks/${workspaceId}/app/${appId}/${integrationType.toLocaleLowerCase()}`,
    });
  }

  exportTickets(workspaceId: string, appId: string) {
    return this.apiService.post({
      resource: `/issue_tracking/${workspaceId}/apps/${appId}/issues/export`,
    });
  }

  integrationInfo<T>(
    workspaceId: string,
    integrationType: IntegrationType,
    signal: AbortSignal,
    appId: string,
    nextPage?: string,
  ) {
    switch (integrationType) {
      case IntegrationType.ZAPIER:
        return this.apiService.get<T>({
          resource: `/service_hooks/${workspaceId}/app/${appId}/${integrationType.toLocaleLowerCase()}`,
          config: {
            signal,
          },
        });
      default:
        return this.apiService.get<T>({
          resource: nextPage
            ? `/service_hooks/${workspaceId}/app/${appId}/projects/${integrationType.toLocaleLowerCase()}?next_page=${nextPage}`
            : `/service_hooks/${workspaceId}/app/${appId}/projects/${integrationType.toLocaleLowerCase()}`,
          config: {
            signal: nextPage ? undefined : signal,
          },
        });
    }
  }

  integrate<Res>(workspaceId: string, appId: string, integrationType: IntegrationType, payload: IntegrationPayload) {
    return this.apiService.post<Res>({
      resource: `/service_hooks/${workspaceId}/app/${appId}/${integrationType.toLocaleLowerCase()}/integrate`,
      data: payload,
    });
  }

  authorizeAzure<Res>(workspaceId: string, appId: string, payload: AzureAuthorizePayload) {
    return this.apiService.post<Res>({
      resource: `/service_hooks/${workspaceId}/app/${appId}/${IntegrationType.AZURE_DEVOPS.toLocaleLowerCase()}`,
      data: payload,
    });
  }

  integrateAzure<T>(
    workspaceId: string,
    appId: string,
    integrationType: IntegrationType,
    payload: AzureIntegrationPayload,
  ) {
    return this.apiService.post<T>({
      resource: `/service_hooks/${workspaceId}/app/${appId}/projects/${integrationType.toLocaleLowerCase()}`,
      data: payload,
    });
  }

  integrateJira<T>(workspaceId: string, appId: string, payload: JiraIntegrationUpdatePayload) {
    return this.apiService.post<T>({
      resource: `/service_hooks/${workspaceId}/app/${appId}/projects/jira`,
      data: payload,
    });
  }

  integrateLegacy<Res>(
    workspaceId: string,
    appId: string,
    integrationType: IntegrationType,
    payload: IntegrationPayload,
  ) {
    return this.apiService.post<Res>({
      resource: `/service_hooks/${workspaceId}/app/${appId}/${integrationType.toLocaleLowerCase()}`,
      data: payload,
    });
  }

  activateIntegration<T>(workspaceId: string, appId: string, integrationType: IntegrationType, payload: unknown) {
    return this.apiService.post<T>({
      resource: `/service_hooks/${workspaceId}/app/${appId}/${integrationType.toLocaleLowerCase()}/activate`,
      data: payload,
    });
  }

  updateIntegration<Res>(
    workspaceId: string,
    appId: string,
    integrationType: IntegrationType,
    payload: IntegrationPayload,
  ) {
    return this.apiService.patch<Res>({
      resource: `/service_hooks/${workspaceId}/app/${appId}/${integrationType.toLocaleLowerCase()}`,
      data: payload,
    });
  }

  updateJiraIntegration<Res>(workspaceId: string, appId: string, payload: JiraIntegrationUpdatePayload) {
    return this.apiService.post<Res>({
      resource: `/service_hooks/${workspaceId}/app/${appId}/projects/jira`,
      data: payload,
    });
  }

  startIntegration<Res>(
    workspaceId: string,
    appId: string,
    integrationType: IntegrationType,
    payload?: StartIntegrationPayload,
  ) {
    return this.apiService.post<Res>({
      resource: `/service_hooks/${workspaceId}/app/${appId}/${integrationType.toLocaleLowerCase()}`,
      data: payload,
    });
  }

  resolveGetMappingFilesEndpoint = (os: PlatformOs) => {
    switch (os) {
      case PlatformOs.IOS:
        return "missing_dsyms";
      case PlatformOs.ANDROID:
        return "app_debug_file";
    }
  };

  getSubscriptionPlans() {
    return this.apiService.get<Plan[]>({
      resource: `/billing/plans`,
    });
  }

  fetchSubscriptionInfo(selectedWorkspaceId: string) {
    return this.apiService.get<Plan>({
      resource: `/billing/${selectedWorkspaceId}/subscription`,
    });
  }

  addSubscription(workspaceId: string, planIds: string[]) {
    return this.apiService.post({
      resource: `/billing/${workspaceId}/subscription`,
      data: {
        plans: planIds,
      },
    });
  }

  createCheckoutSession(workspaceId: string, planIds: string[]) {
    return this.apiService.post<CheckoutRes>({
      resource: `/billing/${workspaceId}/checkout`,
      data: {
        plans: planIds,
      },
    });
  }

  updatePaymentMethod(workspaceId: string) {
    return this.apiService.put<CheckoutRes>({
      resource: `/billing/${workspaceId}/checkout`,
    });
  }

  getUpcomingInfo(workspaceId: string, planIds: string[]) {
    return this.apiService.post<{ amount: number }>({
      resource: `/billing/${workspaceId}/upcoming?plans=${planIds}`,
      data: {
        plans: planIds ?? [],
      },
    });
  }

  changeSubscription(workspaceId: string, planIds: string[]) {
    return this.apiService.put({
      resource: `/billing/${workspaceId}/subscription`,
      data: {
        plans: planIds ?? [],
      },
    });
  }

  getCreditCard(workspaceId: string) {
    return this.apiService.get<CreditCard>({
      resource: `/billing/${workspaceId}/credit_card`,
    });
  }

  getInvoices(workspaceId: string) {
    return this.apiService.get<Invoice[]>({
      resource: `/billing/${workspaceId}/invoices`,
    });
  }

  changeCustomerInfo(selectedWorkspaceId: string, data: Partial<Customer>) {
    return this.apiService.patch<Customer>({
      resource: `/billing/${selectedWorkspaceId}/customer`,
      data,
    });
  }

  handleSubscriptionActivation(workspaceId: string, isActive: boolean) {
    return this.apiService.patch({
      resource: `/billing/${workspaceId}/subscription`,
      data: { cancel_at_period_end: !isActive },
    });
  }

  getWhiteLabel(workspaceId: string) {
    return this.apiService.get<Whitelabel>({
      resource: `/white_labeling/${workspaceId}`,
    });
  }

  updateWhiteLabel(workspaceId: string, slogan: string, backgroundColor: string) {
    return this.apiService.put({
      resource: `/white_labeling/${workspaceId}`,
      data: {
        slogan: slogan,
        background_color: backgroundColor,
      },
    });
  }

  // eslint-disable-next-line
  uploadImportFile(file: File, teamId: string, appId: string, onUploadProgress: (progressEvent: any) => void) {
    const form = new FormData();
    form.append("file", file);

    return this.apiService.post({
      resource: `/issue_tracking/${teamId}/apps/${appId}/import`,
      data: form,
      config: {
        onUploadProgress,
      },
    });
  }

  getCertificate(workspaceId: string, appId: string) {
    return this.apiService.get<Certificate>({
      resource: `/user_model/${workspaceId}/apps/${appId}/push_notifications`,
    });
  }

  deleteCertificate(workspaceId: string, appId: string) {
    return this.apiService.delete<Certificate>({
      resource: `/user_model/${workspaceId}/apps/${appId}/push_notifications`,
    });
  }

  addCertificate(workspaceId: string, appId: string, key: string, file: File | null, isDevelopment?: boolean) {
    const form = new FormData();
    if (file) form.append("file", file);
    if (key) form.append("server_key", key);
    if (isDevelopment) form.append("is_development", isDevelopment.toString());

    return this.apiService.post<Certificate>({
      resource: `/user_model/${workspaceId}/apps/${appId}/push_notifications`,
      data: form,
    });
  }

  getCommonComments(workspaceId: string) {
    return this.apiService.get<CommonComment[]>({
      resource: `/user_model/${workspaceId}/common_replies`,
    });
  }

  addCommonComment(workspaceId: string, title: string, message: string) {
    return this.apiService.post<CommonComment[]>({
      resource: `/user_model/${workspaceId}/common_replies`,
      data: {
        title,
        message,
      },
    });
  }

  updateCommonComment(workspaceId: string, reply_id: string, title: string, message: string) {
    return this.apiService.put({
      resource: `/user_model/${workspaceId}/common_replies/${reply_id}`,
      data: {
        title,
        message,
      },
    });
  }

  deleteCommonComment(workspaceId: string, commentId: string): Promise<AxiosResponse<void>> {
    return this.apiService.delete({
      resource: `/user_model/${workspaceId}/common_replies/${commentId}`,
    });
  }

  getRules(workspaceId: string, appId: string) {
    return this.apiService.get<Rule[]>({
      resource: `/rules/${workspaceId}/apps/${appId}/rules`,
    });
  }

  addRule(
    workspaceId: string,
    appId: string,
    name: string,
    active: boolean,
    module: RulesModule,
    trigger: TriggerForRequest,
    conditions: AttributeRuleForRequest[],
    actions: AttributeRuleForRequest[],
  ) {
    return this.apiService.post<Rule>({
      resource: `/rules/${workspaceId}/apps/${appId}/rules`,
      data: { name, active, module, trigger, conditions, actions },
    });
  }

  updateRule(
    workspaceId: string,
    appId: string,
    ruleId: string,
    name: string,
    active: boolean,
    module: RulesModule,
    trigger: TriggerForRequest,
    conditions: AttributeRuleForRequest[],
    actions: AttributeRuleForRequest[],
  ) {
    return this.apiService.put<Rule>({
      resource: `/rules/${workspaceId}/apps/${appId}/rules/${ruleId}`,
      data: { name, active, module, trigger, conditions, actions },
    });
  }

  getAvailableRules() {
    return this.apiService.get<AvailableRule>({
      resource: `/rules/available_rules`,
    });
  }

  deleteRule(workspaceId: string, appId: string, ruleId: string) {
    return this.apiService.delete<Rule>({
      resource: `/rules/${workspaceId}/apps/${appId}/rules/${ruleId}`,
    });
  }

  getApiKeys(workspaceId: string, appId: string) {
    return this.apiService.get<ApiKey[]>({
      resource: `/accounts/${workspaceId}/apps/${appId}/api_keys`,
    });
  }

  addApiKey(workspaceId: string, appId: string) {
    return this.apiService.post<ApiKey>({
      resource: `/accounts/${workspaceId}/apps/${appId}/api_keys`,
    });
  }

  deleteApiKey(workspaceId: string, appId: string, apiKeyId: string) {
    return this.apiService.delete({
      resource: `/accounts/${workspaceId}/apps/${appId}/api_keys/${apiKeyId}`,
    });
  }
}

import React, { lazy, ReactNode, Suspense } from "react";

import Paragraph from "components/Paragraph/Paragraph";
import { useKeyboardPress } from "components/ShakeTable/useKeyboardPress";
import { Spinner } from "components/Spinner/Spinner";

import { useArrowUpDownKeyPress } from "hooks/useArrowUpDownKeyPress";

import { LogType, LogUnionType, NetworkRequest } from "models/eventTracking";
import { resolveLogTimeString } from "models/helpers/time/creationTime";

import { Flex } from "styles/reusable/Flex/Flex.styles";

import { Close, HeaderContainer, HeaderTip, KeyboardKey, LineSeparator } from "./LogDetails.styles";
const Comp = lazy(() => import("./NetworkRequestDetails"));
import * as Styled from "./NetworkRequestDetails.styles";

interface LogDetailsProps {
  log: LogUnionType;
  navigateToNext?: () => void;
  navigateToPrevious?: () => void;
  closeDrawer: () => void;
}

export const LogDetails = ({ log, navigateToNext, navigateToPrevious, closeDrawer }: LogDetailsProps) => {
  useArrowUpDownKeyPress({
    onArrowDown: navigateToNext,
    onArrowUp: navigateToPrevious,
  });

  useKeyboardPress({
    keyboardKey: "Escape",
    onPress: () => closeDrawer(),
    enabled: true,
  });

  return presentationComponent(log, closeDrawer);
};

const renderLoader = () => <Spinner />;

const presentationComponent = (log: LogUnionType, closeDrawer: () => void) => {
  if (log.instance_type === LogType.NETWORK_REQUEST) {
    return (
      <Suspense fallback={renderLoader}>
        <Comp
          log={log}
          closeDrawer={closeDrawer}
        />
      </Suspense>
    );
  }

  const title = drawerTitleForLogType(log);

  return (
    <div>
      <DrawerHeader
        title={title}
        close={closeDrawer}
      />
      <LineSeparator />
      {logToDetailsPresentation(log)}
    </div>
  );
};

export const DrawerHeader = ({ header, title, close }: { header?: JSX.Element; title?: string; close: () => void }) => {
  return (
    <HeaderContainer>
      <Flex
        $gap={2.8}
        $alignItems="center"
      >
        <Close onClick={close} />
        {title && !header && <Paragraph>{title}</Paragraph>}
      </Flex>
      {header || <div />}
      <HeaderTip>
        <KeyboardKey>Esc</KeyboardKey> to close. <KeyboardKey>↑</KeyboardKey> and <KeyboardKey>↓</KeyboardKey> to
        navigate.
      </HeaderTip>
    </HeaderContainer>
  );
};

const drawerTitleForLogType = (log: Exclude<LogUnionType, NetworkRequest>) => {
  switch (log.instance_type) {
    case LogType.USER_EVENT:
      return "User action";
    case LogType.NOTIFICATION:
      return "Notification";
    case LogType.SYSTEM_EVENT:
      return "System event";
    case LogType.SCREEN_EVENT:
      return "Screen change event";
    case LogType.CONSOLE_LOG:
      return "Console log";
    case LogType.CUSTOM_LOG:
      return "Custom log";
  }
};

const logToDetailsPresentation = (log: Exclude<LogUnionType, NetworkRequest>) => {
  const CommonBody = ({ children }: { children: ReactNode }) => (
    <Styled.PresentationFlex>
      <Styled.HeadersGrid>
        <Styled.KeyWrap>Time</Styled.KeyWrap>
        <Styled.NoOverflow>{resolveLogTimeString(log.timestamp)}</Styled.NoOverflow>
        {children}
      </Styled.HeadersGrid>
    </Styled.PresentationFlex>
  );

  const logContentMap = new Map<string, string | number>();
  switch (log.instance_type) {
    case LogType.USER_EVENT:
      if (log.class_name.startsWith("#SHAKE")) {
        logContentMap.set("Shake Event", log.description);
        break;
      }
      logContentMap.set("Touch type", log.touch_type).set("Element class", log.class_name);
      break;
    case LogType.NOTIFICATION:
      logContentMap.set("Title", log.title).set("Body", log.description);
      break;
    case LogType.SYSTEM_EVENT:
      logContentMap.set("Message", log.description);
      break;
    case LogType.SCREEN_EVENT:
      logContentMap.set("Message", log.description);
      break;
    case LogType.CONSOLE_LOG:
      logContentMap.set("Message", log.message);
      break;
    case LogType.CUSTOM_LOG:
      logContentMap.set("Level", log.level.toUpperCase()).set("Message", log.message);
      break;
  }

  return (
    <CommonBody>
      {Array.from(logContentMap).map(([key, value]) => {
        return (
          <>
            <Styled.KeyWrap>{key}</Styled.KeyWrap>
            <Styled.NoOverflow>{value}</Styled.NoOverflow>
          </>
        );
      })}
    </CommonBody>
  );
};

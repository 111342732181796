import React, { Fragment, useEffect } from "react";

import { generatePath, matchPath, NavLink, Outlet, useLocation, useNavigate, useParams } from "react-router-dom";

import { ButtonNavigation } from "components/Navigation/ButtonNavigation/ButtonNavigation.styles";
import { Spinner } from "components/Spinner/Spinner";

import useWorkspaceAppsApiConsumer from "consumers/useWorkspaceAppsApiConsumer";

import { useAppContext } from "context/App/App.context";
import { useAppSelectionContext } from "context/App/AppSelectionContext";

import { isNativePlatform, PlatformName, Workspace } from "models";

import { RoutePaths } from "router/config/routePaths";
import { appsRouteConfigsWithKey, PathRouteConfig, RouteConfigType } from "router/config/routesConfig";

export const AppsViewModule = () => {
  const { selectedWorkspace } = useAppSelectionContext();
  const { fetchAllApps } = useWorkspaceAppsApiConsumer();
  const { apps } = useAppContext();
  const { appKey } = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!selectedWorkspace) return;

    if (!apps || !apps.length) fetchAllApps(selectedWorkspace.id);
  }, [fetchAllApps, selectedWorkspace, apps]);

  useEffect(() => {
    if (!appKey && apps && apps.length) {
      const chosenApp = apps.find((a) => a.active) ? apps.find((a) => a.active) : apps[0];

      const route = !matchPath(RoutePaths.ADMINISTRATION_APPS, location.pathname)
        ? location.pathname + `/${chosenApp?.key}`
        : generatePath(RoutePaths.ADMINISTRATION_APPS_GENERAL_KEY, {
            workspaceSlug: selectedWorkspace?.slug,
            appKey: chosenApp?.key,
          });
      navigate(route);
    }
  }, [appKey, apps, navigate, selectedWorkspace, location.pathname]);

  if (!selectedWorkspace) {
    return <Spinner />;
  }

  const appsLinks = appsRouteConfigsWithKey
    .filter((routeConfig) => routeConfig.type === RouteConfigType.PATH)
    .map((pathRouteConfig) => {
      const config = pathRouteConfig as PathRouteConfig;
      const app = apps.find((app) => app.key === appKey);
      const isWeb = app?.platform.name === PlatformName.WEB;
      if (matchPath(config.path, RoutePaths.ADMINISTRATION_APPS_USERS_KEY) && app && isWeb) return;
      if (matchPath(config.path, RoutePaths.ADMINISTRATION_APPS_CRASHES_KEY) && app && !isNativePlatform(app)) return;

      return mapRouteConfigToNavLink(config, selectedWorkspace, !!matchPath(config.path, location.pathname), appKey);
    });

  return (
    <Fragment>
      <ButtonNavigation>{appsLinks}</ButtonNavigation>
      <Outlet />
    </Fragment>
  );
};

const mapRouteConfigToNavLink = (config: PathRouteConfig, workspace: Workspace, isActive: boolean, appKey?: string) => {
  if (!appKey) return;
  const to = generatePath(config.path, { workspaceSlug: workspace.slug, appKey: appKey });
  return (
    <div
      className={isActive ? "active" : ""}
      key={config.name}
    >
      <NavLink
        to={to}
        data-testid={config.testId}
      >
        {config.name}
      </NavLink>
    </div>
  );
};

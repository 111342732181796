import React, { useEffect } from "react";

import Shake from "@shakebugs/browser";
import { generatePath, Outlet, useLocation, useNavigate } from "react-router-dom";

import { useAppContext } from "context/App/App.context";
import { useAppSelectionContext } from "context/App/AppSelectionContext";
import { SubscriptionState, useSubscriptionContext } from "context/Subscription/SubscriptionContext";

import { PageNavigation } from "layouts/components/PageNavigation/PageNavigation";

import { App } from "models";

import { useWorkspaceAppParams } from "pages/IntegrationActivation/helpers/useWorkspaceAppParams";

import { getRouteFromPath } from "util/moduleRouteHelper";

export function ModuleLayoutView() {
  const { apps, loading: appsLoading } = useAppContext();
  const { selectedWorkspace, selectedApp } = useAppSelectionContext();

  const navigate = useNavigate();
  const location = useLocation();
  const params = useWorkspaceAppParams();
  const { state } = useSubscriptionContext();

  useEffect(() => {
    if (appsLoading || selectedApp || !selectedWorkspace || state !== SubscriptionState.good) return;

    const pickedApp = getSelectedApp(apps);

    const routePath = getRouteFromPath(location.pathname);
    pickedApp &&
      navigate(generatePath(routePath, { workspaceSlug: selectedWorkspace.slug, appKey: pickedApp.key }), {
        replace: true,
      });
  }, [appsLoading, selectedApp, selectedWorkspace, state, apps, params, location.pathname, navigate]);

  return (
    <PageNavigation>
      <Outlet />
    </PageNavigation>
  );
}

function getSelectedApp(availableApps: App[]) {
  let selectedApp: App | undefined;
  const activeApps = availableApps.filter((a) => a.active);
  if (!selectedApp) selectedApp = activeApps[0];

  Shake.setMetadata("app_id", selectedApp?.id ?? "");
  Shake.setMetadata("app_key", selectedApp?.key ?? "");
  Shake.setMetadata("app_name", selectedApp?.name ?? "");
  Shake.setMetadata("app_bundle_id", selectedApp?.bundle_id ?? "");

  if (localStorage.getItem("localAppKey") && activeApps.find((a) => a.key === localStorage.getItem("localAppKey")))
    selectedApp = availableApps.find((a) => a.key === localStorage.getItem("localAppKey"));

  return selectedApp;
}

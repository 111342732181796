import styled from "styled-components";

export const InputContainer = styled.div`
  padding-top: 0.8rem;
  padding-bottom: 1rem;
`;

export const ModalInput = styled.div`
  margin-bottom: 2rem;
`;

import React from "react";

import { FloatingOverlay, FloatingPortal } from "@floating-ui/react";
import { CellContext, Table } from "@tanstack/react-table";
import { Row } from "@tanstack/react-table";
import { DragSourceMonitor, DropTargetMonitor, useDrag, useDrop } from "react-dnd";
import { usePreview } from "react-dnd-preview";
import styled from "styled-components";

import { ReactComponent as DuplicateIcon } from "assets/images/ticket-icons/duplicate-icon.svg";

import { DropdownItem } from "components/Dropdown/Dropdown.styles";
import { CrashReportTableRowItem, IssueTableRowItem } from "components/MasterTable/models/MasterTableModel";
import Paragraph from "components/Paragraph/Paragraph";
import Tooltip from "components/Tooltip/Tooltip";

import { PopoverInput } from "hooks/filtering/ui/Attributes.styles";
import { usePopover } from "hooks/filtering/ui/SimplePopover";

import { TicketPriority } from "models/TicketPriority.model";
import { TicketStatus } from "models/TicketStatus.model";

import * as Icons from "pages/shared/icons/icons";

import { vars } from "styles";
import { Flex } from "styles/reusable/Flex/Flex.styles";

import identifiers from "util/identifiers.json";

import { fireConfetti } from "../../../util/confetti";
import { AssigneeCell } from "./AssigneeCell";
import { BaseCell } from "./BaseCell";
import { Indicator, Truncate } from "./BaseTableStyles";
import { InputCheckBox } from "./InputCheckBox";

const DRAG_TYPE_ROW = "row";

type TicketCellProps<TData> = {
  status?: TicketStatus;
  priority?: TicketPriority;
  title: string;
  table?: Table<TData>;
  info: CellContext<unknown, string>;
  showsSelection?: boolean;
  showsIndicator: boolean;
  onChangeStatusRequest: (status: string) => Promise<void>;
  onChangePriorityRequest: (priority: string) => Promise<void>;
  linkedTicketsCount?: number;
  onMergeAction?: (rows: TData[], mergeRow: TData, resetSelection?: () => void) => void;
  onChangeAssigneeRequest: (assignee_id: string | null, ticketID: string) => Promise<void>;
};

export function TicketTitleCell<TData>({
  status,
  priority,
  table,
  title,
  showsIndicator,
  info,
  showsSelection = true,
  onChangeStatusRequest,
  onChangePriorityRequest,
  linkedTicketsCount,
  onMergeAction,
  onChangeAssigneeRequest,
}: TicketCellProps<TData>) {
  const [{ isHovering }, dropRef] = useDrop({
    accept: DRAG_TYPE_ROW,
    collect: (monitor: DropTargetMonitor<Row<unknown>, unknown>) => ({
      isHovering: monitor.isOver(),
    }),
    drop: (item: Row<TData>) => {
      if (!table) return;
      const selectedRows = table?.getSelectedRowModel().rows;
      if (
        //eslint-disable-next-line
        //@ts-ignore
        (selectedRows && selectedRows.find((a) => a.original.id === (info.row.original as TData).id)) ||
        //eslint-disable-next-line
        //@ts-ignore
        info.row.original.id === item.original.id
      )
        return;

      onMergeAction?.(
        selectedRows && Boolean(selectedRows.length) ? selectedRows.map((row) => row.original) : [item.original],
        info.row.original as TData,
      );

      table?.resetRowSelection();
    },
  });

  const [{ isDragging, draggedItem }, dragRef] = useDrag({
    collect: (monitor: DragSourceMonitor<Row<unknown>, unknown>) => ({
      isDragging: monitor.isDragging(),
      draggedItem: monitor.getItem(),
    }),
    item: () => info.row,
    type: DRAG_TYPE_ROW,
    canDrag: () => {
      return onMergeAction ? true : false;
    },
  });

  return (
    <BaseCell
      noYPadding={true}
      noLeftPadding={true}
    >
      <div
        style={{
          display: "flex",
          height: "100%",
          alignItems: "center",
          position: "relative",
          cursor: isDragging ? "grabbing" : "pointer",
        }}
      >
        {DragPreview(
          table?.getSelectedRowModel().rows.length
            ? (table?.getSelectedRowModel().rows as unknown as Row<IssueTableRowItem>[])
            : draggedItem
            ? [draggedItem as Row<IssueTableRowItem>]
            : undefined,
        )}

        <div
          ref={dropRef}
          style={{
            position: "absolute",
            zIndex: 2,
            left: 0,
            width: "200%",
            height: "4.8rem",
            borderRadius: "1rem",
            border:
              isHovering && !isDragging && !table?.getSelectedRowModel().rows.find((row) => row.id === info.row.id)
                ? `1px solid ${vars.colors.purple}`
                : undefined,
            cursor: isDragging ? "grabbing" : "pointer",
          }}
        >
          <span
            ref={dragRef}
            style={{
              position: "absolute",
              left: 0,
              zIndex: 2,
              width: "100%",
              height: "4.8rem",
              top: isDragging ? 1 : undefined,
              background: isDragging && !table?.getSelectedRowModel().rows.length ? vars.colors.black : undefined,
              border: isDragging && !table?.getSelectedRowModel().rows.length ? vars.colors.black : undefined,
              opacity: isDragging && !table?.getSelectedRowModel().rows.length ? 0.7 : undefined,
              borderRadius: "1rem",
              cursor: isDragging ? "grabbing" : "pointer",
            }}
          >
            {onMergeAction && (
              <span style={{ position: "fixed", right: "20px", height: "48px", width: "60px", cursor: "grab" }} />
            )}
          </span>
        </div>
        {showsSelection && (
          <CellSelectionBox
            info={info}
            showsIndicator={showsIndicator}
          />
        )}
        <StatusSelector
          status={status}
          onSelectStatus={onChangeStatusRequest}
        />
        <PrioritySelector
          priority={priority}
          onSelectPriority={onChangePriorityRequest}
        />

        <AssigneeCell
          onChangeAssigneeRequest={(assignee) =>
            onChangeAssigneeRequest(assignee, (info.row.original as IssueTableRowItem | CrashReportTableRowItem).id)
          }
          assignee={(info.row.original as IssueTableRowItem | CrashReportTableRowItem).assignee}
        />

        {linkedTicketsCount ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "transparent",
              alignSelf: "stretch",
              color: "white",
              border: "none",
              position: "relative",
            }}
          >
            <StyledTooltip
              style={{
                display: "flex",
                height: "100%",
                alignItems: "center",
                width: "100%",
              }}
              text={`${linkedTicketsCount} ${linkedTicketsCount === 1 ? "duplicate" : "duplicates"}`}
              position="top"
              offset={-3}
            >
              <Flex
                $gap={0.2}
                $alignItems="center"
                style={{ paddingRight: "1rem" }}
              >
                <DuplicateIcon />
                <Paragraph
                  fontSize={14}
                  color={vars.colors.lime}
                  style={{ cursor: "pointer" }}
                >
                  {linkedTicketsCount}
                </Paragraph>
              </Flex>
            </StyledTooltip>
          </div>
        ) : undefined}
        <Truncate style={{ color: "unset" }}>{title}</Truncate>
      </div>
    </BaseCell>
  );
}

function StatusSelector<TData>({
  status,
  onSelectStatus,
}: Pick<TicketCellProps<TData>, "status"> & { onSelectStatus: (status: string) => void }) {
  const statusPopover = usePopover({});

  function onSelectStatusClick(e: React.MouseEvent<HTMLDivElement, MouseEvent>, status: string) {
    e.stopPropagation();
    e.preventDefault();
    statusPopover.setOpen(false);
    onSelectStatus(status);
    if (status === TicketStatus.CLOSED) {
      fireConfetti();
    }
  }

  if (!status) return <></>;
  return (
    <>
      <button
        ref={statusPopover.reference}
        {...statusPopover.getReferenceProps({
          onClick: (e) => {
            e.stopPropagation();
            e.preventDefault();
          },
        })}
        style={{
          width: "30px",
          minWidth: "30px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "transparent",
          alignSelf: "stretch",
          border: "none",
          position: "relative",
        }}
      >
        <StyledTooltip
          style={{
            position: "absolute",
            height: "100%",
            zIndex: 5,
          }}
          text="Set status"
          position="top"
          offset={-3}
        />
        {(() => {
          switch (status) {
            case TicketStatus.NEW:
              return <Icons.StatusNewIcon />;
            case TicketStatus.IN_PROGRESS:
              return <Icons.InProgressIcon />;
            case TicketStatus.CLOSED:
            case TicketStatus.LOCKED:
              return <Icons.StatusDoneIcon />;
          }
        })()}
      </button>
      {statusPopover.open && (
        <FloatingPortal>
          <FloatingOverlay
            lockScroll
            style={{
              zIndex: vars.zIndex.modalOverlay,
            }}
          >
            <div
              ref={statusPopover.floating}
              {...statusPopover.getFloatingProps()}
              style={{
                position: statusPopover.strategy,
                top: statusPopover.y ?? 0,
                left: statusPopover.x ?? 0,
                minHeight: "10rem",
                backgroundColor: vars.colors.grey100,
              }}
            >
              <PopoverInput $widthPx={160}>
                <p style={{ margin: "0 2rem", fontSize: "1.4rem", color: vars.colors.grey60, marginBottom: "1rem" }}>
                  Set status
                </p>
                <DropdownItem onClick={(e) => onSelectStatusClick(e, "New")}>
                  <div style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
                    <Icons.StatusNewIcon />
                    <p>New</p>
                  </div>
                </DropdownItem>

                <DropdownItem onClick={(e) => onSelectStatusClick(e, "In progress")}>
                  <div style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
                    <Icons.InProgressIcon />
                    <p>In progress</p>
                  </div>
                </DropdownItem>

                <DropdownItem onClick={(e) => onSelectStatusClick(e, "Closed")}>
                  <div style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
                    <Icons.StatusDoneIcon />
                    <p>Closed</p>
                  </div>
                </DropdownItem>
              </PopoverInput>
            </div>
          </FloatingOverlay>
        </FloatingPortal>
      )}
    </>
  );
}

function CellSelectionBox<TData>({ showsIndicator, info }: Pick<TicketCellProps<TData>, "info" | "showsIndicator">) {
  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
      }}
      style={{
        minWidth: "40px",
        backgroundColor: "transparent",
        alignSelf: "stretch",
        position: "relative",
        zIndex: 2,
      }}
    >
      {showsIndicator && (
        <Indicator
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            marginLeft: "0.5rem",
          }}
        />
      )}
      <InputCheckBox
        style={{ position: "absolute", width: "100%", height: "100%" }}
        checked={info.row.getIsSelected()}
        onChange={(e, isShiftSelected) => {
          const handler = info.row.getToggleSelectedHandler();
          handler(e);

          if (isShiftSelected) selectChosenOnShift(info, e);

          return;
        }}
        testID={identifiers["modules.master.table.checkbox.listing"]}
      />
    </div>
  );
}

function PrioritySelector<TData>({
  priority,
  onSelectPriority,
}: Pick<TicketCellProps<TData>, "priority"> & { onSelectPriority: (priority: string) => void }) {
  const priorityPopover = usePopover({});

  function onSelectPriorityClick(e: React.MouseEvent<HTMLDivElement, MouseEvent>, priority: string) {
    e.stopPropagation();
    e.preventDefault();
    priorityPopover.setOpen(false);
    onSelectPriority(priority);
  }

  if (!priority) return <></>;
  return (
    <>
      <button
        ref={priorityPopover.reference}
        {...priorityPopover.getReferenceProps({
          onClick: (e) => {
            e.stopPropagation();
            e.preventDefault();
          },
        })}
        style={{
          width: "30px",
          minWidth: "30px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "transparent",
          alignSelf: "stretch",
          color: "white",
          border: "none",
          position: "relative",
        }}
      >
        <StyledTooltip
          style={{
            position: "absolute",
            height: "100%",
            zIndex: 5,
          }}
          text="Set priority"
          position="top"
          offset={-3}
        />
        {(() => {
          switch (priority) {
            case "Low":
              return <Icons.PriorityLowIcon />;
            case "Medium":
              return <Icons.PriorityMediumIcon />;
            case "High":
              return <Icons.PriorityHighIcon />;
          }
        })()}
      </button>
      {priorityPopover.open && (
        <FloatingPortal>
          <FloatingOverlay
            lockScroll
            style={{
              zIndex: vars.zIndex.modalOverlay,
            }}
          >
            <div
              ref={priorityPopover.floating}
              {...priorityPopover.getFloatingProps()}
              style={{
                position: priorityPopover.strategy,
                top: priorityPopover.y ?? 0,
                left: priorityPopover.x ?? 0,
                minHeight: "10rem",
                backgroundColor: vars.colors.grey100,
              }}
            >
              <PopoverInput $widthPx={160}>
                <p style={{ margin: "0 2rem", fontSize: "1.4rem", color: vars.colors.grey60, marginBottom: "1rem" }}>
                  Set priority
                </p>
                <DropdownItem onClick={(e) => onSelectPriorityClick(e, "High")}>
                  <div style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
                    <Icons.PriorityHighIcon />
                    <p>High</p>
                  </div>
                </DropdownItem>
                <DropdownItem onClick={(e) => onSelectPriorityClick(e, "Medium")}>
                  <div style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
                    <Icons.PriorityMediumIcon />
                    <p>Medium</p>
                  </div>
                </DropdownItem>
                <DropdownItem onClick={(e) => onSelectPriorityClick(e, "Low")}>
                  <div style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
                    <Icons.PriorityLowIcon />
                    <p>Low</p>
                  </div>
                </DropdownItem>
              </PopoverInput>
            </div>
          </FloatingOverlay>
        </FloatingPortal>
      )}
    </>
  );
}

const selectChosenOnShift = (info: CellContext<unknown, string>, e?: React.ChangeEvent<HTMLInputElement>) => {
  const rows = info.table.getRowModel().rows;

  const firstSelectedIndex = rows.findIndex((r) => r.getIsSelected());

  const lastSelectedIndex = info.row.index;

  if (firstSelectedIndex !== undefined && lastSelectedIndex !== undefined && firstSelectedIndex !== lastSelectedIndex) {
    const smallerIndex = firstSelectedIndex > lastSelectedIndex ? lastSelectedIndex : firstSelectedIndex;
    const biggerIndex = firstSelectedIndex < lastSelectedIndex ? lastSelectedIndex : firstSelectedIndex;
    const slicedRows = rows.slice(smallerIndex, biggerIndex);
    slicedRows.map((r) => {
      const handler1 = r.getToggleSelectedHandler();
      handler1(e);
    });
  }
};

export const StyledTooltip = styled(Tooltip)`
  > span:first-child {
    z-index: 5;
  }
`;

function DragPreview(rows?: Row<IssueTableRowItem>[]) {
  const preview = usePreview();
  if (!preview.display) {
    return null;
  }
  const { itemType } = preview;
  if (itemType !== DRAG_TYPE_ROW) {
    return null;
  }

  const { style } = preview;

  const secondStyle = {
    backgroundColor: vars.colors.purpleDark,
    borderRadius: "1rem",
    border: `2px solid ${vars.colors.purple}`,
    color: vars.colors.grey30,
    padding: "0.8rem 1.2rem",
    width: "37.2rem",
    zIndex: 50,
    opacity: 0.9,
    cursor: "grabbing",
  };

  return (
    <>
      <div
        className="item-list__item"
        style={{ ...style, ...secondStyle, cursor: "grabbing" }}
      >
        {rows?.length && rows.length > 1 && (
          <div
            style={{
              position: "absolute",
              top: "-1.2rem",
              borderRadius: "1rem",
              opacity: 0.9,
              width: "2.2rem",
              height: "2.2rem",
              backgroundColor: vars.colors.purpleDark,
              border: `2px solid ${vars.colors.purple}`,
              left: "-1.2rem",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              cursor: "grabbing",
            }}
          >
            {rows?.length}
          </div>
        )}
        {rows?.map((row: Row<IssueTableRowItem>, index) => {
          if (index < 5)
            return (
              <Paragraph
                color={vars.colors.grey30}
                key={(row.original as IssueTableRowItem).id}
                style={{ maxWidth: "35rem", overflow: "hidden", textOverflow: "ellipsis", wordBreak: "break-word" }}
              >
                {(row.original as IssueTableRowItem).prettyTitle}
              </Paragraph>
            );
          if (index === 5) {
            return (
              <Paragraph
                color={vars.colors.grey30}
                key={(row.original as IssueTableRowItem).id}
                style={{ maxWidth: "35rem", overflow: "hidden", textOverflow: "ellipsis", wordBreak: "break-word" }}
              >
                ...
              </Paragraph>
            );
          }
        })}
      </div>
    </>
  );
}

import styled from "styled-components";

import Paragraph from "components/Paragraph/Paragraph";

export const InputContainer = styled.div`
  padding-top: 0.8rem;
  padding-bottom: 2.4rem;
`;

export const ClientID = styled(Paragraph)`
  width: 44rem;

  text-overflow: ellipsis;
  overflow: hidden;

  user-select: none;
`;

export const ModalInput = styled.div`
  margin-bottom: 2rem;
`;

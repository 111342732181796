import React, { Fragment } from "react";

import { generatePath, matchPath, NavLink, Outlet, useLocation } from "react-router-dom";

import { ButtonNavigation } from "components/Navigation/ButtonNavigation/ButtonNavigation.styles";
import { Spinner } from "components/Spinner/Spinner";

import { useAppSelectionContext } from "context/App/AppSelectionContext";

import { Workspace } from "models";

import { homeHowToRouteConfigs, PathRouteConfig, RouteConfigType } from "router/config/routesConfig";

export const HomeHowToModule = () => {
  const { selectedWorkspace } = useAppSelectionContext();
  const location = useLocation();

  if (!selectedWorkspace) {
    return <Spinner />;
  }

  const howToLinks = homeHowToRouteConfigs
    .filter((routeConfig) => routeConfig.type === RouteConfigType.PATH)
    .map((pathRouteConfig) => {
      const config = pathRouteConfig as PathRouteConfig;
      return mapRouteConfigToNavLink(config, selectedWorkspace, !!matchPath(config.path, location.pathname));
    });

  return (
    <Fragment>
      <ButtonNavigation>{howToLinks}</ButtonNavigation>

      <Outlet />
    </Fragment>
  );
};

const mapRouteConfigToNavLink = (config: PathRouteConfig, workspace: Workspace, isActive: boolean) => {
  const to = generatePath(config.path, { workspaceSlug: workspace.slug });

  return (
    <div
      className={isActive ? "active" : ""}
      key={config.name}
    >
      <NavLink
        to={to}
        data-testid={config.testId}
      >
        {config.name}
      </NavLink>
    </div>
  );
};

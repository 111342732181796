import { useMemo } from "react";

import { useAppContext } from "context/App/App.context";

import { useUsersDeps } from "pages/Users/Users";

import {
  AllFieldNameUXOperators,
  Attribute,
  Filter,
  FilterHit,
  FilterHitDisplay,
  UseModuleAttributes,
} from "../sharedTypes";
import { useAppAttributes } from "../useAppAttributes";
import { attributeIconFor, getFieldValue, getUXOperatorForFilter, getUXOperators, partialFilterFor } from "../utils";

const availableFieldNames = ["metadata_.first_name", "metadata_.last_name", "end_user_id", "banned"];

const groupedAvailableFieldNames = [
  {
    title: "App user data",
    values: availableFieldNames,
  },
] as const;

export type UsersFieldName = typeof availableFieldNames[number];

export function useUsersAttributes(): UseModuleAttributes {
  const { wsTeamMembers } = useAppContext();
  const { usersService } = useUsersDeps();

  const { addAttribute, removeAttribute, updateAttribute, attributes } = useAppAttributes({
    storageKeyPrefix: "shakebugs.user_attributes",
  });

  const validAttributesJSONString = useMemo(
    () => JSON.stringify(attributes.filter((attr) => isValidAttribute(attr))),
    [attributes],
  );

  return {
    attributes: attributes.map((attr) => {
      return {
        name: attr.field_name,
      };
    }),
    availableAttributes: useMemo(() => getAvailableAttributes(), []),
    validAttributes: useMemo(() => JSON.parse(validAttributesJSONString), [validAttributesJSONString]),
    isFilterUXValid: (atIndex) => isFilterUXValid(atIndex, attributes),
    searchBoxInitialOpen,
    shouldOpenSearchBox,
    showsHitsResults,
    showSearch: (attributeName?: string) => {
      if (attributeName !== "banned") {
        return true;
      }
      return false;
    },
    fetchHitsFN: ({ deps, signal, attributeName, query }) => {
      const workspaceID = deps[0];
      const appID = deps[1];

      if (attributeName === "metadata_.first_name" || attributeName === "metadata_.last_name")
        return Promise.resolve([]);

      return usersService
        .getUsersFiltersHits(workspaceID, appID, query, attributeName, signal)
        .then(({ data }) => data);
    },
    add: (attributeName: string) => {
      addAttribute({
        field_name: attributeName,
        filter: undefined,
        attributeOperator: attributes.length === 0 ? undefined : "and",
      });
    },
    addExternal(attributeName: string, filterValue: string) {
      addAttribute({
        field_name: attributeName,
        filter: { ...partialFilterFor("is"), fieldValue: filterValue },
        attributeOperator: attributes.length === 0 ? undefined : "and",
      });
    },
    update: (identifier: number, value: string | undefined | null, selectedOperator: string) => {
      const current = attributes.find((_, index) => index === identifier);
      if (!current) return;

      const filter: Filter = { ...partialFilterFor(selectedOperator), fieldValue: value };

      const attr: Attribute = {
        attributeOperator: identifier == 0 ? undefined : "and",
        field_name: current.field_name,
        filter,
      };

      updateAttribute(identifier, attr);
    },
    remove: (atIndex: number) => {
      removeAttribute(atIndex);
    },
    filterSelectorInitialOpen: (identifier: number) => {
      const attribute = attributes.find((_, index) => index === identifier);
      if (!attribute) return false;

      return (
        !attribute.filter?.fieldValue &&
        attribute.filter?.fieldValue !== null &&
        isFilterUXValid(identifier, attributes) &&
        identifier === attributes.length - 1
      );
    },
    getAttributeValuePresentation: (identifier: number) => {
      const attribute = attributes.find((_, index) => index === identifier);
      if (!attribute) return "";
      return (
        (attribute.filter && getFieldValue(attribute.field_name, wsTeamMembers, attribute.filter.fieldValue)) ||
        " is missing value"
      );
    },
    getAttributeIcon: (attributeName: string) => {
      return attributeIconFor(attributeName);
    },
    getAttributeValue: (identifier: number) => {
      const attribute = attributes.find((_, index) => index === identifier);
      if (!attribute) return;
      return attribute.filter?.fieldValue;
    },
    getAvailableUXOperators: (identifier: number) => {
      const attribute = attributes.find((_, index) => index === identifier);
      if (!attribute) return [];
      return getUXOperators(getAvailableFieldNameUXOperators(attribute.field_name));
    },
    getSelectedUXOperator: (identifier: number) => {
      const attribute = attributes.find((_, index) => index === identifier);
      if (!attribute || !attribute.filter) return;
      return getUXOperatorForFilter(attribute.filter, attribute.field_name);
    },
    getPreSelectedUXOperator: (identifier: number) => {
      const attribute = attributes.find((_, index) => index === identifier);
      if (!attribute) return;
      return attribute.filter
        ? getUXOperatorForFilter(attribute.filter, attribute.field_name)
        : getUXOperators(getAvailableFieldNameUXOperators(attribute.field_name))[0];
    },
    getPredefinedValuesForAttribute: (identifier: number) => {
      const attribute = attributes.find((_, index) => index === identifier);
      if (!attribute) return [];

      return predefinedValuesForFieldName(attribute.field_name);
    },
    getAttributeOperator: (identifier: number) => {
      const attribute = attributes.find((_, index) => index === identifier);
      if (!attribute) return;
      return attribute.attributeOperator;
    },
    attributeHasDefinedFilter: (identifier: number) => {
      const attribute = attributes.find((_, index) => index === identifier);
      if (!attribute) return false;
      return !!attribute.filter;
    },
    filterHitMapper,
    isEnterKeyEnabled: (attributeName: string) => {
      if (attributeName === "end_user_id" || attributeName === "banned") return false;
      return true;
    },
  };
}

function getAvailableAttributes() {
  return groupedAvailableFieldNames.filter((field) => {
    // return current.map((e) => e.field_name).includes(name);
    return field;
  });
}

function getAvailableFieldNameUXOperators(fieldName: string) {
  const userNameOperators: Array<AllFieldNameUXOperators> = ["contains", "not_available"];
  const userIdOperator: Array<AllFieldNameUXOperators> = ["is"];

  switch (fieldName as UsersFieldName) {
    case "metadata_.first_name":
    case "metadata_.last_name":
      return userNameOperators;
    case "end_user_id":
    case "banned":
      return userIdOperator;
    default:
      return userIdOperator;
  }
}

function predefinedValuesForFieldName(fieldName: string) {
  switch (fieldName) {
    case "banned":
      return [
        { name: "Active", value: "false" },
        { name: "Blocked", value: "true" },
      ];
  }
  return [];
}

function isValidAttribute(attribute: Attribute) {
  if (!attribute.filter) return false;

  const fieldName = attribute.field_name as UsersFieldName;

  if (
    (fieldName === "metadata_.first_name" || fieldName === "metadata_.last_name") &&
    attribute.filter.fieldValue === null
  ) {
    return true;
  }

  if (!attribute.filter.fieldValue) return false;

  return attribute.filter.fieldValue.length > 0;
}

function isFilterUXValid(identifier: number, attributes: Attribute[]) {
  const attribute = attributes.find((_, index) => index === identifier);
  if (!attribute) return false;

  if (!attribute.filter) return true;
  return isValidAttribute(attribute);
}

function searchBoxInitialOpen(attributeName: string, selectedOperator: string) {
  const fieldName = attributeName as UsersFieldName;
  return (
    (fieldName !== "metadata_.first_name" && fieldName !== "metadata_.last_name") ||
    ((fieldName === "metadata_.first_name" || fieldName === "metadata_.last_name") &&
      selectedOperator !== "not_available")
  );
}

function showsHitsResults(attributeName: string) {
  if ((attributeName as UsersFieldName) === "end_user_id") return true;
  return false;
}

function shouldOpenSearchBox(selectedUXOperator: string) {
  if ((selectedUXOperator as AllFieldNameUXOperators) === "not_available") return false;
  return true;
}

export function filterHitMapper(fieldName: string, issueFilters: FilterHit[], filterValue?: string | null | undefined) {
  const displayFilters = [] as FilterHitDisplay[];

  issueFilters.map((filter) => {
    displayFilters.push({
      value: filter.value,
      name: filter.name,
      label: fieldName === "banned" ? filter.name : filter.value,
      isChecked: filter.value === filterValue,
    });
  });

  return displayFilters;
}

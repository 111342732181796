import React, { useEffect } from "react";

import Shake from "@shakebugs/browser";
import { createSyncStoragePersister } from "@tanstack/query-sync-storage-persister";
import { QueryClient } from "@tanstack/react-query";
import { PersistQueryClientProvider } from "@tanstack/react-query-persist-client";
import { hot } from "react-hot-loader";
import { IntlProvider } from "react-intl";
import { BrowserRouter } from "react-router-dom";
import { createGlobalStyle, ThemeProvider } from "styled-components";

import EnvironmentRibbon from "components/EnvironmentRibbon/EnvironmentRibbon";
import Toast from "components/Toast/Toast";

import { AuthContextProvider } from "context/Auth/Auth.context";

import environments from "environments";

import localization from "localization";

import { base, Theme, ThemeDark, typography } from "styles";

import { AuthDependencyProvider } from "./App.dependencies";
import Router from "./router/Router";

declare global {
  interface Window {
    // eslint-disable-next-line
    analytics: any;
    // eslint-disable-next-line
    gtag: any;
    // eslint-disable-next-line
    Shake: any;
  }
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 1000 * 60 * 60 * 24, // 24 hours
    },
  },
});

const localStoragePersister = createSyncStoragePersister({
  storage: window.localStorage,
});

const GlobalStyle = createGlobalStyle<{ theme: Theme }>`
    ${base}
    ${typography}
`;

const shakeSetup = () => {
  Shake.start(environments.shakeSDKApiKey, environments.shakeSDKBundleId);
  Shake.report.enableActivityHistory(true);
  Shake.report.isConsoleLogsEnabled = false;
  Shake.report.isCustomLogsEnabled = true;
  Shake.report.isScreenChangesEnabled = true;
  Shake.report.isSystemEventsEnabled = true;
  Shake.report.isNetworkRequestsEnabled = true;
  Shake.report.isUserActionsEnabled = true;
};

function App() {
  useEffect(() => {
    shakeSetup();
  }, []);

  return (
    <PersistQueryClientProvider
      client={queryClient}
      persistOptions={{ persister: localStoragePersister }}
    >
      <BrowserRouter>
        <ThemeProvider theme={ThemeDark}>
          <GlobalStyle />
          <EnvironmentRibbon />
          <Toast />
          <IntlProvider
            messages={localization["en"]}
            locale="en"
            defaultLocale="en"
          >
            <AuthDependencyProvider>
              <AuthContextProvider>
                <Router />
              </AuthContextProvider>
            </AuthDependencyProvider>
          </IntlProvider>
        </ThemeProvider>
      </BrowserRouter>
    </PersistQueryClientProvider>
  );
}

declare let module: Record<string, unknown>;

export default hot(module)(App);

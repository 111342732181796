import React, { useState } from "react";

import { ReactComponent as ClientId } from "assets/images/client-id-icon.svg";
import { ReactComponent as ClientSecret } from "assets/images/client-secret-icon.svg";

import { AdministrationCard } from "components/AdministrationCard/AdministrationCard";
import Button from "components/Button/Button";
import { GridTable } from "components/GridTable/GridTable";
import Heading from "components/Heading/Heading";
import Input from "components/Input/Input";
import PageTitle from "components/PageTitle/PageTitle";
import PageWrap from "components/PageWrap/PageWrap";

import { useForm } from "hooks/useForm";

import { Workspace } from "models";
import { isAdmin } from "models/Member.model";

import { useTeamMembersConsumer } from "pages/Administration/consumers/useTeamMembersConsumer";
import LogoComponent from "pages/shared/components/LogoComponent/LogoComponent";
import { getRandomColor } from "pages/shared/helpers/getRandomColorHelper";

import { Flex } from "styles/reusable/Flex/Flex.styles";

import identifiers from "util/identifiers.json";
import { t } from "util/translator";
import { Validators } from "util/Validators";

import DangerZoneCard from "../../components/DangerZone/DangerZoneCard";
import useGeneralViewApiConsumer from "../../consumers/useGeneralViewApiConsumer";
import GeneralCardRow from "./GeneralClientRow";
import * as Styled from "./GeneralView.styles";

interface FormState {
  workspaceName: string;
}

const validators = {
  workspaceName: (name: string) => {
    if (!Validators.minLength(name, 3)) return new Error("Type 3 characters or more.");
  },
};

export default function GeneralView(): JSX.Element {
  const {
    updateWorkspaceInfo,
    selectedWorkspace,
    deleteWorkspace,
    loading: deleteLoading,
    uploading,
    updateWorkspaceLogo,
    deleteWorkspaceLogo,
  } = useGeneralViewApiConsumer();

  const { formState, formError, handleFormChange, handleFormSubmit } = useForm<FormState>({
    initialState: {
      workspaceName: selectedWorkspace?.name || "",
    },
    validators,
    onSubmit: handleSubmit,
  });
  const [loading, setLoading] = useState(false);

  const { workspaceName } = formState;
  const { workspaceName: workspaceErr } = formError;

  async function handleSubmit() {
    setLoading(true);
    const newName = await updateWorkspaceInfo(workspaceName);
    handleFormChange("workspaceName", newName);
    setLoading(false);
  }

  const handleInput = (e: React.FormEvent<HTMLInputElement>) => {
    e.preventDefault();
    handleFormChange(e.currentTarget.name, e.currentTarget.value);
  };

  return (
    <>
      <Title />
      <PageWrap>
        <BasicsCard
          name={workspaceName}
          nameErr={workspaceErr}
          handleInput={handleInput}
          handleSubmit={handleFormSubmit}
          selectedWorkspace={selectedWorkspace}
          loading={loading}
          isAdmin={isAdmin(selectedWorkspace?.role)}
          uploading={uploading}
          updateLogo={updateWorkspaceLogo}
          deleteLogo={deleteWorkspaceLogo}
        />
        {isAdmin(selectedWorkspace?.role) && (
          <DangerZoneCard
            deleteWorkspace={deleteWorkspace}
            loading={deleteLoading}
          />
        )}
      </PageWrap>
    </>
  );
}

const Title = () => (
  <PageTitle>
    <Heading
      as="h1"
      heading1
      marginBottom={8}
      testId={identifiers["workspaceAdministration.header.general"]}
    >
      {t("administration.general.title")}
    </Heading>
    <p>{t("administration.general.subtitle")}</p>
  </PageTitle>
);

interface BasicsCardProps {
  name: string;
  handleInput: (e: React.FormEvent<HTMLInputElement>) => void;
  handleSubmit: () => void;
  selectedWorkspace?: Workspace;
  nameErr: Error | undefined;
  loading: boolean;
  isAdmin: boolean;
  uploading?: boolean;
  updateLogo: (file: File) => void;
  deleteLogo: (toggle: () => void) => void;
}

const BasicsCard = ({
  name,
  handleInput,
  handleSubmit,
  selectedWorkspace,
  nameErr,
  loading,
  isAdmin,
  uploading,
  updateLogo,
  deleteLogo,
}: BasicsCardProps) => {
  const { teamData } = useTeamMembersConsumer();
  const isButtonDisabled = name === selectedWorkspace?.name || !isAdmin;
  const tooltipText = !isAdmin ? "Only admins can change workspace name" : "Please change workspace name first";

  return (
    <AdministrationCard title={t("administration.general.card.heading")}>
      <Styled.InputContainer>
        <Flex
          $column
          $gap={2}
        >
          <LogoComponent
            title="Workspace logo"
            subtitle="Optional • Visible to workspace members only."
            image={selectedWorkspace?.logo_url ?? undefined}
            backgroundColor={getRandomColor(selectedWorkspace?.id).background}
            textColor={getRandomColor(selectedWorkspace?.id).text}
            letters={name[0]?.toLocaleUpperCase()}
            onUpdatePicture={updateLogo}
            onDeletePicture={deleteLogo}
            loading={uploading}
            canChange={isAdmin}
          />
          <Input
            type="text"
            name="workspaceName"
            label="Workspace name"
            value={name}
            error={!!nameErr}
            helper={nameErr && nameErr.message}
            onChange={handleInput}
            disabled={!isAdmin || loading}
            testId={identifiers["general.input.workspaceName"]}
          />
          <GridTable gridTemplateColumns="repeat(3, auto)">
            <GeneralCardRow
              title="Client ID"
              value={teamData?.client_id}
              toastText="Client ID copied to clipboard"
              testId={identifiers["general.icon.copyID"]}
              IconComponent={<ClientId />}
            />

            <GeneralCardRow
              title="Client Secret"
              value={teamData?.client_secret}
              toastText="Client Secret copied to clipboard"
              testId={identifiers["general.icon.copySecret"]}
              IconComponent={<ClientSecret />}
            />
          </GridTable>
        </Flex>
      </Styled.InputContainer>

      <Button
        type="submit"
        size="small"
        onClick={handleSubmit}
        disabled={isButtonDisabled || loading}
        testId={identifiers["general.button.save"]}
        tooltip={{
          position: "top",
          text: tooltipText,
          disabled: !isButtonDisabled,
        }}
        loadingText={loading && "Saving..."}
      >
        Save changes
      </Button>
    </AdministrationCard>
  );
};

import React from "react";

import PageWrap from "components/PageWrap/PageWrap";
import { Spinner } from "components/Spinner/Spinner";

import { useAppContext } from "context/App/App.context";
import { useAppSelectionContext } from "context/App/AppSelectionContext";

import { EssentialsCard } from "pages/Administration/components/AppsCards/EssentialsCard/EssentialsCard";
import { RulesCard } from "pages/Administration/components/AppsCards/RulesCard/RulesCard";
import StatusCard from "pages/Administration/components/AppsCards/StatusCard";
import NoAppsView from "pages/Administration/components/NoAppsView";
import useAppsGeneralViewConsumer from "pages/Administration/consumers/useAppsGeneralViewConsumer";

import ApiKeysCard from "../../components/ApiKeysCard/ApiKeysCard";

export default function AppsGeneralView(): JSX.Element {
  const { loading: appsLoading } = useAppContext();
  const { selectedWorkspace } = useAppSelectionContext();

  const {
    updateApp,
    activateInactivateApp,
    saving,
    allApps,
    localSelectedApp,
    updateAppLogo,
    deleteAppLogo,
    rules,
    refetchRules,
    deleteRule,
    addApiKey,
    deleteApiKey,
    loading,
  } = useAppsGeneralViewConsumer();

  if (!appsLoading && allApps.length === 0) {
    <NoAppsView selectedWorkspace={selectedWorkspace} />;
  }

  if (!localSelectedApp) {
    return <Spinner />;
  }

  const localLoading = appsLoading || loading;

  return (
    <PageWrap style={{ marginTop: "4rem" }}>
      <StatusCard
        isAppActive={localSelectedApp.active}
        handleActiveChange={() => activateInactivateApp(localSelectedApp.active)}
        loading={appsLoading}
        saving={saving}
      />
      <EssentialsCard
        key={localSelectedApp.id}
        selectedApp={localSelectedApp}
        appName={localSelectedApp.name}
        updateApp={updateApp}
        loading={appsLoading}
        saving={saving}
        updateAppLogo={updateAppLogo}
        deleteAppLogo={deleteAppLogo}
        uploading={saving}
      />
      <ApiKeysCard
        app={localSelectedApp}
        loading={appsLoading}
        addApiKey={addApiKey}
        deleteApiKey={deleteApiKey}
        selectedWorkspace={selectedWorkspace}
      />
      <RulesCard
        selectedApp={localSelectedApp}
        loading={localLoading}
        rules={rules}
        getRules={refetchRules}
        deleteRule={deleteRule}
      />
    </PageWrap>
  );
}

import React, { useEffect } from "react";

import ErrorImage from "assets/images/FTUX/not-found-graphic.png";

import EmptyStateElement from "components/EmptyStateElement/components/EmptyStateElement";
import PageWrap from "components/PageWrap/PageWrap";
import { BaseTable } from "components/ShakeTable/BaseTable";
import { Spinner } from "components/Spinner/Spinner";

import { useAppSelectionContext } from "context/App/AppSelectionContext";

import { useCrashesAttributes } from "hooks/filtering/modules/useCrashesAttributes";
import { Attributes } from "hooks/filtering/ui/Attributes";
import { AttributesContainer } from "hooks/filtering/ui/Attributes.styles";

import { isNativePlatform } from "models";

import useCrashReportsApiConsumer from "pages/Crashes/consumers/useCrashReportsApiConsumer";
import { EmptyStateViewConf, EmptyTableView } from "pages/shared/components/EmptyTableView/EmptyTableView";
import { SdkSetupSteps } from "pages/shared/components/SDKSetup/SdkSetupSteps";
import {
  CrashesModuleSampleAppFooter,
  CrashModuleFreePlanFooter,
} from "pages/shared/components/TableFooter/TableFooter";
import { getCrashNotSupportedConfig, resolveEmptyStateView } from "pages/shared/helpers/resolveEmptyStateView";

import { isFreePlan } from "util/workspaceUtil";

import { CrashesModuleEmptyState } from "../../shared/components/ModuleEmptyState/CrashesModuleEmptyState";

export default function CrashesPageView(): JSX.Element {
  const { selectedApp, selectedWorkspace } = useAppSelectionContext();

  const crashesAttributeState = useCrashesAttributes();

  const { items, loading, hasMore, total, error, crashTableProps, refetchData } = useCrashReportsApiConsumer({
    attributesProps: crashesAttributeState,
  });

  const hasAttributes = Boolean(crashesAttributeState.validAttributes.length);
  const isError = Boolean(error);

  useEffect(() => {
    if (crashesAttributeState.validAttributes) {
      crashTableProps.table.resetRowSelection();
    }
    // eslint-disable-next-line
  }, [crashesAttributeState.validAttributes]);

  // Show crashes not supported screen
  if (selectedApp && !isNativePlatform(selectedApp)) {
    const config = getCrashNotSupportedConfig(selectedApp) as EmptyStateViewConf;
    return (
      <>
        <PageWrap style={{ height: "100%", display: "flex", flexDirection: "column" }}>
          <EmptyTableView {...config} />
        </PageWrap>
      </>
    );
  }

  // Show SDK not added screen
  if (!selectedApp?.is_approved) {
    return <SdkSetupSteps />;
  }

  // Show error screen
  if (error && hasAttributes) {
    return (
      <EmptyStateElement
        title="There was an error loading crash reports. Try refreshing."
        subtitle="The link you followed may be broken, or the workspace may have been removed."
        imageSrc={ErrorImage}
      />
    );
  }

  const isInitialLoading = typeof items == "undefined" || loading;

  // Show data empty screen
  const hasItems = (items && items.length > 0) || total > 0;
  if (!isInitialLoading && !hasAttributes && !isError && !hasItems) {
    return (
      <>
        <PageWrap style={{ height: "100%", display: "flex", flexDirection: "column" }}>
          <CrashesModuleEmptyState />
        </PageWrap>
      </>
    );
  }

  const emptyViewConfig = resolveEmptyStateView(
    loading,
    "crashes",
    hasItems,
    selectedApp,
    hasAttributes,
    isError,
    refetchData,
  ) as EmptyStateViewConf | undefined;

  const buildTableFooter = () => {
    if (Boolean(selectedApp?.is_sample)) return <CrashesModuleSampleAppFooter />;
    if (isFreePlan(selectedWorkspace) && !Boolean(hasMore)) return <CrashModuleFreePlanFooter />;
    return null;
  };

  return (
    <>
      <PageWrap style={{ height: "100%", display: "flex", flexDirection: "column", overflow: "hidden" }}>
        <AttributesContainer>
          <Attributes
            {...crashesAttributeState}
            searchField="title"
          />
        </AttributesContainer>
        {emptyViewConfig && (
          <EmptyTableView
            withFilters
            {...emptyViewConfig}
          />
        )}
        {isInitialLoading && <Spinner />}
        {!isInitialLoading && !emptyViewConfig && (
          <BaseTable
            {...crashTableProps}
            footer={buildTableFooter()}
            isFetchingNext={crashTableProps.isFetchingNext}
          />
        )}
      </PageWrap>
    </>
  );
}

import { useCallback, useEffect, useRef, useState } from "react";

import { InfiniteData, useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useAppDeps } from "App.dependencies";
import { saveAs } from "file-saver";
import { generatePath, useNavigate, useParams } from "react-router-dom";

import { emptyArrayFor1Attach, resolveAttachmentUrls } from "components/DetailsPane/helper";
import { IssueTableRowItem } from "components/MasterTable/models/MasterTableModel";
import { TicketSortOption, UserSortOption } from "components/ShakeTable/ui/SortHeaderCell";
import displayToast from "components/Toast/displayToast";

import useNotificationsApiConsumer from "consumers/useNotificationsApiConsumer";

import { useAppSelectionContext } from "context/App/AppSelectionContext";

import { useFeedbackAttributes } from "hooks/filtering/modules/useFeedbackAttributes";
import { useAppBoundStorage } from "hooks/filtering/useAppBoundStorage";
import useAbortController from "hooks/useAbortController";
import { Page } from "hooks/useShakePaginaton";

import { Attachment, SupportedFileTypes, Tag } from "models";
import { CrashReport } from "models/crashReporting";
import { Integration, IntegrationType } from "models/integrations";
import { Issue } from "models/issueTracking";
import { TicketPriority } from "models/TicketPriority.model";
import { TicketStatus } from "models/TicketStatus.model";

import { RoutePaths } from "router/config/routePaths";

import { analyticsTrack, sendGtagEvent } from "util/analyticsData";
import { resolveFileType } from "util/files";

import { useUserFeedbackDeps } from "../UserFeedback";

interface Params {
  selectedWorkspaceId?: string;
  selectedAppId?: string;
  userFeedbackKey?: string;
  setRefreshActivity?: (refreshActivity: boolean) => void;
}

export default function useUserFeedbackDetailsApiConsumer<TData>({
  selectedWorkspaceId,
  selectedAppId,
  userFeedbackKey,
  setRefreshActivity,
}: Params) {
  const [error, setError] = useState<unknown>();

  const queryClient = useQueryClient();

  const { selectedWorkspace, selectedApp } = useAppSelectionContext();

  const { notificationsService } = useAppDeps();
  const { userFeedbackService } = useUserFeedbackDeps();
  const { fetchUnreadNotificationsAndDispatch, unreadNotifications } = useNotificationsApiConsumer();
  const initialUnreadRef = useRef(unreadNotifications);

  const { validAttributes } = useFeedbackAttributes(true);

  const { state: sortOption } = useAppBoundStorage<TicketSortOption | UserSortOption>("shakebugs.feedback_sort", "key");
  const sortValue = sortOption.value;
  const queryKey = `userFeedback ${selectedApp?.id}`;

  const deps = [selectedWorkspaceId, selectedAppId, validAttributes, sortValue];

  const { abortSignal } = useAbortController();
  const { workspaceSlug, appKey } = useParams<{ workspaceSlug: string; appKey: string }>();
  const navigate = useNavigate();
  const [integrationDropdownOpened, setIntegrationDropdownOpened] = useState<IntegrationType | undefined>(undefined);
  const [sendingIntegrationMap, setSendingIntegrationMap] = useState(new Map<string, boolean>());
  const [mappedAttachments, setMappedAttachments] = useState<Attachment[]>([]);

  const markNotificationsAsRead = useCallback(
    async (userFeedbackId: string) => {
      if (!selectedWorkspace || !selectedApp) return;

      const unreadNotificationList = initialUnreadRef.current[selectedApp.id]?.filter(
        (notification) => notification.issue_id === userFeedbackId,
      );

      if (!unreadNotificationList) return;

      for (const unreadNotification of unreadNotificationList) {
        await notificationsService.markNotificationAsRead(selectedWorkspace.id, unreadNotification.id);
      }

      await fetchUnreadNotificationsAndDispatch(selectedWorkspace.id);
    },
    [fetchUnreadNotificationsAndDispatch, notificationsService, selectedApp, selectedWorkspace],
  );

  async function getUserFeedbackServiceHooks() {
    if (!selectedWorkspaceId || !selectedAppId || !issue?.id) return;

    try {
      const { data } = await userFeedbackService.getServiceHooks(selectedWorkspaceId, selectedAppId, issue.id);

      return data;
    } catch (error) {
      displayToast({
        title: "Something went wrong",
        content: "Please try again.",
      });
    }
  }

  const getUserFeedbackBlackbox = async () => {
    if (!selectedWorkspaceId || !selectedAppId || !issue?.id) return;

    try {
      const { data } = await userFeedbackService.getBlackbox(selectedWorkspaceId, selectedAppId, issue.id);

      return data;
    } catch (error) {
      displayToast({
        title: "Something went wrong",
        content: "Please try again.",
      });
    }
  };

  const getSentryProjects = async () => {
    if (!selectedWorkspaceId || !selectedAppId || !issue?.id || !selectedWorkspace?.sentry) return;

    try {
      const { data } = await userFeedbackService.getSentryInfo(selectedWorkspaceId);

      return data;
    } catch (error) {
      displayToast({
        title: "Something went wrong",
        content: "Please try again.",
      });
    }
  };

  const getSentryIssues = async (projectSlug: string, environment: string) => {
    if (!selectedWorkspaceId || !selectedAppId || !issue?.id || !selectedWorkspace?.sentry) return;

    try {
      const { data } = await userFeedbackService.getSentryIssues(
        selectedWorkspaceId,
        selectedAppId,
        issue.id,
        projectSlug,
        environment,
      );

      return data;
    } catch (error) {
      displayToast({
        title: "Something went wrong",
        content: "Please try again.",
      });
    }
  };

  const getLinkedIssues = async () => {
    if (!selectedWorkspaceId || !selectedAppId || !issue?.id || issue?.parent_id) return;

    try {
      const { data } = await userFeedbackService.getLinkedIssues(selectedWorkspaceId, selectedAppId, issue.id);

      return data;
    } catch (error) {
      displayToast({
        title: "Something went wrong",
        content: "Please try again.",
      });
    }
  };

  const getUserFeedbackDetails = async () => {
    if (!selectedWorkspaceId || !selectedAppId || !userFeedbackKey) return undefined;

    setError(undefined);
    try {
      return await userFeedbackService
        .getUserFeedback(selectedWorkspaceId, selectedAppId, userFeedbackKey, abortSignal)
        .then(({ data }) => {
          markNotificationsAsRead(data.id);
          return data as Issue;
        });
    } catch (error) {
      if (abortSignal.aborted) return undefined;
      setError(error);
      return undefined;
    }
  };

  const getUserFeedbackSuggestion = async () => {
    if (!selectedWorkspaceId || !selectedAppId || !issue || !issue.suggest_duplicate || issue.parent_id) return null;

    try {
      return await userFeedbackService
        .getUserFeedbackSuggestion(selectedWorkspaceId, selectedAppId, issue.id, abortSignal)
        .then(({ data }) => {
          return data as Issue;
        });
    } catch (error) {
      if (abortSignal.aborted) return undefined;
      return undefined;
    }
  };

  const { data: issue, refetch } = useQuery([`ticket`, { selectedAppId, userFeedbackKey }], getUserFeedbackDetails, {
    refetchOnWindowFocus: false,
    cacheTime: Infinity,
  });

  const { data: issueSuggestion } = useQuery(
    [`ticket suggestion`, { selectedAppId, userFeedbackKey }],
    getUserFeedbackSuggestion,
    {
      refetchOnWindowFocus: false,
      cacheTime: Infinity,
      enabled: Boolean(issue && issue.suggest_duplicate),
    },
  );

  const getUserFeedbackDetailsNums = async () => {
    if (!selectedWorkspaceId || !selectedAppId || !issue) return undefined;

    try {
      return await userFeedbackService
        .getUserFeedbackDetailsNums(selectedWorkspaceId, selectedAppId, issue?.id, abortSignal)
        .then(({ data }) => {
          return data;
        });
    } catch (error) {
      if (abortSignal.aborted) return undefined;
      return undefined;
    }
  };

  const { data: issueDetailsNums } = useQuery(
    [`ticket details num`, { selectedAppId, userFeedbackKey }],
    getUserFeedbackDetailsNums,
    {
      refetchOnWindowFocus: false,
      cacheTime: Infinity,
      enabled: Boolean(issue),
    },
  );

  const { data: sentryProjects, refetch: refetchSentryProjects } = useQuery(
    [`sentry projects`, { selectedWorkspaceId }],
    getSentryProjects,
    {
      enabled: issue && issue.id ? true : false,
      refetchOnWindowFocus: false,
      cacheTime: Infinity,
    },
  );

  const { data: linkedIssues, refetch: refetchLinkedIssues } = useQuery(
    [`linked issues`, { selectedAppId, userFeedbackKey }],
    getLinkedIssues,
    {
      enabled: issue && issue.id ? true : false,
      refetchOnWindowFocus: false,
      cacheTime: Infinity,
    },
  );

  const { data: serviceHooks, refetch: hooksRefetch } = useQuery(
    [`hooks`, { selectedAppId, userFeedbackKey }],
    getUserFeedbackServiceHooks,
    {
      enabled: issue && issue.id ? true : false,
      refetchOnWindowFocus: false,
      cacheTime: Infinity,
    },
  );

  const { data: blackboxData } = useQuery([`blackbox`, { selectedAppId, userFeedbackKey }], getUserFeedbackBlackbox, {
    enabled: issue && issue.id ? true : false,
    refetchOnWindowFocus: false,
    cacheTime: Infinity,
  });

  const updateUserFeedback = async (issue: Partial<Issue>, isSuggestion?: boolean) => {
    if (!selectedWorkspaceId || !selectedAppId || !userFeedbackKey) return;

    return userFeedbackService
      .updateUserFeedback(selectedWorkspaceId, selectedAppId, userFeedbackKey, issue)
      .then(() => {
        if (isSuggestion) refetch();
        setRefreshActivity?.(true);
        return issue;
      });
  };

  const { mutate: mutateUserFeedbackTitle } = useMutation({
    mutationFn: updateUserFeedback,
    onSuccess: (changedIssue) => updateIssueCache("pretty_title", changedIssue?.title),
  });

  const { mutate: mutateUserFeedbackSuggestion } = useMutation({
    mutationFn: (issue: Partial<Issue>) => updateUserFeedback(issue, true),
    onSuccess: (changedIssue) => updateIssueCache("suggest_duplicate", changedIssue?.suggest_duplicate),
  });

  const deleteUserFeedback = async (id: string) => {
    if (!selectedWorkspaceId || !selectedAppId || !userFeedbackKey) return;

    if (Boolean(selectedApp?.is_sample)) {
      displayToast({ content: "Deleting sample tickets is not allowed." });
      return;
    }

    try {
      await userFeedbackService.deleteUserFeedback(selectedWorkspaceId, selectedAppId, userFeedbackKey);
      displayToast({ content: "Ticket has been successfully deleted" });
      navigate(generatePath(RoutePaths.USER_FEEDBACK, { workspaceSlug, appKey }), { replace: true });
      return id;
    } catch (error) {
      displayToast({ title: "Something went wrong", content: "Please try again" });
    }
  };

  const { mutate: mutateFeedbackDelete } = useMutation({
    mutationFn: deleteUserFeedback,
    onSuccess: (ticketId) => removeIssueFromCache(ticketId),
  });

  const createIssueTag = async (tag: Tag) => {
    if (!selectedWorkspaceId || !issue?.id) return;

    const { data } = await userFeedbackService.createIssueTag(selectedWorkspaceId, issue.id, tag.name);
    refetch();
    setRefreshActivity?.(true);
    return data;
  };

  const { mutate: createTag } = useMutation({
    mutationFn: createIssueTag,
    onSuccess: (tags) => updateIssueCache("tags", tags ?? []),
  });

  const deleteIssueTag = async (tagName: string) => {
    if (!selectedWorkspaceId || !issue?.id) return;

    const { data } = await userFeedbackService.deleteIssueTag(selectedWorkspaceId, issue.id, tagName);
    refetch();
    setRefreshActivity?.(true);
    return data;
  };

  const { mutate: deleteTag } = useMutation({
    mutationFn: deleteIssueTag,
    onSuccess: (tags) => updateIssueCache("tags", tags ?? []),
  });

  const sendIssue = async (integration: Integration) => {
    if (!selectedApp || !selectedWorkspaceId || !issue?.id) return;

    try {
      setSendingIntegrationMap((map) => {
        return new Map(map.entries()).set(integration.id, true);
      });

      await userFeedbackService.sendIssue(selectedWorkspaceId, selectedApp.id, issue.id, integration.id);

      await hooksRefetch();
      setIntegrationDropdownOpened(undefined);
    } catch (error) {
      if (error.response.status === 403) {
        displayToast({
          title: "Something went wrong",
          content: error.response.data.message,
        });
        return;
      }
      displayToast({ title: "Something went wrong", content: "Please try again." });
    } finally {
      setSendingIntegrationMap((map) => {
        const newMap = new Map(map.entries());
        newMap.delete(integration.id);
        return newMap;
      });
    }
    return;
  };

  const changeStatus = async (status: TicketStatus) => {
    if (!selectedApp || !selectedWorkspaceId || !issue?.id) return;

    try {
      await userFeedbackService.changeStatus(selectedWorkspaceId, selectedApp.id, issue.id, status);

      await refetch();
      setRefreshActivity?.(true);
      return status;
    } catch (error) {
      displayToast({ title: "Something went wrong", content: "Please try again." });
    }
    return;
  };

  const { mutate: mutateStatus } = useMutation({
    mutationFn: changeStatus,
    onSuccess: (status) => updateIssueCache("status", { status: status }),
  });

  const changePriority = async (priority: TicketPriority) => {
    if (!selectedApp || !selectedWorkspaceId || !issue?.id) return;

    try {
      await userFeedbackService.changePriority(selectedWorkspaceId, selectedApp.id, issue.id, priority);

      await refetch();
      setRefreshActivity?.(true);
      return priority;
    } catch (error) {
      displayToast({ title: "Something went", content: "Please try again." });
    }
    return;
  };

  const { mutate: mutatePriority } = useMutation({
    mutationFn: changePriority,
    onSuccess: (priority) => updateIssueCache("priority", { priority: priority }),
  });

  const changeAssignee = async (assigneeId: string | null) => {
    if (!selectedApp || !selectedWorkspaceId || !issue?.id) return;

    try {
      await userFeedbackService.changeAssignee(selectedWorkspaceId, selectedApp.id, issue.id, assigneeId);

      await refetch();
      setRefreshActivity?.(true);
      return assigneeId;
    } catch (error) {
      displayToast({ title: "Something went wrong", content: "Please try again." });
    }
    return;
  };

  const { mutate: mutateAssignee } = useMutation({
    mutationFn: changeAssignee,
    onSuccess: (assignee) => updateIssueCache("assignee", { assignee_id: assignee }),
  });

  const onMergeAction = async (items: (Issue | CrashReport)[], item: CrashReport | Issue) => {
    if (!selectedApp?.id || !selectedWorkspace?.id) return;

    const toastId = Date.now().toString();

    return userFeedbackService
      .bulkMergeInto(
        selectedWorkspace.id,
        selectedApp.id,
        items.map((item) => item.id),
        item.id,
      )
      .then(() => {
        // eslint-disable-next-line
        queryClient.setQueryData<InfiniteData<TData>>([queryKey, { ...deps, queryKey }], (oldData: any) => ({
          ...oldData,
          pages: oldData.pages.map((page: Page<IssueTableRowItem>) => {
            const pageItems = page.items.map((ticket) =>
              ticket.id === item.id
                ? {
                    ...ticket,
                    linked_issues_count: ticket.linked_issues_count + 1,
                  }
                : ticket,
            );
            return { ...page, items: pageItems };
          }),
        }));

        displayToast({
          id: toastId,
          content: "Ticket has been marked as duplicate",
          duration: 2000,
        });

        refetch();
      })
      .catch(() => {
        displayToast({
          id: toastId,
          content: "Error happened while making ticket as duplicate",
          duration: 2000,
        });
        return [];
      });
  };

  const onUnMergeAction = async (items: (Issue | CrashReport)[], itemId: string) => {
    if (!selectedApp?.id || !selectedWorkspace?.id) return;

    const toastId = Date.now().toString();

    return userFeedbackService
      .unMergeTicket(
        selectedWorkspace.id,
        selectedApp.id,
        items.map((item) => item.id),
        itemId,
      )
      .then(() => {
        // eslint-disable-next-line
        queryClient.setQueryData<InfiniteData<TData>>([queryKey, { ...deps, queryKey }], (oldData: any) => ({
          ...oldData,
          pages: oldData.pages.map((page: Page<Issue>) => {
            const pageItems = page.items.map((ticket) =>
              ticket.id === itemId
                ? {
                    ...ticket,
                    linked_issues_count: ticket.linked_issues_count > 0 ? ticket.linked_issues_count - 1 : 0,
                  }
                : ticket,
            );
            return { ...page, items: pageItems };
          }),
        }));

        displayToast({
          id: toastId,
          content: "Duplicate status has been cleared",
          duration: 2000,
        });

        refetch();
      })
      .catch(() => {
        displayToast({
          id: toastId,
          content: "Error happened while clearing duplicate status",
          duration: 2000,
        });
        return [];
      });
  };

  const downloadActivityHistory = async (ticketKey?: string) => {
    if (!selectedApp || !selectedWorkspaceId || !ticketKey) return;

    try {
      const { data } = await userFeedbackService.downloadActivityHistory(
        selectedWorkspaceId,
        selectedApp.id,
        ticketKey,
      );

      const blob = new Blob([data], { type: "text/plain;charset=utf-8" });
      saveAs(blob, `${issue?.pretty_title}.log`);

      displayToast({
        title: "Fantastic!",
        content: `Activity history has been successfully downloaded`,
      });
    } catch (error) {
      displayToast({ title: "Something went wrong", content: "Please try again." });
    }
    return;
  };

  const mapAttachment = useCallback(async (url: string) => {
    if (resolveFileType(url) === SupportedFileTypes.TXT) {
      await fetch(url, { method: "GET", mode: "cors" })
        .then((response) => response.text())
        .then((t) => {
          setMappedAttachments((mappedAttachments) => {
            return [...mappedAttachments, { url: url, text: t, num: 3 }];
          });
        })
        .catch(() => console.error("err"));
    } else {
      setMappedAttachments((mappedAttachments) => {
        return [...mappedAttachments, { url: url, num: getAttachmentOrderNum(resolveFileType(url)) }];
      });
    }
  }, []);

  const updateIssueCache = <T,>(field: string, data: T) =>
    // eslint-disable-next-line
    queryClient.setQueryData<InfiniteData<TData>>([queryKey, { ...deps, queryKey }], (oldData: any) => ({
      ...oldData,
      pages: oldData.pages.map((page: Page<Issue>) => {
        const pageItems = page.items.map((ticket) =>
          ticket.id === issue?.id
            ? {
                ...ticket,
                [field]: data,
              }
            : ticket,
        );
        return { ...page, items: pageItems };
      }),
    }));

  const removeIssueFromCache = (ticketId: string | undefined) => {
    if (!ticketId) return;
    // eslint-disable-next-line
    return queryClient.setQueryData<InfiniteData<TData>>([queryKey, { ...deps, queryKey }], (oldData: any) => ({
      ...oldData,
      pages: oldData.pages.map((page: Page<Issue>) => {
        const pageItems = page.items.filter((ticket) => ticket.id !== ticketId);
        return { ...page, items: pageItems };
      }),
    }));
  };

  useEffect(() => {
    analyticsTrack("User feedback issue visited");
    sendGtagEvent("AW-11045616142/hC8lCOKlh4kYEI70-pIp");
  }, [selectedApp]);

  useEffect(() => {
    if (issue && !mappedAttachments.length) {
      resolveAttachmentUrls(issue)?.map((url) => {
        mapAttachment(url);
      });

      if (resolveAttachmentUrls(issue).length === 1 && issue.files.length === 0) {
        // objects with only num represent empty cards
        setMappedAttachments((mappedAttachments) => {
          return [...mappedAttachments, ...emptyArrayFor1Attach("large")];
        });
      }

      if (
        !Boolean(resolveAttachmentUrls(issue).length) ||
        (resolveAttachmentUrls(issue).length === 2 && !issue.files.length)
      ) {
        setMappedAttachments((mappedAttachments) => {
          return [...mappedAttachments, ...emptyArrayFor1Attach("small")];
        });
      }
    }
  }, [issue, mapAttachment, mappedAttachments]);

  return {
    userFeedbackDetails: issue,
    updateUserFeedback: mutateUserFeedbackTitle,
    updateFeedbackSuggestion: mutateUserFeedbackSuggestion,
    deleteUserFeedback: mutateFeedbackDelete,
    createIssueTag: createTag,
    deleteIssueTag: deleteTag,
    sendIssue,
    serviceHooks,
    changeStatus: mutateStatus,
    changePriority: mutatePriority,
    changeAssignee: mutateAssignee,
    integrationDropdownOpened,
    setIntegrationDropdownOpened,
    sendingIntegrationMap,
    downloadActivityHistory,
    error,
    mappedAttachments,
    blackbox: blackboxData,
    onMergeAction,
    sentryProjects,
    getSentryIssues,
    linkedIssues,
    refetchLinkedIssues,
    onUnMergeAction,
    issueDetailsNums,
    issueSuggestion,
  };
}

const getAttachmentOrderNum = (type?: SupportedFileTypes) => {
  switch (type) {
    case SupportedFileTypes.IMAGE:
    case SupportedFileTypes.VIDEO:
      return 1;
    case SupportedFileTypes.TXT:
      return 2;
    default:
      return 3;
  }
};

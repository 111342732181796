import { useCallback, useEffect, useState } from "react";

import { InfiniteData, useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { generatePath, useNavigate } from "react-router-dom";

import { TicketSortOption, UserSortOption } from "components/ShakeTable/ui/SortHeaderCell";
import displayToast from "components/Toast/displayToast";

import { useAppSelectionContext } from "context/App/AppSelectionContext";

import { useCrashesAttributes } from "hooks/filtering/modules/useCrashesAttributes";
import { useAppBoundStorage } from "hooks/filtering/useAppBoundStorage";
import useAbortController from "hooks/useAbortController";
import { Page } from "hooks/useShakePaginaton";

import { Tag } from "models";
import { CrashActivity, CrashReport } from "models/crashReporting";
import { Issue } from "models/issueTracking";
import { TicketPriority } from "models/TicketPriority.model";
import { TicketStatus } from "models/TicketStatus.model";

import { RoutePaths } from "router/config/routePaths";

import { analyticsTrack, sendGtagEvent } from "util/analyticsData";

import { useCrashesDeps } from "../Crashes";

interface Params {
  selectedWorkspaceId?: string;
  selectedAppId?: string;
  crashReportKey?: string;
}

export default function useCrashOverviewApiConsumer<TData>({
  selectedWorkspaceId,
  selectedAppId,
  crashReportKey,
}: Params) {
  const [crashOverview, setCrashOverview] = useState<CrashReport>();
  const [crashActivity, setCrashActivity] = useState<CrashActivity[]>();
  const queryClient = useQueryClient();
  const { crashesService } = useCrashesDeps();
  const { abortSignal } = useAbortController();
  const [error, setError] = useState();
  const { selectedWorkspace, selectedApp } = useAppSelectionContext();
  const navigate = useNavigate();

  const { validAttributes } = useCrashesAttributes(true);

  const { state: sortOption } = useAppBoundStorage<TicketSortOption | UserSortOption>("shakebugs.crashes_sort", "key");
  const sortValue = sortOption.value;
  const queryKey = `crashReports ${selectedApp?.id}`;

  const deps = [selectedWorkspaceId, selectedAppId, validAttributes, sortValue];

  const getCrashOverview = useCallback(async () => {
    if (!selectedWorkspaceId || !selectedAppId || !crashReportKey) return;

    try {
      setError(undefined);
      const { data } = await crashesService.getCrashReport(
        selectedWorkspaceId,
        selectedAppId,
        crashReportKey,
        abortSignal,
      );
      setCrashOverview(data);
    } catch (error) {
      if (abortSignal.aborted) return;
      setError(error);
      displayToast({ title: "Something went wrong", content: "Please try again." });
    }
  }, [selectedWorkspaceId, selectedAppId, crashReportKey, crashesService, abortSignal]);

  const getCrashActivity = useCallback(async () => {
    if (!selectedWorkspaceId || !selectedAppId || !crashOverview) return;

    try {
      setError(undefined);
      const { data } = await crashesService.getCrashActivity(
        selectedWorkspaceId,
        selectedAppId,
        crashOverview.id,
        abortSignal,
      );
      setCrashActivity(data);
    } catch (error) {
      if (abortSignal.aborted) return;
      setError(error);
      displayToast({ title: "Something went wrong", content: "Please try again." });
    }
  }, [selectedWorkspaceId, selectedAppId, crashesService, abortSignal, crashOverview]);

  useEffect(() => {
    getCrashOverview();
    analyticsTrack("Crash overview visited");
    sendGtagEvent("AW-11045616142/hC8lCOKlh4kYEI70-pIp");
  }, [getCrashOverview]);

  useEffect(() => {
    getCrashActivity();
  }, [getCrashActivity]);

  const deleteCrashReport = async (id: string) => {
    if (!selectedWorkspaceId || !selectedAppId || !crashReportKey) return;

    if (Boolean(selectedApp?.is_sample)) {
      displayToast({ content: "Deleting sample tickets is not allowed." });
      return;
    }

    try {
      await crashesService.deleteCrashReport(selectedWorkspaceId, selectedAppId, crashReportKey);
      displayToast({ content: "Crash group has been successfully deleted" });

      navigate(
        generatePath(RoutePaths.CRASH_REPORTS, {
          workspaceSlug: selectedWorkspace?.slug,
          appKey: selectedApp?.key,
        }),
        { replace: true },
      );
      return id;
    } catch (error) {
      displayToast({ title: "Something went wrong", content: "Please try again" });
    }
  };

  const { mutate: mutateDelete } = useMutation({
    mutationFn: deleteCrashReport,
    onSuccess: (ticketId) => removeCrashFromCache(ticketId),
  });

  const createCrashReportTag = async (tag: Tag) => {
    if (!selectedWorkspaceId || !crashOverview) return;

    return crashesService.createCrashReportTag(selectedWorkspaceId, crashOverview.id, tag.name).then(({ data }) => {
      setCrashOverview({ ...crashOverview, tags: data });
      getCrashActivity();
      return data;
    });
  };

  const { mutate: createTag } = useMutation({
    mutationFn: createCrashReportTag,
    onSuccess: (tags) => updateCrashCache("tags", tags ?? []),
  });

  const deleteCrashReportTag = async (tagName: string) => {
    if (!selectedWorkspaceId || !crashOverview) return;

    return crashesService.deleteCrashReportTag(selectedWorkspaceId, crashOverview.id, tagName).then(({ data }) => {
      setCrashOverview({
        ...crashOverview,
        tags: crashOverview.tags.filter((tag) => tag.name !== tagName).sort((a, b) => a.name.localeCompare(b.name)),
      });
      getCrashActivity();
      return data;
    });
  };

  const { mutate: deleteTag } = useMutation({
    mutationFn: deleteCrashReportTag,
    onSuccess: (tags) => updateCrashCache("tags", tags ?? []),
  });

  const changeStatus = async (status: TicketStatus) => {
    if (!selectedApp || !selectedWorkspaceId || !crashOverview?.id) return;

    try {
      await crashesService.changeStatus(selectedWorkspaceId, selectedApp.id, crashOverview.id, status);

      await getCrashOverview();
      getCrashActivity();
      return status;
    } catch (error) {
      displayToast({
        title: "Something went wrong",
        content: "Error happened while trying to change a status. Please try again.",
      });
    }
    return;
  };

  const { mutate: mutateStatus } = useMutation({
    mutationFn: changeStatus,
    onSuccess: (status) => updateCrashCache("status", { status: status }),
  });

  const changePriority = async (priority: TicketPriority) => {
    if (!selectedApp || !selectedWorkspaceId || !crashOverview?.id) return;

    try {
      await crashesService.changePriority(selectedWorkspaceId, selectedApp.id, crashOverview.id, priority);

      await getCrashOverview();
      getCrashActivity();
      return priority;
    } catch (error) {
      displayToast({
        title: "Something went wrong",
        content: "Error happened while trying to change a status. Please try again.",
      });
    }
    return;
  };

  const { mutate: mutatePriority } = useMutation({
    mutationFn: changePriority,
    onSuccess: (priority) => updateCrashCache("priority", { priority: priority }),
  });

  const changeAssignee = async (assigneeId: string | null) => {
    if (!selectedApp || !selectedWorkspaceId || !crashOverview?.id) return;

    try {
      await crashesService.changeAssignee(selectedWorkspaceId, selectedApp.id, crashOverview.id, assigneeId);

      await getCrashOverview();
      getCrashActivity();
      return assigneeId;
    } catch (error) {
      displayToast({
        title: "Something went wrong",
        content: "Error happened while trying to change assignee. Please try again.",
      });
    }
    return;
  };

  const { mutate: mutateAssignee } = useMutation({
    mutationFn: changeAssignee,
    onSuccess: (assignee) => updateCrashCache("assignee", { assignee_id: assignee }),
  });

  const updateCrashCache = <T,>(field: string, data: T) =>
    // eslint-disable-next-line
    queryClient.setQueryData<InfiniteData<TData>>([queryKey, { ...deps, queryKey }], (oldData: any) => ({
      ...oldData,
      pages: oldData.pages.map((page: Page<Issue>) => {
        const pageItems = page.items.map((ticket) =>
          ticket.id === crashOverview?.id
            ? {
                ...ticket,
                [field]: data,
              }
            : ticket,
        );
        return { ...page, items: pageItems };
      }),
    }));

  const removeCrashFromCache = (crashId: string | undefined) => {
    if (!crashId) return;
    // eslint-disable-next-line
    return queryClient.setQueryData<InfiniteData<TData>>([queryKey, { ...deps, queryKey }], (oldData: any) => ({
      ...oldData,
      pages: oldData.pages.map((page: Page<CrashReport>) => {
        const pageItems = page.items.filter((ticket) => ticket.id !== crashId);
        return { ...page, items: pageItems };
      }),
    }));
  };

  const getLinkedIssues = async () => {
    if (!selectedWorkspaceId || !selectedAppId || !crashOverview?.id) return;

    try {
      const { data } = await crashesService.getLinkedIssues(selectedWorkspaceId, selectedAppId, crashOverview.id);

      return data;
    } catch (error) {
      displayToast({
        title: "Something went wrong",
        content: "Please try again.",
      });
    }
  };

  const { data: linkedIssues, refetch: refetchLinkedIssues } = useQuery(
    [`linked issues`, { selectedAppId, crashReportKey }],
    getLinkedIssues,
    {
      enabled: crashOverview && crashOverview.id ? true : false,
      refetchOnWindowFocus: false,
      cacheTime: Infinity,
    },
  );

  const onMergeAction = async (items: (Issue | CrashReport)[], item: CrashReport | Issue) => {
    if (!selectedApp?.id || !selectedWorkspace?.id) return;

    const toastId = Date.now().toString();

    return crashesService
      .bulkMergeInto(
        selectedWorkspace.id,
        selectedApp.id,
        items.map((item) => item.id),
        item.id,
      )
      .then(() => {
        // eslint-disable-next-line
        queryClient.setQueryData<InfiniteData<TData>>([queryKey, { ...deps, queryKey }], (oldData: any) => ({
          ...oldData,
          pages: oldData.pages.map((page: Page<CrashReport>) => {
            const pageItems = page.items.map((ticket) =>
              ticket.id === item.id
                ? {
                    ...ticket,
                    linked_crash_groups_count: ticket.linked_crash_groups_count + 1,
                  }
                : ticket,
            );
            return { ...page, items: pageItems };
          }),
        }));

        displayToast({
          id: toastId,
          content: "Ticket has been marked as duplicate",
          duration: 2000,
        });

        getCrashOverview();
      })
      .catch(() => {
        displayToast({
          id: toastId,
          content: "Error happened while making ticket as duplicate",
          duration: 2000,
        });
        return [];
      });
  };

  const onUnMergeAction = async (items: (Issue | CrashReport)[], itemId: string) => {
    if (!selectedApp?.id || !selectedWorkspace?.id) return;

    const toastId = Date.now().toString();

    return crashesService
      .unMergeTicket(
        selectedWorkspace.id,
        selectedApp.id,
        items.map((item) => item.id),
        itemId,
      )
      .then(() => {
        // eslint-disable-next-line
        queryClient.setQueryData<InfiniteData<TData>>([queryKey, { ...deps, queryKey }], (oldData: any) => ({
          ...oldData,
          pages: oldData.pages.map((page: Page<CrashReport>) => {
            const pageItems = page.items.map((ticket) =>
              ticket.id === itemId
                ? {
                    ...ticket,
                    linked_crash_groups_count:
                      ticket.linked_crash_groups_count > 0 ? ticket.linked_crash_groups_count - 1 : 0,
                  }
                : ticket,
            );
            return { ...page, items: pageItems };
          }),
        }));

        displayToast({
          id: toastId,
          content: "Duplicate status has been cleared",
          duration: 2000,
        });

        getCrashOverview();
        refetchLinkedIssues();
      })
      .catch(() => {
        displayToast({
          id: toastId,
          content: "Error happened while clearing duplicate status",
          duration: 2000,
        });
        return [];
      });
  };

  return {
    crashOverview,
    crashActivity,
    deleteCrashReport: mutateDelete,
    createCrashReportTag: createTag,
    deleteCrashReportTag: deleteTag,
    changeAssignee: mutateAssignee,
    changeStatus: mutateStatus,
    changePriority: mutatePriority,
    onMergeAction,
    onUnMergeAction,
    linkedIssues,
    refetchLinkedIssues,
    error,
  };
}

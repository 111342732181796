import React, { useEffect, useState } from "react";

import { generatePath, useNavigate } from "react-router-dom";

import Button from "components/Button/Button";
import Icon from "components/Icon/Icon";
import { ModalProps } from "components/Modal/Modal";
import { ModalHeaderProps } from "components/Modal/ModalHeader/ModalHeader";
import { SubNavigation } from "components/Navigation/SubNavigation/SubNavigation.styles";
import Paragraph from "components/Paragraph/Paragraph";

import { useAppSelectionContext } from "context/App/AppSelectionContext";

import { CommonComment } from "models/CommonComment.model";

import { useCommonCommentsConsumer } from "pages/Administration/consumers/useCommonCommentsConsumer";

import { RoutePaths } from "router/config/routePaths";

import { Flex } from "styles/reusable/Flex/Flex.styles";

import Input, { InputElementType } from "../../Input";
import * as Styled from "./InsertCommentModalBody.styles";

interface Props {
  Modal: React.ForwardRefExoticComponent<ModalProps & ModalHeaderProps & React.RefAttributes<unknown>>;
  internalProps?: ModalProps;
  setSelectedComment: (comment: CommonComment) => void;
}

export default function InsertCommentsModalBody({ Modal, internalProps, setSelectedComment }: Props) {
  const { selectedWorkspace } = useAppSelectionContext();
  const { commonComments } = useCommonCommentsConsumer();
  const [activeComment, setActiveComment] = useState<CommonComment>();
  const navigate = useNavigate();

  return (
    <Modal
      {...internalProps}
      heading="Insert common comment"
      leftButtonElement={
        <Button
          styling="outline"
          onClick={() =>
            navigate(
              generatePath(RoutePaths.ADMINISTRATION_COMMON_COMMENTS, {
                workspaceSlug: selectedWorkspace?.slug,
              }),
              { replace: true },
            )
          }
        >
          Manage common comments
        </Button>
      }
    >
      <Flex $gap={1.8}>
        <div style={{ width: "50%" }}>{List(setSelectedComment, commonComments, activeComment, setActiveComment)}</div>
        <div style={{ width: "50%" }}>
          <Input
            readOnly
            value={activeComment?.message}
            rows={14}
            elementType={InputElementType.TEXTAREA}
            maxHeight={300}
          />
        </div>
      </Flex>
    </Modal>
  );
}

const List = (
  setSelectedComment: (active: CommonComment) => void,
  commonComments?: CommonComment[],
  activeComment?: CommonComment,
  setActiveComment?: (active: CommonComment) => void,
) => {
  useEffect(() => {
    if (!activeComment && setActiveComment && commonComments) setActiveComment(commonComments[0]);
  }, [activeComment, commonComments, setActiveComment]);

  if (!commonComments) return <></>;

  return (
    <Styled.List>
      <SubNavigation
        $maxWidthText="234"
        $maxWidth="326"
        $rowHeight="48"
        $inModal
      >
        <ul>
          {commonComments.map((comment) => (
            <li
              key={comment.id}
              className={activeComment?.id === comment.id ? "active" : ""}
              onMouseEnter={() => setActiveComment?.(comment)}
              onClick={() => setSelectedComment(comment)}
            >
              <Paragraph>{comment.title}</Paragraph>

              <Icon
                icon="caret-right"
                size={10}
              />
            </li>
          ))}
        </ul>
      </SubNavigation>
    </Styled.List>
  );
};

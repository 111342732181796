import React, { Fragment } from "react";

import { CSSProperties } from "styled-components";

import Tooltip from "components/Tooltip/Tooltip";

import { useAppSelectionContext } from "context/App/AppSelectionContext";

import { Attachment, SupportedFileTypes } from "models";

import { ImageAttachment } from "pages/shared/components/Attachments/ImageAttachment/ImageAttachment";
import { UnsupportedAttachment } from "pages/shared/components/Attachments/UnsupportedAttachment/UnsupportedAttachment";
import VideoAttachment from "pages/shared/components/Attachments/VideoAttachment/VideoAttachment";

import { docLink } from "util/docs";
import { resolveFileType } from "util/files";
import identifiers from "util/identifiers.json";

import * as Styled from "./AttachmentsContainer.styles";

interface Props {
  attachments: Attachment[];
  style?: CSSProperties;
  width?: number;
  height?: number;
}

export default function AttachmentsContainer({ attachments = [], style, width, height }: Props) {
  const { selectedApp } = useAppSelectionContext();
  return (
    <Styled.MainContainer style={style}>
      <Styled.Grid>
        {attachments.map((attachment) => (
          <Styled.Card
            data-testid={identifiers["module.attachment"]}
            key={attachment.url}
            $type={attachment.url ? resolveFileType(attachment.url) : attachment.type}
            $isEmpty={!attachment.url}
            $isHelper={attachment.isHelper}
            style={style}
          >
            {attachment.url ? (
              renderAttachmentByFileType(attachment, style, width, height)
            ) : attachment.isHelper ? (
              <Styled.Helper>
                <Tooltip
                  text="Auto attach files"
                  position="top"
                >
                  <a
                    href={docLink(selectedApp?.platform.name).autoAttach}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Styled.Plus />
                  </a>
                </Tooltip>
              </Styled.Helper>
            ) : (
              <Fragment />
            )}
          </Styled.Card>
        ))}
      </Styled.Grid>
    </Styled.MainContainer>
  );
}

const renderAttachmentByFileType = (attachment: Attachment, style?: CSSProperties, width?: number, height?: number) => {
  const fileType = resolveFileType(attachment.url);

  switch (fileType) {
    case SupportedFileTypes.IMAGE:
      return (
        <ImageAttachment
          url={attachment.url}
          style={style}
        />
      );
    case SupportedFileTypes.VIDEO:
      return (
        <VideoAttachment
          url={attachment.url}
          style={style}
          width={width}
          height={height}
        />
      );
    default:
      return (
        <UnsupportedAttachment
          attachment={attachment}
          style={style}
        />
      );
  }
};

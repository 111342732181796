import React from "react";

import Button from "components/Button/Button";
import Input, { InputElementType } from "components/Input/Input";
import { ModalProps } from "components/Modal/Modal";
import { ModalHeaderProps } from "components/Modal/ModalHeader/ModalHeader";

import { Customer } from "models/billing";

import { useCustomizeBillingFormConsumer } from "pages/Administration/consumers/useCustomizeBillingFormConsumer";

import identifiers from "util/identifiers.json";

import * as Styled from "./ModalBody.styles";

interface Props {
  customer: Customer;
  toggle: () => void;
  Modal: React.ForwardRefExoticComponent<ModalProps & ModalHeaderProps & React.RefAttributes<unknown>>;
  internalProps?: ModalProps;
}

export default function CustomizeBillingModalBody({ customer, toggle, Modal, internalProps }: Props) {
  const { formState, formError, handleFormChange, handleFormSubmit, loading } = useCustomizeBillingFormConsumer({
    customer,
    toggle,
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>): void => {
    handleFormChange(e.currentTarget.name, e.currentTarget.value);
  };

  const isButtonDisabled =
    formState.billingEmail === customer.email &&
    (formState.extraInfo === customer.extra_info || (formState.extraInfo === "" && customer.extra_info === null));

  return (
    <Modal
      {...internalProps}
      heading="Customize billing"
      testId={identifiers["billing.modal.header.customize"]}
      buttonElement={
        <Button
          size="small"
          type="submit"
          disabled={isButtonDisabled || loading}
          tooltip={{
            position: "top",
            text: "No changes have been made",
            disabled: !isButtonDisabled,
          }}
          testId={identifiers["billing.modal.button.save.changes"]}
          loadingText={loading && "Saving..."}
          onClick={handleFormSubmit}
        >
          Save changes
        </Button>
      }
    >
      <Styled.Form
        noValidate
        onSubmit={(event) => event.preventDefault()}
      >
        <Input
          disabled={loading}
          name="billingEmail"
          label="Billing email"
          type="email"
          error={!!formError.billingEmail}
          helper={formError.billingEmail?.message}
          value={formState.billingEmail}
          onChange={handleChange}
          testId={identifiers["billing.modal.input.billing.email"]}
        />
        <Input
          disabled={loading}
          name="extraInfo"
          label="Extra information on receipt"
          placeholder={"e.g. Company name\nLLC Street address\nVAT number"}
          value={formState.extraInfo}
          onChange={handleChange}
          rows={3}
          elementType={InputElementType.TEXTAREA}
          testId={identifiers["billing.modal.input.extra.info"]}
        />
      </Styled.Form>
    </Modal>
  );
}

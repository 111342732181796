import React from "react";

import background from "assets/images/empty-state/feedback-module-empty-heading.png";

import Paragraph from "components/Paragraph/Paragraph";

import { FeedbackModuleDescriptionBox } from "pages/shared/components/ModuleDescriptionBox/ModuleDescriptionBox";

import { SetupStep } from "../SetupStep/SetupStep";
import * as Styled from "./ModuleEmptyState.styles";

export const FeedbackModuleEmptyState = () => {
  return (
    <Styled.BackgroundContainer style={{ backgroundImage: `url("${background}")` }}>
      <Styled.Container>
        <Styled.HeadingBoxWrapper>
          <FeedbackModuleDescriptionBox />
        </Styled.HeadingBoxWrapper>
        <SetupStep
          number={1}
          title={"Shake your device"}
        />
        <Styled.StepContent>
          <Paragraph
            lineHeight={26}
            marginBottom={60}
          >
            {
              "Just open the app and shake the device to report the first ticket. Of course, make sure you have an app build with Shake SDK inside. If you have configured a different invocation method, use it to call Shake."
            }
          </Paragraph>
        </Styled.StepContent>
      </Styled.Container>
    </Styled.BackgroundContainer>
  );
};

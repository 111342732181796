import React from "react";

import { useAppSelectionContext } from "context/App/AppSelectionContext";

import { RoutePaths } from "../config/routePaths";
import RedirectWithLoading from "./RedirectWithLoading";

export const AdministrationRedirect = () => {
  const { selectedWorkspace } = useAppSelectionContext();

  const dynamicValues = { workspaceSlug: selectedWorkspace?.slug };

  return (
    <RedirectWithLoading
      path={RoutePaths.ADMINISTRATION_GENERAL}
      dynamicValues={dynamicValues}
    />
  );
};

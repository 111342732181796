import React, { useCallback, useEffect } from "react";

import { Drawer } from "components/Drawer/Drawer";
import PageWrap from "components/PageWrap/PageWrap";
import { BaseTable } from "components/ShakeTable/BaseTable";
import { Spinner } from "components/Spinner/Spinner";

import { useAppSelectionContext } from "context/App/AppSelectionContext";

import { useLogsAttributes } from "hooks/filtering/modules/useLogsAttributes";
import { Attributes } from "hooks/filtering/ui/Attributes";
import { AttributesContainer } from "hooks/filtering/ui/Attributes.styles";

import { EmptyStateViewConf, EmptyTableView } from "pages/shared/components/EmptyTableView/EmptyTableView";
import { useActivityHistoryApiConsumer } from "pages/shared/consumers/useActivityHistoryApiConsumer";
import { resolveEmptyStateView } from "pages/shared/helpers/resolveEmptyStateView";

import { LogDetails } from "./LogDetails/LogDetails";

interface Props {
  ticketKey: string;
  type: "crash" | "user_feedback";
  hasLogs?: boolean;
}

export const ActivityHistoryTable = ({ ticketKey, type, hasLogs }: Props) => {
  const { selectedWorkspace, selectedApp } = useAppSelectionContext();

  const logAttributeState = useLogsAttributes();

  const { getNextLog, getPreviousLog, items, loading, activityTableProps, detailLog, setDetailLog, error } =
    useActivityHistoryApiConsumer({
      type: type,
      teamId: selectedWorkspace?.id,
      appId: selectedApp?.id,
      ticketKey: ticketKey,
      hasLogs,
      attributesProps: logAttributeState,
    });

  const handleNavigateToNext = useCallback(() => {
    if (!detailLog) {
      return;
    }
    const nextDetailLog = getNextLog(detailLog.id);
    nextDetailLog && setDetailLog(nextDetailLog);
  }, [getNextLog, detailLog]);

  const handleNavigateToPrevious = useCallback(() => {
    if (!detailLog) {
      return;
    }
    const previousDetailLog = getPreviousLog(detailLog.id);
    previousDetailLog && setDetailLog(previousDetailLog);
  }, [getPreviousLog, setDetailLog, detailLog]);

  const handleLogClose = useCallback(() => {
    setDetailLog(undefined);
    activityTableProps.table.resetRowSelection();
  }, [setDetailLog]);

  useEffect(() => {
    if (detailLog) {
      activityTableProps.table.resetRowSelection();
      const row = activityTableProps.table.getRowModel().rows.find((a) => a.original.id === detailLog?.id);
      row?.toggleSelected(true);
    }
    // eslint-disable-next-line
  }, [detailLog]);

  useEffect(() => {
    handleLogClose();
  }, [logAttributeState.attributes.length, handleLogClose]);

  const hasItems = Boolean(items && items.length > 0);
  const emptyViewConfig = resolveEmptyStateView(
    loading,
    "logs",
    hasItems,
    selectedApp,
    Boolean(logAttributeState.validAttributes.length),
    error,
  ) as EmptyStateViewConf | undefined;

  const isInitialLoading = typeof items == "undefined" && loading;

  return (
    <>
      <PageWrap style={{ height: "100%", display: "flex", flexDirection: "column", overflow: "hidden" }}>
        <AttributesContainer>
          <Attributes
            {...logAttributeState}
            searchField="message"
          />
        </AttributesContainer>

        {emptyViewConfig && (
          <EmptyTableView
            withFilters
            {...emptyViewConfig}
          />
        )}
        {isInitialLoading && <Spinner />}
        {!emptyViewConfig && !isInitialLoading && (
          <BaseTable
            {...activityTableProps}
            validAttributes={logAttributeState.validAttributes}
          />
        )}
      </PageWrap>
      <Drawer open={!!detailLog}>
        {detailLog && (
          <LogDetails
            log={detailLog}
            navigateToNext={handleNavigateToNext}
            navigateToPrevious={handleNavigateToPrevious}
            closeDrawer={handleLogClose}
          />
        )}
      </Drawer>
    </>
  );
};

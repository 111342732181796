import React, { useCallback, useState } from "react";

import { InfiniteData, useMutation, useQueryClient } from "@tanstack/react-query";

import { CrashReportTableRowItem, RowType } from "components/MasterTable/models/MasterTableModel";
import { useCrashesTable } from "components/ShakeTable/modules/useCrashesTable";
import { TicketSortOption, UserSortOption } from "components/ShakeTable/ui/SortHeaderCell";
import displayToast, { ToastContent, updateToast } from "components/Toast/displayToast";

import { useAppContext } from "context/App/App.context";
import { useAppSelectionContext } from "context/App/AppSelectionContext";

import { Attribute, UseModuleAttributes } from "hooks/filtering/sharedTypes";
import { useAppBoundStorage } from "hooks/filtering/useAppBoundStorage";
import { prepareFiltersForRequest } from "hooks/filtering/utils";
import { Page, useShakePagination } from "hooks/useShakePaginaton";

import { CrashReport } from "models/crashReporting";
import { Member } from "models/Member.model";
import { TicketPriority } from "models/TicketPriority.model";
import { TicketStatus } from "models/TicketStatus.model";

import { useCrashesDeps } from "../Crashes";
import { useCrashGroupAddedSocketConsumer } from "./useCrashGroupAddedSocketConsumer";

// Used to store in memory ticket status to avoid re-fetching large data.
// Sockets with individual ticket updates not yet available
type MemoryStatus = Record<string, string>;
type MemoryPriority = Record<string, string>;
type MemoryAssignee = Record<string, string>;

const autoClose = 2000;

export default function useCrashReportsApiConsumer<TData>({
  attributesProps,
}: {
  attributesProps: UseModuleAttributes;
}) {
  const { crashesService } = useCrashesDeps();
  const queryClient = useQueryClient();
  const { socket } = useAppContext();
  const { selectedWorkspace, selectedApp } = useAppSelectionContext();

  const { wsTeamMembers } = useAppContext();

  const { state: sortOption, setState: setSortOption } = useAppBoundStorage<TicketSortOption | UserSortOption>(
    "shakebugs.crashes_sort",
    "key",
  );

  const [memoryStatus, setMemoryStatus] = useState<MemoryStatus>({});
  const [memoryPriority, setMemoryPriority] = useState<MemoryPriority>({});
  const [memoryAssignee, setMemoryAssignee] = useState<MemoryAssignee>({});

  /// Pagination logic
  const { refetchData, ...pagination } = useShakePagination<CrashReport, CrashReportTableRowItem>({
    deps: [selectedWorkspace?.id, selectedApp?.id, attributesProps.validAttributes, sortOption.value],
    fetchFn: ({ deps, signal, pagination }) => {
      const workspaceID = deps[0] as string;
      const appID = deps[1] as string;
      const validAttributes = deps[2] as Array<Required<Attribute>>;
      const sort = deps[3] as TicketSortOption;

      if (Boolean(validAttributes.length)) {
        const filters = prepareFiltersForRequest(validAttributes);
        return crashesService
          .getCrashReportsByFilters(workspaceID, appID, pagination.offset, pagination.limit, filters, sort, signal)
          .then(({ data }) => {
            return { total: data.total, items: data.items };
          });
      } else {
        return crashesService
          .getCrashReports(workspaceID, appID, pagination.limit, pagination.offset, sort, signal)
          .then(({ data }) => {
            return { total: data.total, items: data.items };
          });
      }
    },
    enabled: (deps) => deps.every((dep) => Boolean(dep)),
    select: {
      mapperDeps: [memoryStatus, memoryPriority, memoryAssignee],
      mapper: (items, _, mapperDeps) => {
        const memoryStatus = mapperDeps[0] as MemoryStatus;
        const memoryPriority = mapperDeps[1] as MemoryPriority;
        const memoryAssignee = mapperDeps[2] as MemoryAssignee;

        const mappedItems = [] as CrashReportTableRowItem[];
        items.map((crashReport) => {
          if (crashReport) {
            mappedItems.push(
              mapCrashReportToTableRowItem(crashReport, memoryStatus, memoryPriority, memoryAssignee, wsTeamMembers),
            );
          }
        });
        return mappedItems;
      },
    },
    queryKey: `crashReports ${selectedApp?.id}`,
  });

  useCrashGroupAddedSocketConsumer({ socket, refetchData });

  const onDeleteRequest = async (items: Array<CrashReportTableRowItem>) => {
    if (!selectedApp?.id || !selectedWorkspace?.id) return;
    return crashesService
      .bulkDeleteIssues(
        selectedWorkspace.id,
        selectedApp.id,
        items.map((item) => item.id),
      )
      .then(() => {
        displayToast({ content: getDeletedToastMessage(items.length), duration: 3000 });
        refetchData();
        return items.map((item) => item.id);
      })
      .catch(() => {
        displayToast({ title: "Error", content: "Something went wrong. Please try again.", duration: 3000 });
        return [];
      });
  };

  const deps = [selectedWorkspace?.id, selectedApp?.id, attributesProps.validAttributes, sortOption.value];
  const queryKey = `crashReports ${selectedApp?.id}`;

  const { mutate: mutateBulkDelete } = useMutation({
    mutationFn: onDeleteRequest,
    onSuccess: (items) => {
      if (!items) return;

      //eslint-disable-next-line
      return queryClient.setQueryData<InfiniteData<TData>>([queryKey, { ...deps, queryKey }], (oldData: any) => ({
        ...oldData,
        pages: oldData.pages.map((page: Page<CrashReport>) => {
          const pageItems = page.items.filter((ticket) => !items.find((item) => item === ticket.id));
          return { ...page, items: pageItems };
        }),
      }));
    },
  });

  const onChangeStatus = useCallback(
    async (status: string, ticketID: string) => {
      if (!selectedApp || !selectedWorkspace || !status || !ticketID) return;
      try {
        const { data } = await crashesService.changeStatus(
          selectedWorkspace.id,
          selectedApp.id,
          ticketID,
          status as TicketStatus,
        ); // TODO fix typin
        setMemoryStatus((status) => {
          return {
            ...status,
            [ticketID]: data.status,
          };
        });
      } catch (error) {
        // Display Error only in table flows
        displayToast({ title: "Something went wrong", content: "Please try again." });
      }
      return;
    },
    [selectedApp, selectedWorkspace, crashesService],
  );

  const onChangePriority = async (priority: string, ticketID: string) => {
    if (!selectedApp || !selectedWorkspace || !priority || !ticketID) return;
    try {
      const { data } = await crashesService.changePriority(
        selectedWorkspace.id,
        selectedApp.id,
        ticketID,
        priority as TicketPriority,
      ); // type
      setMemoryPriority((priority) => {
        return {
          ...priority,
          [ticketID]: data.priority,
        };
      });
    } catch (error) {
      displayToast({ title: "Something went", content: "Please try again." });
    }
    return;
  };

  const onChangeAssignee = useCallback(
    async (assignee: string | null, ticketID: string) => {
      if (!selectedApp || !selectedWorkspace || !ticketID) return;
      try {
        const { data } = await crashesService.changeAssignee(selectedWorkspace.id, selectedApp.id, ticketID, assignee); // TODO fix typin
        setMemoryAssignee((assignee) => {
          return {
            ...assignee,
            [ticketID]: data.assignee_id,
          };
        });
      } catch (error) {
        // Display Error only in table flows
        displayToast({ title: "Something went wrong", content: "Please try again." });
      }
      return;
    },
    [selectedApp, selectedWorkspace, crashesService],
  );

  const onBulkChangePriority = async (items: Array<CrashReportTableRowItem>, priority: TicketPriority) => {
    if (!selectedApp?.id || !selectedWorkspace?.id) return;
    const toastId = Date.now().toString();

    displayToast({
      id: toastId,
      title: "Action in progress",
      content: "Don't close this tab until the action is done. This may take a few moments.",
      duration: autoClose,
    });

    return crashesService
      .bulkChangePriority(
        selectedWorkspace.id,
        selectedApp.id,
        items.map((item) => item.id),
        priority,
      )
      .then(() => {
        // eslint-disable-next-line
        queryClient.setQueryData<InfiniteData<TData>>([queryKey, { ...deps, queryKey }], (oldData: any) => ({
          ...oldData,
          pages: oldData.pages.map((page: Page<CrashReport>) => {
            const pageItems = page.items.map((ticket) =>
              items.find((item) => item.id === ticket.id)
                ? {
                    ...ticket,
                    priority: { priority: priority },
                  }
                : ticket,
            );
            return { ...page, items: pageItems };
          }),
        }));

        updateToast({
          id: toastId,
          options: {
            autoClose: autoClose,
            progress: 0,
            render: (
              <ToastContent content={`Priority has been successfully changed to ${priority} on selected tickets`} />
            ),
          },
        });

        refetchData();
      })
      .catch(() => {
        updateToast({
          id: toastId,
          options: {
            progress: 0,
            autoClose: autoClose,
            render: <ToastContent content="Error happened while changing priority on selected tickets" />,
          },
        });
        return [];
      });
  };

  const onBulkChangeStatus = async (items: Array<CrashReportTableRowItem>, status: TicketStatus) => {
    if (!selectedApp?.id || !selectedWorkspace?.id) return;

    const toastId = Date.now().toString();

    displayToast({
      id: toastId,
      title: "Action in progress",
      content: "Don't close this tab until the action is done. This may take a few moments.",
      duration: autoClose,
    });

    return crashesService
      .bulkChangeStatus(
        selectedWorkspace.id,
        selectedApp.id,
        items.map((item) => item.id),
        status,
      )
      .then(() => {
        // eslint-disable-next-line
        queryClient.setQueryData<InfiniteData<TData>>([queryKey, { ...deps, queryKey }], (oldData: any) => ({
          ...oldData,
          pages: oldData.pages.map((page: Page<CrashReport>) => {
            const pageItems = page.items.map((ticket) =>
              items.find((item) => item.id === ticket.id)
                ? {
                    ...ticket,
                    status: { status: status },
                  }
                : ticket,
            );
            return { ...page, items: pageItems };
          }),
        }));

        updateToast({
          id: toastId,
          options: {
            autoClose: autoClose,
            progress: 0,
            render: <ToastContent content={`Status has been successfully changed to ${status} on selected tickets`} />,
          },
        });

        refetchData();
      })
      .catch(() => {
        updateToast({
          id: toastId,
          options: {
            progress: 0,
            autoClose: autoClose,
            render: <ToastContent content="Error happened while changing status on selected tickets" />,
          },
        });
        return [];
      });
  };

  const onBulkChangeAssignee = async (items: Array<CrashReportTableRowItem>, assigneeId: string | null) => {
    if (!selectedApp?.id || !selectedWorkspace?.id) return;

    const toastId = Date.now().toString();

    displayToast({
      id: toastId,
      title: "Action in progress",
      content: "Don't close this tab until the action is done. This may take a few moments.",
      duration: autoClose,
    });

    return crashesService
      .bulkChangeAssignee(
        selectedWorkspace.id,
        selectedApp.id,
        items.map((item) => item.id),
        assigneeId,
      )
      .then(() => {
        // eslint-disable-next-line
        queryClient.setQueryData<InfiniteData<TData>>([queryKey, { ...deps, queryKey }], (oldData: any) => ({
          ...oldData,
          pages: oldData.pages.map((page: Page<CrashReport>) => {
            const pageItems = page.items.map<CrashReport>((ticket) =>
              items.find((item) => item.id === ticket.id)
                ? { ...ticket, assignee: { assignee_id: assigneeId } }
                : ticket,
            );
            return { ...page, items: pageItems };
          }),
        }));

        updateToast({
          id: toastId,
          options: {
            autoClose: autoClose,
            progress: 0,
            render: <ToastContent content={`Assignee has been successfully changed on selected tickets`} />,
          },
        });

        refetchData();
      })
      .catch(() => {
        updateToast({
          id: toastId,
          options: {
            progress: 0,
            autoClose: autoClose,
            render: <ToastContent content="Error happened while changing assignee on selected tickets" />,
          },
        });
        return [];
      });
  };

  const onBulkAddTags = async (items: Array<CrashReportTableRowItem>, tags: string[]) => {
    if (!selectedApp?.id || !selectedWorkspace?.id) return;

    const toastId = Date.now().toString();

    displayToast({
      id: toastId,
      title: "Action in progress",
      content: "Don't close this tab until the action is done. This may take a few moments.",
      duration: autoClose,
    });

    return crashesService
      .bulkAddTags(
        selectedWorkspace.id,
        selectedApp.id,
        items.map((item) => item.id),
        tags,
      )
      .then(() => {
        // eslint-disable-next-line
        queryClient.setQueryData<InfiniteData<TData>>([queryKey, { ...deps, queryKey }], (oldData: any) => ({
          ...oldData,
          pages: oldData.pages.map((page: Page<CrashReport>) => {
            const pageItems = page.items.map((ticket) =>
              items.find((item) => item.id === ticket.id)
                ? {
                    ...ticket,
                    tags: ticket.tags
                      .concat(
                        tags.map((tag) => {
                          return {
                            name: tag,
                          };
                        }),
                      )
                      .filter((value, index, self) => index === self.findIndex((t) => t.name === value.name)),
                  }
                : ticket,
            );
            return { ...page, items: pageItems };
          }),
        }));
        updateToast({
          id: toastId,
          options: {
            autoClose: autoClose,
            progress: 0,
            render: <ToastContent content={"Tags have been successfully added to the selected tickets"} />,
          },
        });

        refetchData();
      })
      .catch(() => {
        updateToast({
          id: toastId,
          options: {
            progress: 0,
            autoClose: autoClose,
            render: <ToastContent content={"Error happened while adding tags to the selected tickets"} />,
          },
        });
        return [];
      });
  };

  /// Table props
  const crashTableProps = useCrashesTable({
    data: pagination.mappedData || [],
    hasMore: pagination.hasMore,
    isLoading: pagination.isLoading,
    isFetchingNext: pagination.isFetchingNextPage,
    loadMore: pagination.loadNext,
    onDeleteRequest: mutateBulkDelete,
    onBulkChangePriority: onBulkChangePriority,
    onBulkChangeAssignee: onBulkChangeAssignee,
    onBulkChangeStatus: onBulkChangeStatus,
    onBulkAddTags: onBulkAddTags,
    onChangeSortOption: (newOption) => {
      if (newOption == sortOption.value) {
        refetchData();
        return;
      }
      setSortOption(newOption);
    },
    selectedSortOption: sortOption.value,
    onChangePriorityRequest: onChangePriority,
    onChangeStatusRequest: onChangeStatus,
    onChangeAssigneeRequest: onChangeAssignee,
    total: pagination.total,
  });

  return {
    items: pagination.mappedData,
    loading: pagination.isLoading,
    error: pagination.error,
    hasMore: pagination.hasMore,
    total: pagination.total,
    refetchData,
    crashTableProps,
  };
}

export const mapCrashReportToTableRowItem = (
  crashReport: CrashReport,
  memoryStatus?: MemoryStatus,
  memoryPriority?: MemoryPriority,
  memoryAssignee?: MemoryAssignee,
  members?: Member[],
): CrashReportTableRowItem => {
  const firstAndSecondColumn = crashReport.title?.split(":");
  return {
    id: crashReport.id,
    key: crashReport.key,
    name: firstAndSecondColumn?.[0],
    line: firstAndSecondColumn?.[1],
    method: crashReport.subtitle,
    tags: crashReport.tags?.length ? crashReport.tags?.sort((a, b) => a.name.localeCompare(b.name)) : [],
    highestVersion: 2.45,
    events: crashReport.crash_events_count,
    row_type: RowType.CRASH_REPORT,
    status: (memoryStatus?.[crashReport.id] ?? crashReport.status?.status) as Exclude<
      TicketStatus,
      TicketStatus.LOCKED
    >,
    priority: (memoryPriority?.[crashReport.id] ?? crashReport.priority?.priority) as TicketPriority,
    assignee: {
      name:
        memoryAssignee?.[crashReport.id] !== null
          ? getAssignee(
              memoryAssignee?.[crashReport.id] ? memoryAssignee?.[crashReport.id] : crashReport.assignee?.assignee_id,
              members,
            )?.user.name ?? ""
          : "",
      id:
        memoryAssignee?.[crashReport.id] !== null
          ? getAssignee(
              memoryAssignee?.[crashReport.id] ? memoryAssignee?.[crashReport.id] : crashReport.assignee?.assignee_id,
              members,
            )?.user.id ?? null
          : null,
      picture:
        memoryAssignee?.[crashReport.id] !== null
          ? getAssignee(
              memoryAssignee?.[crashReport.id] ? memoryAssignee[crashReport.id] : crashReport.assignee?.assignee_id,
              members,
            )?.user.picture ?? null
          : null,
    },
    linked_crash_groups_count: crashReport.linked_crash_groups_count,
  };
};

const getDeletedToastMessage = (issuesLength: number): string => {
  if (issuesLength === 1) {
    return `${issuesLength} crash report has been successfully deleted.`;
  }
  return `${issuesLength} crash reports have been successfully deleted.`;
};

const getAssignee = (assigneeId: string | null, members?: Member[]) => {
  if (!members) return;
  return members.find((member) => member.id === assigneeId);
};

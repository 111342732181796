import { useCallback } from "react";

import Shake from "@shakebugs/browser";
import { generatePath, useLocation, useNavigate } from "react-router-dom";

import { useAppSelectionContext } from "context/App/AppSelectionContext";

import { App, Workspace } from "models";

import { RoutePaths } from "router/config/routePaths";

import { getRouteFromPath } from "util/moduleRouteHelper";
import { removeAttributesFromLocalStorage } from "util/removeAttributesFromLocalStorage";

export default function useWorkspaceAndAppChange() {
  const location = useLocation();
  const navigate = useNavigate();
  const { selectedWorkspace } = useAppSelectionContext();

  const handleWorkspaceChange = useCallback(
    async (workspace: Workspace) => {
      removeAttributesFromLocalStorage();
      localStorage.setItem("lastWorkspaceVisited", workspace.id);
      Shake.setMetadata("workspace_id", workspace.id);
      Shake.setMetadata("workspace_slug", workspace.slug);
      Shake.setMetadata("workspace_name", workspace.name);
      navigate(generatePath(RoutePaths.FEATURES_LATEST, { workspaceSlug: workspace.slug }));
    },
    [navigate],
  );

  const handleAppChange = useCallback(
    (app: App) => {
      if (!selectedWorkspace) return;
      // removeAttributesFromLocalStorage();
      Shake.setMetadata("app_id", app.id);
      Shake.setMetadata("app_key", app.key);
      Shake.setMetadata("app_name", app.name);
      Shake.setMetadata("app_platform", `${app.platform.os} ${app.platform.name}`);
      const moduleAppRoute = getRouteFromPath(location.pathname);
      navigate(generatePath(moduleAppRoute, { workspaceSlug: selectedWorkspace.slug, appKey: app.key }));
    },
    [navigate, selectedWorkspace, location.pathname],
  );

  return { handleWorkspaceChange, handleAppChange };
}

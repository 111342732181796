import React from "react";

import ErrorImage from "assets/images/FTUX/not-found-graphic.png";

import EmptyStateElement from "components/EmptyStateElement/components/EmptyStateElement";
import PageWrap from "components/PageWrap/PageWrap";
import { Spinner } from "components/Spinner/Spinner";

import { useAppSelectionContext } from "context/App/AppSelectionContext";

import { useWindowTriggerWidth } from "hooks/useWindowMinWidth";

import { PlatformName } from "models";
import { isNativePlatform } from "models";

import { SdkSetupSteps } from "pages/shared/components/SDKSetup/SdkSetupSteps";

import { AnalyticsModuleEmptyState } from "../../shared/components/ModuleEmptyState/AnalyticsModuleEmptyState";
import AnalyticsCard from "../components/AnalyticsCard/AnalyticsCard";
import BarChart from "../components/Charts/BarChart";
import LineChart from "../components/Charts/LineChart";
import Filters from "../components/Filters/Filters";
import useAnalyticsConsumer from "../consumers/useAnalyticsConsumer";
import { groupPretty, valuesSum } from "../helpers/analyticsHelper";
import * as Styled from "./Analytics.styles";

export default function AnalyticsPageView() {
  const { selectedApp } = useAppSelectionContext();

  const isSmaller = useWindowTriggerWidth({
    triggerWidthPx: 1116,
  });

  const isWeb = selectedApp?.platform.name === PlatformName.WEB;

  const {
    isAnalyticsEmpty,
    isEmptyLoading,
    crashData,
    crashesLoading,
    feedbackData,
    feedbackLoading,
    usersData,
    usersLoading,
    error,
    usersFilters,
    setUsersFilters,
    crashesFilters,
    setCrashesFilters,
    feedbackFilters,
    setFeedbackFilters,
    tags,
    feedbackAppVersions,
    crashesAppVersions,
    infoLoading,
  } = useAnalyticsConsumer({ isNative: isNativePlatform(selectedApp) });

  if (!selectedApp?.is_approved) {
    return <SdkSetupSteps />;
  }

  if (error) {
    return (
      <EmptyStateElement
        title="There was an error loading stats. Try refreshing."
        subtitle="The link you followed may be broken, or the workspace may have been removed."
        imageSrc={ErrorImage}
      />
    );
  }

  if (isEmptyLoading || infoLoading) {
    return (
      <>
        <PageWrap style={{ height: "100%", display: "flex", flexDirection: "column" }}>
          <Spinner />
        </PageWrap>
      </>
    );
  }

  if (isAnalyticsEmpty) {
    return (
      <>
        <PageWrap style={{ height: "100%", display: "flex", flexDirection: "column" }}>
          <AnalyticsModuleEmptyState />
        </PageWrap>
      </>
    );
  }

  return (
    <>
      <PageWrap style={{ height: "100%", display: "flex", flexDirection: "column" }}>
        <Styled.CenteredContainer>
          <Styled.AnalyticsSection>
            <Styled.Cards $isSmaller={isSmaller}>
              <AnalyticsCard
                title="User feedback"
                helperText={
                  feedbackData && feedbackData.length
                    ? `The ${groupPretty(feedbackFilters.group).toLocaleLowerCase()} average was ${valuesSum(
                        feedbackData,
                      ).toFixed(1)} received tickets.`
                    : undefined
                }
              >
                <Filters
                  setFilters={setFeedbackFilters}
                  filters={feedbackFilters}
                  appVersions={feedbackAppVersions}
                  tags={tags}
                />
                <BarChart
                  data={feedbackData ?? []}
                  loading={feedbackLoading}
                  group={feedbackFilters.group}
                  time={feedbackFilters.time}
                  type="feedbacks"
                />
              </AnalyticsCard>

              {isNativePlatform(selectedApp) &&
                !(
                  <AnalyticsCard
                    title="Crash events"
                    helperText={
                      crashData && crashData.length
                        ? `The ${groupPretty(crashesFilters.group).toLocaleLowerCase()} average was ${valuesSum(
                            crashData,
                          ).toFixed(1)} crash events.`
                        : undefined
                    }
                  >
                    <Filters
                      setFilters={setCrashesFilters}
                      filters={crashesFilters}
                      appVersions={crashesAppVersions}
                    />
                    <BarChart
                      data={crashData ?? []}
                      loading={crashesLoading}
                      group={crashesFilters.group}
                      type="crashes"
                      time={crashesFilters.time}
                    />
                  </AnalyticsCard>
                )}

              {!isWeb && (
                <AnalyticsCard title="App users">
                  <Filters
                    setFilters={setUsersFilters}
                    filters={usersFilters}
                  />
                  <LineChart
                    data={usersData ?? []}
                    loading={usersLoading}
                    time={usersFilters.time}
                  />
                </AnalyticsCard>
              )}
            </Styled.Cards>
          </Styled.AnalyticsSection>
        </Styled.CenteredContainer>
      </PageWrap>
    </>
  );
}

import { useCallback, useEffect, useState } from "react";

import { useQuery } from "@tanstack/react-query";
import { saveAs } from "file-saver";
import { generatePath, useNavigate } from "react-router-dom";

import { emptyArrayFor1Attach, resolveAttachmentUrls } from "components/DetailsPane/helper";
import displayToast from "components/Toast/displayToast";

import { useAppSelectionContext } from "context/App/AppSelectionContext";

import useAbortController from "hooks/useAbortController";

import { Attachment, SupportedFileTypes } from "models";
import { CrashEvent } from "models/crashReporting";

import { RoutePaths } from "router/config/routePaths";

import { analyticsTrack, sendGtagEvent } from "util/analyticsData";
import { resolveFileType } from "util/files";

import { useCrashesDeps } from "../Crashes";

interface CrashEventApiConsumerParams {
  teamId?: string;
  appId?: string;
  crashReportKey?: string;
  crashReportEventId?: string;
}

export default function useCrashEventApiConsumer({
  teamId,
  appId,
  crashReportKey,
  crashReportEventId,
}: CrashEventApiConsumerParams) {
  const [crashEvent, setCrashEvent] = useState<CrashEvent>();
  const { crashesService } = useCrashesDeps();
  const { abortSignal } = useAbortController();
  const { selectedWorkspace, selectedApp } = useAppSelectionContext();
  const [attachments, setAttachments] = useState<Attachment[]>([]);
  const navigate = useNavigate();

  const getCrashEvent = useCallback(async () => {
    if (!teamId || !appId || !crashReportEventId) return;

    try {
      setCrashEvent(undefined);
      const { data } = await crashesService.getCrashReportEvent(teamId, appId, crashReportEventId, abortSignal);
      setAttachments([]);
      setCrashEvent(data);
    } catch (error) {
      navigate(
        generatePath(RoutePaths.CRASH_REPORT_OVERVIEW, {
          workspaceSlug: selectedWorkspace?.slug,
          appKey: selectedApp?.key,
          crashReportKey,
        }),
        { replace: true },
      );
      if (abortSignal.aborted) return;
      displayToast({ title: "Something went wrong", content: "Please try again." });
    }
  }, [
    teamId,
    appId,
    crashReportEventId,
    crashesService,
    abortSignal,
    navigate,
    selectedWorkspace?.slug,
    selectedApp?.key,
    crashReportKey,
  ]);

  const downloadActivityHistory = async () => {
    if (!selectedApp || !selectedWorkspace?.id || !crashEvent) return;

    try {
      const { data } = await crashesService.downloadActivityHistory(
        selectedWorkspace.id,
        selectedApp.id,
        crashEvent.id,
      );

      const blob = new Blob([data], { type: "text/plain;charset=utf-8" });
      saveAs(blob, `${crashEvent?.pretty_title}.log`);

      displayToast({
        title: "Fantastic!",
        content: `Activity history has been successfully downloaded`,
      });
    } catch (error) {
      displayToast({ title: "Something went wrong", content: "Please try again." });
    }
    return;
  };

  const mapAttachment = useCallback(async (url: string) => {
    if (resolveFileType(url) === SupportedFileTypes.TXT) {
      await fetch(url, { method: "GET", mode: "cors" })
        .then((response) => response.text())
        .then((t) => {
          setAttachments((mappedAttachments) => {
            return [...mappedAttachments, { url: url, text: t, num: 3 }];
          });
        })
        .catch(() => console.error("err"));
    } else {
      setAttachments((mappedAttachments) => {
        return [...mappedAttachments, { url: url, num: getAttachmentOrderNum(resolveFileType(url)) }];
      });
    }
  }, []);

  useEffect(() => {
    if (crashEvent && !attachments.length) {
      resolveAttachmentUrls(crashEvent)?.map((url) => {
        mapAttachment(url);
      });

      if (resolveAttachmentUrls(crashEvent).length === 1 && crashEvent.files.length === 0) {
        // nums are just for sorting => empty attachments cards are always last
        setAttachments((mappedAttachments) => {
          return [...mappedAttachments, ...emptyArrayFor1Attach("large")];
        });
      }

      if (
        !Boolean(resolveAttachmentUrls(crashEvent).length) ||
        (resolveAttachmentUrls(crashEvent).length === 2 && !crashEvent.files.length)
      ) {
        setAttachments((mappedAttachments) => {
          return [...mappedAttachments, ...emptyArrayFor1Attach("small")];
        });
      }
    }
  }, [crashEvent, mapAttachment, attachments]);

  const getCrashEventBlackbox = async () => {
    if (!selectedWorkspace?.id || !selectedApp?.id || !crashEvent?.id) return;

    try {
      const { data } = await crashesService.getBlackbox(selectedWorkspace.id, selectedApp.id, crashEvent.id);

      return data;
    } catch (error) {
      displayToast({
        title: "Something went wrong",
        content: "Please try again.",
      });
    }
  };

  const { data: blackboxData } = useQuery(
    [`blackbox ${selectedApp?.id} ${crashReportEventId}`],
    getCrashEventBlackbox,
    {
      enabled: crashEvent && crashEvent.id ? true : false,
      refetchOnWindowFocus: false,
      cacheTime: Infinity,
    },
  );

  useEffect(() => {
    getCrashEvent();
    analyticsTrack("Crash event visited");
    sendGtagEvent("AW-11045616142/hC8lCOKlh4kYEI70-pIp");
  }, [getCrashEvent]);

  const updateCrashEvent = async (crashEvent: Partial<CrashEvent>) => {
    if (!teamId || !appId || !crashReportEventId) return;

    return crashesService.updateCrashEvent(teamId, appId, crashReportEventId, crashEvent).then(({ data }) => {
      setCrashEvent(data);
    });
  };

  return { crashEvent, updateCrashEvent, downloadActivityHistory, attachments, blackbox: blackboxData };
}

const getAttachmentOrderNum = (type?: SupportedFileTypes) => {
  switch (type) {
    case SupportedFileTypes.IMAGE:
    case SupportedFileTypes.VIDEO:
      return 1;
    case SupportedFileTypes.TXT:
      return 2;
    default:
      return 3;
  }
};

import React, { useEffect, useRef, useState } from "react";

import useOnClickOutside from "hooks/useOnClickOutside";

import { MoreIcon } from "pages/shared/icons/icons";

import Icon from "../Icon/Icon";
import * as Styled from "./Dropdown.styles";

interface DropdownProps {
  className?: string;
  label?: string;
  labelComponent?: JSX.Element;
  position?: "left" | "right";
  wide?: boolean;
  button?: boolean;
  buttonElement?: JSX.Element;
  isOpened?: boolean;
  setIsOpened?: (isOpened: boolean) => void;
  testId?: string;
  icon?: string;
  iconComponent?: JSX.Element;
  disabled?: boolean;
  horizontalOffset?: number;
  verticalPos?: "top" | "bottom";
  num?: number;
  numTooltipText?: string;
  fullWidth?: boolean;
  numTooltipPos?: "top-right" | "top-left"; //this is a hack, should me modified later
}

interface DropdownItemProps {
  className?: string;
  active?: boolean;
  withSubmenu?: boolean;
  // eslint-disable-next-line
  onClick?: (e: any) => void;
  wide?: boolean;
  testId?: string;
  disabled?: boolean;
  error?: boolean;
  sameIconColor?: boolean;
}

interface DropdownSubMenuProps {
  position?: "left" | "right";
  wide?: boolean;
}

// todo this needs to be refactored a bit if we have the time
const Dropdown: React.FC<DropdownProps> = ({
  children,
  className,
  label,
  labelComponent,
  position,
  wide,
  button,
  buttonElement,
  isOpened,
  setIsOpened,
  testId,
  icon,
  disabled,
  iconComponent,
  horizontalOffset,
  verticalPos,
  num,
  numTooltipText,
  fullWidth,
  numTooltipPos,
}) => {
  const [isActive, setActive] = useState(isOpened ?? false);
  const [verticalPosition, setVerticalPosition] = useState<"bottom" | "top" | undefined>(verticalPos);

  const toggleIsActive = () => {
    const position = getVerticalPosition(isActive, dropdownMenuRef.current);
    setActive(!isActive);

    if (setIsOpened) setIsOpened(!isActive);
    if (!verticalPos) setVerticalPosition(position);
  };

  const dropdownRef = useRef<HTMLDivElement>(null);
  const dropdownMenuRef = useRef<HTMLDivElement>(null);

  const handleClick = (e: Event) => {
    if (null !== dropdownRef.current) {
      if (dropdownRef.current.contains(e.target as Node)) {
        return;
      }
    }
    setActive(false);
    if (setIsOpened) setIsOpened(false);
  };

  useOnClickOutside(dropdownRef, handleClick);

  useEffect(() => {
    if (isOpened === undefined) return;

    setActive(isOpened);
  }, [isOpened]);

  return (
    <>
      <Styled.Dropdown
        ref={dropdownRef}
        className={`dropdown ${className}`}
        $disabled={disabled}
      >
        <Styled.DropdownButton
          onClick={!disabled ? toggleIsActive : undefined}
          className="dropdown-button"
          data-testid={testId}
        >
          {icon && <Styled.Image src={icon} />}
          {iconComponent && iconComponent}
          {label && <span style={{ maxWidth: num && !fullWidth ? "11rem" : "100%" }}>{label}</span>}
          {labelComponent && labelComponent}
          {buttonElement ? (
            buttonElement
          ) : button ? (
            <span className="wrap">
              <Icon
                icon="dots2"
                size={14}
              />
            </span>
          ) : (
            <MoreIcon />
          )}
          {num ? (
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Styled.NumTooltip
                text={numTooltipText ?? ""}
                position={numTooltipPos}
                prewrap
              >
                <Styled.NumContainer>{num}</Styled.NumContainer>
              </Styled.NumTooltip>
            </div>
          ) : undefined}
        </Styled.DropdownButton>
        <Styled.DropdownMenu
          className="dropdown-button"
          ref={dropdownMenuRef}
          $active={isActive && !!verticalPosition}
          scroll={scroll}
          $position={position}
          $positionVertical={verticalPosition}
          $wide={wide}
          $button={button}
          $horizontalOffset={horizontalOffset}
        >
          {children}
        </Styled.DropdownMenu>
      </Styled.Dropdown>
      <Styled.Overlay
        active={isActive}
        onClick={toggleIsActive}
      />
    </>
  );
};

export const DropdownItem: React.FC<DropdownItemProps> = ({
  children,
  className,
  active,
  withSubmenu,
  onClick,
  wide,
  testId,
  disabled,
  error,
  sameIconColor,
}) => {
  return (
    <Styled.DropdownItem
      className={`dropdown-item ${className}`}
      $active={active}
      $withSubmenu={withSubmenu}
      $wide={wide}
      onClick={!disabled ? onClick : undefined}
      data-testid={testId}
      $disabled={disabled}
      $error={error}
      $sameIconColor={sameIconColor}
    >
      {children}
      {withSubmenu && (
        <div className="icon-submenu">
          <Icon
            icon="caret-right"
            size={10}
          />
        </div>
      )}
    </Styled.DropdownItem>
  );
};

export const DropdownSubMenu: React.FC<DropdownSubMenuProps> = ({ children, wide, position }) => {
  return (
    <Styled.DropdownSubMenu
      $wide={wide}
      $position={position}
      className="dropdown-submenu"
    >
      {children}
    </Styled.DropdownSubMenu>
  );
};

const isElementInViewport = (element: HTMLDivElement) => {
  if (!element) return;

  const windowHeight = document.getElementById("root")?.clientHeight ?? window.innerHeight;
  const instOffsetWithMenu = element.offsetTop + element.getBoundingClientRect().bottom + element.clientHeight;

  return !(instOffsetWithMenu >= windowHeight);
};

function getVerticalPosition(isActive: boolean, ref: HTMLDivElement | null) {
  if (isActive) {
    return undefined;
    return;
  }
  return !isElementInViewport(ref as HTMLDivElement) ? "top" : "bottom";
}

export default Dropdown;

import React from "react";

import { CellContext, ColumnDef, createColumnHelper } from "@tanstack/react-table";
import { generatePath } from "react-router-dom";

import { ReactComponent as DragIcon } from "assets/images/drag.svg";

import { getTagColor } from "components/Input/InputWithTags/InputWithTags";
import { IssueTableRowItem } from "components/MasterTable/models/MasterTableModel";
import TagComponent from "components/Tag/Tag";

import { useAppSelectionContext } from "context/App/AppSelectionContext";

import { TicketPriority } from "models/TicketPriority.model";
import { TicketStatus } from "models/TicketStatus.model";

import { RoutePaths } from "router/config/routePaths";

import { BaseCell } from "../ui/BaseCell";
import { Grab, HeaderCell } from "../ui/BaseTableStyles";
import { SelectionHeaderCell } from "../ui/SelectionHeaderCell";
import { SortHeaderCell, TicketSortOption, UserSortOption } from "../ui/SortHeaderCell";
import { SpaceToPreviewIndicator } from "../ui/SpaceToPreviewIndicator";
import { TicketTitleCell } from "../ui/TicketTitleCell";
import { useBaseTable } from "../useBaseTable";

type TableProps = {
  selectable?: boolean;
  data: IssueTableRowItem[];
  hasMore: boolean;
  isLoading: boolean;
  isFetchingNext: boolean;
  total: number;
  loadMore: () => void;
  onDeleteRequest: (rows: IssueTableRowItem[]) => void;
  onMarkAsReadRequest: (rows: IssueTableRowItem[]) => void;
  onBulkChangePriority: (rows: IssueTableRowItem[], priority: TicketPriority, resetSelection?: () => void) => void;
  onBulkChangeStatus: (rows: IssueTableRowItem[], status: TicketStatus, resetSelection?: () => void) => void;
  onBulkChangeAssignee: (rows: IssueTableRowItem[], assigneeId: string | null, resetSelection?: () => void) => void;
  onBulkAddNoteSendChat: (rows: IssueTableRowItem[], message: string, isNote?: boolean) => void;
  onBulkAddTags: (rows: IssueTableRowItem[], tags: string[]) => void;
  onPreviewRequest: (row: IssueTableRowItem) => void;
  onPreviewClose: () => void;
  onChangeStatusRequest: (status: string, ticketID: string) => Promise<void>;
  onChangePriorityRequest: (priority: string, ticketID: string) => Promise<void>;
  onChangeSortOption: (sort: TicketSortOption | UserSortOption) => void;
  selectedSortOption: TicketSortOption | UserSortOption;
  onChangeAssigneeRequest: (assignee_id: string | null, ticketID: string) => Promise<void>;
  onMergeAction: (rows: IssueTableRowItem[], row: IssueTableRowItem) => void;
};

export function useFeedbackTable({
  selectable,
  data,
  hasMore,
  isLoading,
  isFetchingNext,
  loadMore,
  onDeleteRequest,
  onMarkAsReadRequest,
  onBulkChangePriority,
  onBulkChangeAssignee,
  onBulkChangeStatus,
  onBulkAddNoteSendChat,
  onBulkAddTags,
  onPreviewRequest,
  onPreviewClose,
  onChangeStatusRequest,
  onChangePriorityRequest,
  onChangeSortOption,
  selectedSortOption,
  onChangeAssigneeRequest,
  total,
  onMergeAction,
}: TableProps) {
  const { selectedApp, selectedWorkspace } = useAppSelectionContext();

  const baseTableProps = useBaseTable({
    data,
    columns: useDefaultFeedbackColumns({
      onDeleteRequest,
      onMarkAsReadRequest,
      onBulkChangePriority,
      onBulkChangeStatus,
      onBulkChangeAssignee,
      onBulkAddNoteSendChat,
      onBulkAddTags,
      onChangeStatusRequest,
      onChangePriorityRequest,
      onChangeSortOption,
      selectedSortOption,
      onChangeAssigneeRequest,
      total,
      onMergeAction,
    }) as unknown as ColumnDef<IssueTableRowItem>[],
    selectable,
    hasMore,
    isLoading,
    isFetchingNext,
    loadMore,
    renderRowLinkPath: (item) => {
      return generatePath(RoutePaths.USER_FEEDBACK_DETAILS, {
        workspaceSlug: selectedWorkspace?.slug,
        appKey: selectedApp?.key,
        userFeedbackKey: item.key?.toString(),
      });
    },
    onPreviewRequest,
    onPreviewClose,
    onDeleteRequest,
    onMarkAsReadRequest,
    total,
    selectedSortOption,
  });

  return { ...baseTableProps };
}

const columnHelper = createColumnHelper<IssueTableRowItem>();

const sortOptions = [
  { value: "key" as TicketSortOption, label: "Last created" },
  { value: "status" as TicketSortOption, label: "Status" },
  { value: "priority" as TicketSortOption, label: "Priority" },
];

export function useDefaultFeedbackColumns({
  total,
  onDeleteRequest,
  onMarkAsReadRequest,
  onBulkChangePriority,
  onBulkChangeStatus,
  onBulkChangeAssignee,
  onBulkAddNoteSendChat,
  onBulkAddTags,
  onChangeStatusRequest,
  onChangePriorityRequest,
  onChangeSortOption,
  selectedSortOption,
  onChangeAssigneeRequest,
  onMergeAction,
}: Pick<
  TableProps,
  | "total"
  | "onDeleteRequest"
  | "onMarkAsReadRequest"
  | "onBulkChangePriority"
  | "onBulkChangeStatus"
  | "onBulkChangeAssignee"
  | "onBulkAddNoteSendChat"
  | "onBulkAddTags"
  | "onChangeStatusRequest"
  | "onChangePriorityRequest"
  | "onChangeSortOption"
  | "selectedSortOption"
  | "onChangeAssigneeRequest"
  | "onMergeAction"
>) {
  return [
    columnHelper.accessor("prettyTitle", {
      cell: (info) => (
        <TicketTitleCell
          title={info.row.original.prettyTitle}
          status={info.row.original.status}
          priority={info.row.original.priority}
          linkedTicketsCount={info.row.original.linked_issues_count}
          showsIndicator={info.row.original.showNotificationIndicator}
          info={info as CellContext<unknown, string>}
          onChangeStatusRequest={(status) => onChangeStatusRequest(status, info.row.original.id)}
          onChangePriorityRequest={(priority) => onChangePriorityRequest(priority, info.row.original.id)}
          table={info.table}
          onMergeAction={onMergeAction}
          onChangeAssigneeRequest={onChangeAssigneeRequest}
        />
      ),
      header: (info) => (
        <SelectionHeaderCell
          total={total}
          table={info.table}
          onDeleteRequest={onDeleteRequest}
          onMarkAsReadRequest={onMarkAsReadRequest}
          onBulkChangeStatus={onBulkChangeStatus}
          onBulkChangePriority={onBulkChangePriority}
          onBulkChangeAssignee={onBulkChangeAssignee}
          onBulkAddNoteSendChat={onBulkAddNoteSendChat}
          onBulkAddTags={onBulkAddTags}
          onMergeAction={onMergeAction}
        />
      ),
      size: 4, // size in fractions of available space - grid fr
    }),
    columnHelper.accessor("tags", {
      cell: (info) => (
        <BaseCell
          fadeGradientReach={100}
          style={{ display: "flex", alignItems: "center", overflow: "hidden", position: "relative" }}
        >
          {info.row.original.tags?.map((tag) => {
            return (
              <TagComponent
                key={tag.id}
                value={tag.name}
                color={getTagColor(tag.name)}
                border
              />
            );
          })}
        </BaseCell>
      ),
      header: () => <HeaderCell>Tags</HeaderCell>,
      size: 2,
    }),
    columnHelper.accessor("original", {
      cell: (info) => (
        <BaseCell
          fadeGradientReach={100}
          style={{ display: "flex", alignItems: "center", overflow: "hidden", position: "relative" }}
        >
          {info.getValue().app_version}
        </BaseCell>
      ),
      header: () => <HeaderCell>App Version</HeaderCell>,
      size: 0.7,
    }),
    columnHelper.accessor("issue_reported_time", {
      cell: (info) => (
        <BaseCell>
          <SpaceToPreviewIndicator value={info.getValue()} />
        </BaseCell>
      ),
      header: () => <HeaderCell>Created</HeaderCell>,
      size: 0.8,
    }),
    columnHelper.accessor("assignee", {
      cell: () => (
        <BaseCell
          style={{
            cursor: "grab",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            maxHeight: "4.8rem",
            pointerEvents: "auto",
          }}
        >
          <Grab>
            <DragIcon style={{ cursor: "grab" }} />
          </Grab>
        </BaseCell>
      ),
      header: () => (
        <SortHeaderCell
          onSortRequest={onChangeSortOption}
          selectedSortOption={selectedSortOption}
          options={sortOptions}
          type="feedback"
        />
      ),
      size: 0.5,
    }),
  ];
}

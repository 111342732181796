import HelpCenter from "assets/images/useful-links/help-center-icon@2x.png";
import LiveChat from "assets/images/useful-links/private-live-chat-icon@2x.png";
import Roadmap from "assets/images/useful-links/roadmap-icon@2x.png";
import Docs from "assets/images/useful-links/sdk-docs-icon@2x.png";
import Slack from "assets/images/useful-links/slack-community-icon@2x.png";
import StatusPage from "assets/images/useful-links/status-page-icon@2x.png";

export interface UsefulLink {
  image: string;
  title: string;
  subtitle: string;
  link: string;
}

export const usefulLinks: UsefulLink[] = [
  {
    image: Docs,
    title: "SDK docs",
    subtitle: "Installation and all customizations in one place",
    link: "https://docs.shakebugs.com/docs/",
  },
  {
    image: HelpCenter,
    title: "Help center",
    subtitle: "Answers to 90 most commonly asked questions",
    link: "https://help.shakebugs.com/en/",
  },
  {
    image: Slack,
    title: "Slack community",
    subtitle: "Come say hi, suggest a feature or just see what’s shaking",
    link: "https://join.slack.com/t/shakebugs/shared_invite/zt-1q0p1dd8h-OrkYobRWZw1N_hJSvklD_w",
  },
  {
    image: Roadmap,
    title: "Roadmap",
    subtitle: "Vote because we prioritize based on users’ wishes",
    link: "https://feedback.shakebugs.com/",
  },
  {
    image: StatusPage,
    title: "Status page",
    subtitle: "All services are online. Or are they?",
    link: "https://status.shakebugs.com/",
  },
  {
    image: LiveChat,
    title: "Private live chat",
    subtitle: "Our support engineers are here for you",
    link: "javascript:Intercom('showNewMessage')",
  },
];

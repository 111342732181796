import React, { createContext, ReactNode, useContext, useEffect, useState } from "react";

import Shake from "@shakebugs/browser";
import { generatePath, useNavigate } from "react-router-dom";

import displayToast from "components/Toast/displayToast";

import useWorkspaceAppsApiConsumer from "consumers/useWorkspaceAppsApiConsumer";

import { App, Workspace } from "models";

import { useWorkspaceAppParams } from "pages/IntegrationActivation/helpers/useWorkspaceAppParams";

import { RoutePaths } from "../../router/config/routePaths";
import { isModuleRoute } from "../../util/moduleRouteHelper";
import { useAppContext } from "./App.context";

type AppSelectionContext = {
  selectedApp: App | undefined;
  selectedWorkspace: Workspace | undefined;
};

const initial: AppSelectionContext = {
  selectedApp: undefined,
  selectedWorkspace: undefined,
};

const appSelectionContext = createContext<AppSelectionContext>(initial);

/// Clicking "back" in the browser doesn't cause a full reload.
/// This var makes sure that selected app is not picked until apps are loaded, but React lifecycle dispatch is "too late".
let fetchingAppsOnWSChangeSync = false;

/*
Always keeps workspace and app "global selection" in sync with the current URL.
*/
export const AppSelectionContextProvider = ({ children }: { children: ReactNode }) => {
  const navigate = useNavigate();
  const { loading: appsLoading, apps, workspaces, updatingSelectedWorkspace } = useAppContext();
  const [selection, setSelection] = useState<AppSelectionContext>(initial);
  const { workspaceSlug, appKey } = useWorkspaceAppParams();
  const { fetchAllApps } = useWorkspaceAppsApiConsumer();

  useEffect(() => {
    fetchingAppsOnWSChangeSync = true;
    if (!workspaces.length || updatingSelectedWorkspace) return;
    setSelection((selection) => {
      Shake.setMetadata("workspace_id", selection.selectedWorkspace?.id ?? "");
      Shake.setMetadata("workspace_slug", selection.selectedWorkspace?.slug ?? "");
      Shake.setMetadata("workspace_name", selection.selectedWorkspace?.name ?? "");
      return {
        ...selection,
        selectedWorkspace: findWsBySlug(workspaceSlug, workspaces),
      };
    });
  }, [workspaces, workspaceSlug, updatingSelectedWorkspace]);

  useEffect(() => {
    if (!selection.selectedWorkspace) return;
    const abortController = new AbortController();
    fetchAllApps(selection.selectedWorkspace.id, abortController.signal)
      .then((apps) => {
        // We want re-direct user to add app screen if there are no apps, consider only module screens
        // Requests are aborted when workspace is changed, we don't want to redirect in that case
        if (isModuleRoute(location.pathname) && (!apps || apps.length <= 0) && !abortController.signal.aborted) {
          navigate(generatePath(RoutePaths.ADD_APP, { workspaceSlug }));
        }
      })
      .finally(() => {
        Shake.setMetadata("workspace_id", selection.selectedWorkspace?.id ?? "");
        Shake.setMetadata("workspace_slug", selection.selectedWorkspace?.slug ?? "");
        Shake.setMetadata("workspace_name", selection.selectedWorkspace?.name ?? "");
        fetchingAppsOnWSChangeSync = false;
      });
    return () => {
      abortController.abort();
    };
  }, [selection.selectedWorkspace, fetchAllApps]);

  useEffect(() => {
    if (fetchingAppsOnWSChangeSync) return;
    if (appsLoading || !selection?.selectedWorkspace || updatingSelectedWorkspace) return;

    setSelection((selection) => {
      Shake.setMetadata("workspace_id", selection.selectedWorkspace?.id ?? "");
      Shake.setMetadata("workspace_slug", selection.selectedWorkspace?.slug ?? "");
      Shake.setMetadata("workspace_name", selection.selectedWorkspace?.name ?? "");
      return {
        ...selection,
        selectedApp: findAppByKey(appKey, apps, selection?.selectedWorkspace?.slug ?? ""),
      };
    });
    if (appKey) localStorage.setItem("localAppKey", appKey ?? "");
  }, [appKey, apps, appsLoading, navigate, selection?.selectedWorkspace, updatingSelectedWorkspace]);

  return <appSelectionContext.Provider value={selection}>{children}</appSelectionContext.Provider>;
};

export const useAppSelectionContext = () => {
  return useContext(appSelectionContext);
};

function findAppByKey(appKey: string | undefined, availableApps: App[], wsSlug: string) {
  if (!appKey || location.pathname.includes("administration")) return;

  const pickedApp = availableApps.filter((a) => a.active).find((a) => a.key === appKey);

  if (!pickedApp) {
    displayToast({
      title: "Yikes, that couldn't be accessed",
      content: `Things often get archived and deleted, or maybe an app doesn't belong to ${wsSlug} workspace.`,
    });
  }

  return pickedApp;
}

function findWsBySlug(slug: string | undefined, availableWorkspaces: Workspace[]) {
  if (!slug) return;

  const pickedWorkspace = availableWorkspaces.find((w) => w.slug === slug);

  if (slug !== "home" && slug !== "administration" && slug !== "account" && !pickedWorkspace) {
    displayToast({
      title: "Yikes, that couldn't be accessed",
      content: `Things often get archived and deleted, or maybe you're not a member of ${slug} workspace. If it's the latter, ask someone for an invite.`,
      duration: 5000,
    });
  }

  return pickedWorkspace;
}

import React, { Fragment } from "react";

import { generatePath, useNavigate, useParams } from "react-router-dom";

import ErrorImage from "assets/images/FTUX/not-found-graphic.png";

import EmptyStateElement from "components/EmptyStateElement/components/EmptyStateElement";
import { Spinner } from "components/Spinner/Spinner";
import Tooltip from "components/Tooltip/Tooltip";

import { useAppSelectionContext } from "context/App/AppSelectionContext";

import { useCustomElementInjection } from "hooks/useCustomElementInjection";

import NavigationCustomElement from "layouts/components/PageNavigation/NavigationCustomElement/NavigationCustomElement";
import { ELEMENT_CONTAINER_ID } from "layouts/components/PageNavigation/PageNavigation";
import { NAV_LIST } from "layouts/components/PageNavigation/PageNavigation";

import { getActivityHistoryHelperText } from "pages/shared/components/ActivityHistoryTable/ActivityHistoryHelpers";
import { ActivityHistoryTable } from "pages/shared/components/ActivityHistoryTable/ActivityHistoryTable";

import { RoutePaths } from "router/config/routePaths";

import { Flex } from "styles/reusable/Flex/Flex.styles";

import useUserFeedbackDetailsApiConsumer from "../consumers/useUserFeedbackDetailsApiConsumer";
import * as Styled from "./UserFeedback.styles";

export default function UserFeedbackActivityHistoryPageView() {
  const { selectedWorkspace, selectedApp } = useAppSelectionContext();

  const navigate = useNavigate();
  const params = useParams<{
    workspaceSlug: string;
    appKey: string;
    userFeedbackKey: string;
  }>();

  const consumer = useUserFeedbackDetailsApiConsumer({
    selectedWorkspaceId: selectedWorkspace?.id,
    selectedAppId: selectedApp?.id,
    userFeedbackKey: params.userFeedbackKey,
  });

  const CustomElement = consumer.userFeedbackDetails ? (
    <Fragment>
      <NavigationCustomElement>
        <Styled.NavigationTitle
          $isButton
          onClick={() =>
            navigate(
              generatePath(RoutePaths.USER_FEEDBACK_DETAILS, {
                workspaceSlug: selectedWorkspace?.slug,
                appKey: selectedApp?.key,
                userFeedbackKey: params.userFeedbackKey,
              }),
            )
          }
        >
          <div>
            <p>{consumer.userFeedbackDetails?.title ?? "No description"}</p>
          </div>
        </Styled.NavigationTitle>
        <Styled.NavigationTitle $isButton={false}>
          <Flex>
            <p>Activity history</p>
            <div>
              <Tooltip
                text={!consumer.userFeedbackDetails.log ? "No logs to download" : "Download as .log file"}
                position="bottom"
              >
                <Styled.DownloadIcon
                  disabled={!consumer.userFeedbackDetails.log}
                  onClick={() => {
                    if (consumer.userFeedbackDetails?.log)
                      consumer.downloadActivityHistory(consumer.userFeedbackDetails?.key.toString());
                  }}
                  $disabled={!consumer.userFeedbackDetails.log}
                />
              </Tooltip>
            </div>
          </Flex>
        </Styled.NavigationTitle>

        {getActivityHistoryHelperText(selectedApp?.platform)}
      </NavigationCustomElement>
    </Fragment>
  ) : (
    <Fragment />
  );

  useCustomElementInjection({
    parentElementId: NAV_LIST,
    element: CustomElement,
    containerId: ELEMENT_CONTAINER_ID,
    tagName: "li",
  });

  if (consumer.error) {
    return (
      <EmptyStateElement
        title="There was an error loading user feedback. Try refreshing."
        subtitle="The link you followed may be broken, or the app may have been removed."
        imageSrc={ErrorImage}
      />
    );
  }

  if (!params.userFeedbackKey) return <Fragment />;

  if (!consumer.userFeedbackDetails) {
    return <Spinner />;
  }

  return (
    <ActivityHistoryTable
      ticketKey={params.userFeedbackKey}
      hasLogs={Boolean(consumer.userFeedbackDetails?.log)}
      type="user_feedback"
    />
  );
}

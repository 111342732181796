export const androidUsersSetupSteps = {
  registerUserCode: [
    {
      title: "Kotlin",
      file: "App.kt",
      language: "kotlin",
      code: `fun onUserLoggedIn(user: User) {
  Shake.registerUser(user.id)
}`,
      highlightedLines: [1],
    },
    {
      title: "Java",
      file: "App.java",
      language: "java",
      code: `void onUserLoggedIn(User user) {
  Shake.registerUser(user.getId());
}`,
      highlightedLines: [1],
    },
  ],
};

export const iosUsersSetupSteps = {
  registerUserCode: [
    {
      title: "Swift",
      file: "AppDelegate.swift",
      language: "swift",
      code: `func onUserLoggedIn(userId: String) {
  Shake.registerUser(userId)
}`,
      highlightedLines: [1],
    },
    {
      title: "Objective-C",
      file: "AppDelegate.m",
      language: "objectivec",
      code: `- (void)onUserLoggedIn:(NSString*)userId {
  [SHKShake registerUserWithUserId:userId];
}`,
      highlightedLines: [1],
    },
  ],
};

export const flutterUsersSetupSteps = {
  registerUserCode: [
    {
      title: "Dart",
      file: "main.dart",
      language: "dart",
      code: `void onUserLoggedIn(String userId) {
  Shake.registerUser(userId);
}`,
      highlightedLines: [1],
    },
  ],
};

export const rnUsersSetupSteps = {
  registerUserCode: [
    {
      title: "Javascript",
      file: "index.js",
      language: "jsx",
      code: `const onUserLoggedIn = (userId) => {
  Shake.registerUser(userId);
}`,
      highlightedLines: [1],
    },
  ],
};

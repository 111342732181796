import React, { Fragment } from "react";

import ReactPlayer from "react-player";
import { CSSProperties } from "styled-components";

import { BaseDialog, useBaseDialog } from "components/Dialog/BaseDialog";
import Tooltip from "components/Tooltip/Tooltip";

import * as Styled from "./VideoAttachment.styles";

interface Props {
  url?: string;
  width?: number;
  height?: number;
  style?: CSSProperties;
}

export default function VideoAttachment({ url, width, height, style }: Props) {
  const modal = useBaseDialog({});
  const showModal = () => {
    modal.setOpen(true);
  };

  if (!url) return <Fragment />;

  return (
    <Fragment>
      <Tooltip
        text="Play video"
        position="top"
      >
        <Styled.VideoContainer
          onClick={() => showModal()}
          style={style}
        >
          <Styled.Play />
          <ReactPlayer
            url={url}
            style={{ cursor: "pointer", borderRadius: "1rem", objectFit: "cover" }}
            width={width ?? 121}
            height={height ?? 260}
            playing={false}
            type="video/mp4"
            controls={false}
            showInfo={false}
          />
        </Styled.VideoContainer>
      </Tooltip>

      <BaseDialog
        {...modal}
        noBackground
        fullHeight
        noPadding
      >
        <Styled.Flex
          $alignItems="center"
          $justifyContent="center"
        >
          <ReactPlayer
            url={url}
            controls
            playing
            type="video/mp4"
            autoPlay
            style={{ minHeight: "90vh", maxWidth: "90vw" }}
          />
        </Styled.Flex>
      </BaseDialog>
    </Fragment>
  );
}

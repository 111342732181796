import React from "react";

import { AttributesProvider } from "../context/AttributesProvider";
import { UseModuleAttributes } from "../sharedTypes";
import { AddAttributePill } from "./AddAttributePill";
import { AttributePill } from "./AttributePill";
import * as Styled from "./Attributes.styles";
import { OperatorChip } from "./OperatorChip";

interface Props extends UseModuleAttributes {
  className?: string;
  tooltipPosition?: "bottom" | "top" | "right" | "left";
  hideOperator?: boolean;
  searchField?: string;
}

export function Attributes(props: Props) {
  const { attributes, getAttributeOperator } = props;

  return (
    <AttributesProvider moduleAttributes={props}>
      <Styled.AttributesContainerFlex className={props.className}>
        {attributes.length == 0 && (
          <AddAttributePill
            label={props.filterLabel ? props.filterLabel : " filter"}
            tooltipPosition={props.tooltipPosition ?? "bottom"}
            searchField={props.searchField}
          />
        )}
        {attributes.map((attribute, index, arr) => (
          <React.Fragment key={`${index}-fragment`}>
            {getAttributeOperator(index) && (
              <OperatorChip
                key={`${index}-chip`}
                // eslint-disable-next-line  @typescript-eslint/no-non-null-assertion
                operator={getAttributeOperator(index)!} // TODO unwrap
              />
            )}
            <AttributePill
              key={index}
              identifier={index}
              isLastAttribute={index == arr.length - 1}
              isFirstAttribute={index == 0}
              attributeName={attribute.name}
              tooltipPosition={props.tooltipPosition ?? "bottom"}
              label={props.filterLabel}
              hideOperator={props.hideOperator}
            />
          </React.Fragment>
        ))}
      </Styled.AttributesContainerFlex>
    </AttributesProvider>
  );
}

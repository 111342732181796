import { useCallback } from "react";

import Shake from "@shakebugs/browser";

import displayToast from "components/Toast/displayToast";

import { setJoinableWorkspaces, setWorkspacesAction } from "context/App/App.actions";
import { useAppDispatch } from "context/App/App.context";
import { setUserDataAction } from "context/Auth/Auth.actions";
import { useAuthDispatch } from "context/Auth/Auth.context";
import { USER_DATA_LOCAL_STORAGE_KEY } from "context/Auth/Auth.types";

import { useAuthDeps } from "../App.dependencies";

export default function useUserDataApiConsumer() {
  const { authService } = useAuthDeps();

  const authDispatch = useAuthDispatch();
  const appDispatch = useAppDispatch();

  const fetchUserDataAndDispatch = useCallback(async () => {
    try {
      const { data } = await authService.getMe();
      const { teams: workspaces, joinable_teams: joinableWorkspaces, ...userData } = data;

      Shake.setMetadata("user_id", data.id);
      Shake.setMetadata("user_email", data.email);
      Shake.setMetadata("user_name", data.name);
      localStorage.setItem(USER_DATA_LOCAL_STORAGE_KEY, JSON.stringify(userData));
      authDispatch(setUserDataAction(userData));
      appDispatch(setWorkspacesAction(workspaces));

      appDispatch(setJoinableWorkspaces(joinableWorkspaces));

      return { userData, workspaces };
    } catch (error) {
      displayToast({
        title: "Something went wrong",
        content: error?.response?.data?.message ?? "Please try again.",
      });

      throw error;
    }
  }, [authService, authDispatch, appDispatch]);

  return { fetchUserDataAndDispatch };
}

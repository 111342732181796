import React, { useCallback, useMemo, useState } from "react";

import { InfiniteData, useMutation, useQueryClient } from "@tanstack/react-query";

import { useSpaceToPreviewDialog } from "components/Dialog/SpaceToPreviewDialog";
import { IssueTableRowItem, RowType } from "components/MasterTable/models/MasterTableModel";
import { useFeedbackTable } from "components/ShakeTable/modules/useFeedbackTable";
import { TicketSortOption, UserSortOption } from "components/ShakeTable/ui/SortHeaderCell";
import displayToast, { ToastContent, updateToast } from "components/Toast/displayToast";

import useNotificationsApiConsumer from "consumers/useNotificationsApiConsumer";

import { useAppContext } from "context/App/App.context";
import { useAppSelectionContext } from "context/App/AppSelectionContext";

import { Attribute, UseModuleAttributes } from "hooks/filtering/sharedTypes";
import { useAppBoundStorage } from "hooks/filtering/useAppBoundStorage";
import { prepareFiltersForRequest } from "hooks/filtering/utils";
import { Page, useShakePagination } from "hooks/useShakePaginaton";

import { getTimeSinceLong } from "models/helpers/time/timeSince";
import { Issue } from "models/issueTracking";
import { Member } from "models/Member.model";
import { AppNotification } from "models/notifications";
import { TicketPriority } from "models/TicketPriority.model";
import { TicketStatus } from "models/TicketStatus.model";

import useUserFeedbackAndCrashesSocketConsumer from "pages/shared/consumers/useUserFeedbackSocketConsumer";

import { useUserFeedbackDeps } from "../UserFeedback";

// Used to store in memory ticket status to avoid re-fetching large data.
// Sockets with individual ticket updates not yet available
type MemoryStatus = Record<string, string>;
type MemoryPriority = Record<string, string>;
type MemoryAssignee = Record<string, string>;

const autoClose = 2000;

export default function useUserFeedbackApiConsumer<TData>({
  attributesProps,
}: {
  attributesProps: UseModuleAttributes;
}) {
  const { userFeedbackService } = useUserFeedbackDeps();
  const queryClient = useQueryClient();
  const { wsTeamMembers, socket } = useAppContext();
  const { selectedWorkspace, selectedApp } = useAppSelectionContext();
  const { unreadNotifications, fetchUnreadNotificationsAndDispatch } = useNotificationsApiConsumer();

  const { state: sortOption, setState: setSortOption } = useAppBoundStorage<TicketSortOption | UserSortOption>(
    "shakebugs.feedback_sort",
    "key",
  );

  const [memoryStatus, setMemoryStatus] = useState<MemoryStatus>({});
  const [memoryPriority, setMemoryPriority] = useState<MemoryPriority>({});
  const [memoryAssignee, setMemoryAssignee] = useState<MemoryAssignee>({});

  /// Pagination logic
  const { refetchData, ...pagination } = useShakePagination<Issue, IssueTableRowItem>({
    deps: [selectedWorkspace?.id, selectedApp?.id, attributesProps.validAttributes, sortOption.value],
    fetchFn: ({ deps, signal, pagination }) => {
      const workspaceID = deps[0] as string;
      const appID = deps[1] as string;
      const validAttributes = deps[2] as Array<Required<Attribute>>;
      const sort = deps[3] as TicketSortOption;

      if (Boolean(validAttributes.length)) {
        const filters = prepareFiltersForRequest(validAttributes);
        return userFeedbackService
          .getUserFeedbackTicketsByFilters(
            workspaceID,
            appID,
            pagination.offset,
            pagination.limit,
            filters,
            sort,
            signal,
          )
          .then(({ data }) => {
            return { total: data.total, items: data.items };
          });
      } else {
        return userFeedbackService
          .getUserFeedbackTickets(workspaceID, appID, pagination.offset, pagination.limit, sort, signal)
          .then(({ data }) => {
            return { total: data.total, items: data.items };
          });
      }
    },
    enabled: (deps) => deps.every((dep) => Boolean(dep)),
    select: {
      mapperDeps: [unreadNotifications, memoryStatus, memoryPriority],
      mapper: (items, deps, mapperDeps) => {
        const appID = deps[1] as string;
        const unreadNotifications = mapperDeps[0] as Record<string, AppNotification[]>;
        const memoryStatus = mapperDeps[1] as MemoryStatus;
        const memoryPriority = mapperDeps[2] as MemoryPriority;

        const mappedItems = [] as IssueTableRowItem[];
        items.map((issue: Issue) => {
          if (issue) {
            mappedItems.push(
              mapIssueToTableRowItem(
                issue as Issue,
                appID,
                unreadNotifications ?? {},
                memoryStatus,
                memoryPriority,
                memoryAssignee,
                wsTeamMembers,
              ),
            );
          }
        });
        return mappedItems;
      },
    },
    queryKey: `userFeedback ${selectedApp?.id}`,
  });

  const deps = useMemo(() => {
    return [selectedWorkspace?.id, selectedApp?.id, attributesProps.validAttributes, sortOption.value];
  }, [selectedWorkspace?.id, selectedApp?.id, attributesProps.validAttributes, sortOption.value]);
  const queryKey = `userFeedback ${selectedApp?.id}`;

  const onDeleteRequest = async (items: Array<IssueTableRowItem>) => {
    if (!selectedApp?.id || !selectedWorkspace?.id) return;
    return userFeedbackService
      .bulkDeleteIssues(
        selectedWorkspace.id,
        selectedApp.id,
        items.map((item) => item.id),
      )
      .then(() => {
        displayToast({ content: getDeletedToastMessage(items.length), duration: 3000 });
        refetchData();
        return items.map((item) => item.id);
      })
      .catch(() => {
        displayToast({ title: "Error", content: "Something went wrong. Please try again.", duration: 3000 });
        return [];
      });
  };

  const { mutate: mutateBulkDelete } = useMutation({
    mutationFn: onDeleteRequest,
    onSuccess: (items) => {
      if (!items) return;
      //eslint-disable-next-line
      return queryClient.setQueryData<InfiniteData<TData>>([queryKey, { ...deps, queryKey }], (oldData: any) => ({
        ...oldData,
        pages: oldData.pages.map((page: Page<Issue>) => {
          const pageItems = page.items.filter((ticket) => !items.find((item) => item === ticket.id));
          return { ...page, items: pageItems };
        }),
      }));
    },
  });

  const onMarkAsReadRequest = async (items: Array<IssueTableRowItem>) => {
    if (!selectedApp?.id || !selectedWorkspace?.id) return;

    const toastId = Date.now().toString();

    displayToast({
      id: toastId,
      title: "Action in progress",
      content: "Don't close this tab until the action is done. This may take a few moments.",
      duration: 2000,
    });

    return userFeedbackService
      .bulkMarkAsRead(
        selectedWorkspace.id,
        selectedApp.id,
        items.map((item) => item.id),
      )
      .then(() => {
        // eslint-disable-next-line
        queryClient.setQueryData<InfiniteData<TData>>([queryKey, { ...deps, queryKey }], (oldData: any) => ({
          ...oldData,
          pages: oldData.pages.map((page: Page<Issue>) => {
            const pageItems = page.items.map((ticket) =>
              items.find((item) => item.id === ticket.id)
                ? {
                    ...ticket,
                    showNotificationIndicator: false,
                  }
                : ticket,
            );
            return { ...page, items: pageItems };
          }),
        }));

        updateToast({
          id: toastId,
          options: {
            autoClose: autoClose,
            progress: 0,
            render: <ToastContent content={`${items.length} tickets have been marked as read.`} />,
          },
        });

        fetchUnreadNotificationsAndDispatch(selectedWorkspace?.id);
      })
      .catch(() => {
        updateToast({
          id: toastId,
          options: {
            progress: 0,
            autoClose: autoClose,
            render: <ToastContent content="Error happened while marking selected tickets as read" />,
          },
        });
        return [];
      });
  };

  const onBulkChangePriority = async (
    items: Array<IssueTableRowItem>,
    priority: TicketPriority,
    resetSelection?: () => void,
  ) => {
    if (!selectedApp?.id || !selectedWorkspace?.id) return;

    const toastId = Date.now().toString();

    displayToast({
      id: toastId,
      title: "Action in progress",
      content: "Don't close this tab until the action is done. This may take a few moments.",
      duration: autoClose,
    });

    return userFeedbackService
      .bulkChangePriority(
        selectedWorkspace.id,
        selectedApp.id,
        items.map((item) => item.id),
        priority,
      )
      .then(() => {
        // eslint-disable-next-line
        queryClient.setQueryData<InfiniteData<TData>>([queryKey, { ...deps, queryKey }], (oldData: any) => ({
          ...oldData,
          pages: oldData.pages.map((page: Page<Issue>) => {
            let pageItems: Issue[] = [];

            if (
              attributesProps.validAttributes.find((att) => att.field_name === "priority") &&
              priority !==
                attributesProps.validAttributes.find((att) => att.field_name === "priority")?.filter?.fieldValue
            ) {
              pageItems = page.items.filter((ticket) => !items.find((i) => i.id === ticket.id));
              resetSelection?.();
            } else {
              pageItems = page.items.map((ticket) =>
                items.find((item) => item.id === ticket.id)
                  ? {
                      ...ticket,
                      priority: { priority: priority },
                    }
                  : ticket,
              );
            }
            return { ...page, items: pageItems };
          }),
        }));

        updateToast({
          id: toastId,
          options: {
            autoClose: autoClose,
            progress: 0,
            render: (
              <ToastContent content={`Priority has been successfully changed to ${priority} on selected tickets`} />
            ),
          },
        });

        setTimeout(() => refetchData(), 2000);
      })
      .catch(() => {
        updateToast({
          id: toastId,
          options: {
            progress: 0,
            autoClose: autoClose,
            render: <ToastContent content="Error happened while changing priority on selected tickets" />,
          },
        });

        return [];
      });
  };

  const onBulkChangeStatus = async (
    items: Array<IssueTableRowItem>,
    status: TicketStatus,
    resetSelection?: () => void,
  ) => {
    if (!selectedApp?.id || !selectedWorkspace?.id) return;
    const toastId = Date.now().toString();

    displayToast({
      id: toastId,
      title: "Action in progress",
      content: "Don't close this tab until the action is done. This may take a few moments.",
      duration: autoClose,
    });

    return userFeedbackService
      .bulkChangeStatus(
        selectedWorkspace.id,
        selectedApp.id,
        items.map((item) => item.id),
        status,
      )
      .then(() => {
        // eslint-disable-next-line
        queryClient.setQueryData<InfiniteData<TData>>([queryKey, { ...deps, queryKey }], (oldData: any) => ({
          ...oldData,
          pages: oldData.pages.map((page: Page<Issue>) => {
            let pageItems: Issue[] = [];

            if (
              attributesProps.validAttributes.find((att) => att.field_name === "status") &&
              status !== attributesProps.validAttributes.find((att) => att.field_name === "status")?.filter?.fieldValue
            ) {
              pageItems = page.items.filter((ticket) => !items.find((i) => i.id === ticket.id));
              resetSelection?.();
            } else {
              pageItems = page.items.map((ticket) =>
                items.find((item) => item.id === ticket.id)
                  ? {
                      ...ticket,
                      status: { status: status },
                    }
                  : ticket,
              );
            }
            return { ...page, items: pageItems };
          }),
        }));

        updateToast({
          id: toastId,
          options: {
            autoClose: autoClose,
            progress: 0,
            render: <ToastContent content={`Status has been successfully changed to ${status} on selected tickets`} />,
          },
        });

        setTimeout(() => refetchData(), 2000);
      })
      .catch(() => {
        updateToast({
          id: toastId,
          options: {
            progress: 0,
            autoClose: autoClose,
            render: <ToastContent content="Error happened while changing status on selected tickets" />,
          },
        });
        return [];
      });
  };

  const onBulkChangeAssignee = async (
    items: Array<IssueTableRowItem>,
    assigneeId: string | null,
    resetSelection?: () => void,
  ) => {
    if (!selectedApp?.id || !selectedWorkspace?.id) return;

    const toastId = Date.now().toString();

    displayToast({
      id: toastId,
      title: "Action in progress",
      content: "Don't close this tab until the action is done. This may take a few moments.",
      duration: autoClose,
    });

    return userFeedbackService
      .bulkChangeAssignee(
        selectedWorkspace.id,
        selectedApp.id,
        items.map((item) => item.id),
        assigneeId,
      )
      .then(() => {
        // eslint-disable-next-line
        queryClient.setQueryData<InfiniteData<TData>>([queryKey, { ...deps, queryKey }], (oldData: any) => ({
          ...oldData,
          pages: oldData.pages.map((page: Page<Issue>) => {
            let pageItems: Issue[] = [];

            if (
              attributesProps.validAttributes.find((att) => att.field_name === "assignee_id") &&
              assigneeId !==
                attributesProps.validAttributes.find((att) => att.field_name === "assignee_id")?.filter?.fieldValue
            ) {
              pageItems = page.items.filter((ticket) => !items.find((i) => i.id === ticket.id));
              resetSelection?.();
            } else {
              pageItems = page.items.map((ticket) =>
                items.find((item) => item.id === ticket.id)
                  ? { ...ticket, assignee: { assignee_id: assigneeId } }
                  : ticket,
              );
            }
            return { ...page, items: pageItems };
          }),
        }));

        updateToast({
          id: toastId,
          options: {
            autoClose: autoClose,
            progress: 0,
            render: <ToastContent content="Assignee has been successfully changed on selected tickets" />,
          },
        });

        setTimeout(() => refetchData(), 2000);
      })
      .catch(() => {
        updateToast({
          id: toastId,
          options: {
            progress: 0,
            autoClose: autoClose,
            render: <ToastContent content="Error happened while changing assignee on selected tickets" />,
          },
        });
        return [];
      });
  };

  const onChangeStatus = useCallback(
    async (status: string, ticketID: string) => {
      if (!selectedApp || !selectedWorkspace || !status || !ticketID) return;
      try {
        const { data } = await userFeedbackService.changeStatus(
          selectedWorkspace.id,
          selectedApp.id,
          ticketID,
          status as TicketStatus,
        );
        setMemoryStatus((status) => {
          return {
            ...status,
            [ticketID]: data.status,
          };
        });

        // eslint-disable-next-line
        queryClient.setQueryData<InfiniteData<TData>>([queryKey, { ...deps, queryKey }], (oldData: any) => ({
          ...oldData,
          pages: oldData.pages.map((page: Page<Issue>) => {
            let pageItems: Issue[] = [];

            if (
              attributesProps.validAttributes.find((att) => att.field_name === "status") &&
              status !== attributesProps.validAttributes.find((att) => att.field_name === "status")?.filter?.fieldValue
            ) {
              pageItems = page.items.filter((ticket) => ticket.id !== ticketID);
            } else {
              pageItems = page.items.map((ticket) =>
                ticket.id === ticketID
                  ? {
                      ...ticket,
                      status: { status: status as TicketStatus },
                    }
                  : ticket,
              );
            }
            return { ...page, items: pageItems };
          }),
        }));
      } catch (error) {
        // Display Error only in table flows
        displayToast({ title: "Something went wrong", content: "Please try again." });
      }
      return;
    },
    [selectedApp, selectedWorkspace, userFeedbackService, attributesProps.validAttributes, deps, queryClient, queryKey],
  );

  const onChangePriority = async (priority: string, ticketID: string) => {
    if (!selectedApp || !selectedWorkspace || !priority || !ticketID) return;
    try {
      const { data } = await userFeedbackService.changePriority(
        selectedWorkspace.id,
        selectedApp.id,
        ticketID,
        priority as TicketPriority,
      ); // type
      setMemoryPriority((priority) => {
        return {
          ...priority,
          [ticketID]: data.priority,
        };
      });

      // eslint-disable-next-line
      queryClient.setQueryData<InfiniteData<TData>>([queryKey, { ...deps, queryKey }], (oldData: any) => ({
        ...oldData,
        pages: oldData.pages.map((page: Page<Issue>) => {
          let pageItems: Issue[] = [];

          if (
            attributesProps.validAttributes.find((att) => att.field_name === "priority") &&
            priority !==
              attributesProps.validAttributes.find((att) => att.field_name === "priority")?.filter?.fieldValue
          ) {
            pageItems = page.items.filter((ticket) => ticket.id !== ticketID);
          } else {
            pageItems = page.items.map((ticket) =>
              ticket.id === ticketID
                ? {
                    ...ticket,
                    priority: { priority: priority as TicketPriority },
                  }
                : ticket,
            );
          }
          return { ...page, items: pageItems };
        }),
      }));
    } catch (error) {
      displayToast({ title: "Something went", content: "Please try again." });
    }
    return;
  };

  const onChangeAssignee = useCallback(
    async (assignee: string | null, ticketID: string) => {
      if (!selectedApp || !selectedWorkspace || !ticketID) return;
      try {
        const { data } = await userFeedbackService.changeAssignee(
          selectedWorkspace.id,
          selectedApp.id,
          ticketID,
          assignee,
        );

        setMemoryAssignee((assignee) => {
          return {
            ...assignee,
            [ticketID]: data.assignee_id,
          };
        });

        // eslint-disable-next-line
        queryClient.setQueryData<InfiniteData<TData>>([queryKey, { ...deps, queryKey }], (oldData: any) => ({
          ...oldData,
          pages: oldData.pages.map((page: Page<Issue>) => {
            let pageItems: Issue[] = [];

            if (
              attributesProps.validAttributes.find((att) => att.field_name === "assignee_id") &&
              assignee &&
              assignee !==
                attributesProps.validAttributes.find((att) => att.field_name === "assignee_id")?.filter?.fieldValue
            ) {
              pageItems = page.items.filter((ticket) => ticket.id !== ticketID);
            } else {
              pageItems = page.items.map((ticket) =>
                ticket.id === ticketID
                  ? {
                      ...ticket,
                      assignee: { assignee_id: assignee },
                    }
                  : ticket,
              );
            }
            return { ...page, items: pageItems };
          }),
        }));
      } catch (error) {
        // Display Error only in table flows
        displayToast({ title: "Something went wrong", content: "Please try again." });
      }
      return;
    },
    [selectedApp, selectedWorkspace, userFeedbackService, deps, attributesProps.validAttributes, queryKey, queryClient],
  );

  const onBulkAddNoteSendChat = async (items: Array<IssueTableRowItem>, message: string, isNote?: boolean) => {
    if (!selectedApp?.id || !selectedWorkspace?.id) return;

    const toastId = Date.now().toString();

    displayToast({
      id: toastId,
      title: "Action in progress",
      content: "Don't close this tab until the action is done. This may take a few moments.",
      duration: autoClose,
    });

    return userFeedbackService
      .bulkSendChatNote(
        selectedWorkspace.id,
        selectedApp.id,
        items.map((item) => item.id),
        message,
        isNote,
      )
      .then(() => {
        updateToast({
          id: toastId,
          options: {
            autoClose: autoClose,
            progress: 0,
            render: (
              <ToastContent
                content={
                  isNote
                    ? "Note has been successfully added to the selected tickets"
                    : "Message has been successfully sent to the selected users"
                }
              />
            ),
          },
        });

        refetchData();
      })
      .catch(() => {
        updateToast({
          id: toastId,
          options: {
            progress: 0,
            autoClose: autoClose,
            render: (
              <ToastContent
                content={
                  isNote
                    ? "Error happened while adding a note to the selected tickets"
                    : "Error happened while sending messages to the selected users"
                }
              />
            ),
          },
        });
        return [];
      });
  };

  const onBulkAddTags = async (items: Array<IssueTableRowItem>, tags: string[]) => {
    if (!selectedApp?.id || !selectedWorkspace?.id) return;

    const toastId = Date.now().toString();

    displayToast({
      id: toastId,
      title: "Action in progress",
      content: "Don't close this tab until the action is done. This may take a few moments.",
      duration: autoClose,
    });

    return userFeedbackService
      .bulkAddTags(
        selectedWorkspace.id,
        selectedApp.id,
        items.map((item) => item.id),
        tags,
      )
      .then(() => {
        // eslint-disable-next-line
        queryClient.setQueryData<InfiniteData<TData>>([queryKey, { ...deps, queryKey }], (oldData: any) => ({
          ...oldData,
          pages: oldData.pages.map((page: Page<Issue>) => {
            const pageItems = page.items.map((ticket) =>
              items.find((item) => item.id === ticket.id)
                ? {
                    ...ticket,
                    tags: ticket.tags
                      .concat(
                        tags.map((tag) => {
                          return {
                            name: tag,
                          };
                        }),
                      )
                      .filter((value, index, self) => index === self.findIndex((t) => t.name === value.name)),
                  }
                : ticket,
            );
            return { ...page, items: pageItems };
          }),
        }));
        updateToast({
          id: toastId,
          options: {
            autoClose: autoClose,
            progress: 0,
            render: <ToastContent content={"Tags have been successfully added to the selected tickets"} />,
          },
        });

        refetchData();
      })
      .catch(() => {
        updateToast({
          id: toastId,
          options: {
            progress: 0,
            autoClose: autoClose,
            render: <ToastContent content={"Error happened while adding tags to the selected tickets"} />,
          },
        });
        return [];
      });
  };

  const onBulkMergeAction = async (items: Array<IssueTableRowItem>, item: IssueTableRowItem) => {
    if (!selectedApp?.id || !selectedWorkspace?.id) return;

    const toastId = Date.now().toString();

    displayToast({
      id: toastId,
      title: "Action in progress",
      content: "Don't close this tab until the action is done. This may take a few moments.",
      duration: autoClose,
    });

    return userFeedbackService
      .bulkMergeInto(
        selectedWorkspace.id,
        selectedApp.id,
        items.map((item) => item.id),
        item.id,
      )
      .then(() => {
        // eslint-disable-next-line
        queryClient.setQueryData<InfiniteData<TData>>([queryKey, { ...deps, queryKey }], (oldData: any) => ({
          ...oldData,
          pages: oldData.pages.map((page: Page<IssueTableRowItem>) => {
            const pageItems = page.items
              .filter((ticket) => !items.find((i) => i.id === ticket.id))
              .map((ticket) =>
                ticket.id === item.id
                  ? {
                      ...ticket,
                      linked_issues_count: ticket.linked_issues_count + items.length,
                    }
                  : ticket,
              );
            return { ...page, items: pageItems };
          }),
        }));

        updateToast({
          id: toastId,
          options: {
            autoClose: autoClose,
            progress: 0,
            render: <ToastContent content={"Issues have been successfully merged into the selected ticket"} />,
          },
        });

        setTimeout(() => refetchData(), 2000);
      })
      .catch(() => {
        updateToast({
          id: toastId,
          options: {
            progress: 0,
            autoClose: autoClose,
            render: <ToastContent content={"Error happened while merge selected tickets"} />,
          },
        });
        return [];
      });
  };

  const spaceToPreviewDialogProps = useSpaceToPreviewDialog();

  /// Table props
  const feedbackTableProps = useFeedbackTable({
    data: pagination.mappedData || [],
    hasMore: pagination.hasMore,
    isLoading: pagination.isLoading,
    isFetchingNext: pagination.isFetchingNextPage,
    loadMore: pagination.loadNext,
    onDeleteRequest: mutateBulkDelete,
    onMarkAsReadRequest: onMarkAsReadRequest,
    onBulkChangePriority: onBulkChangePriority,
    onBulkChangeStatus: onBulkChangeStatus,
    onBulkChangeAssignee: onBulkChangeAssignee,
    onBulkAddNoteSendChat: onBulkAddNoteSendChat,
    onBulkAddTags: onBulkAddTags,
    onPreviewClose: spaceToPreviewDialogProps.onPreviewClose,
    onPreviewRequest: spaceToPreviewDialogProps.onPreviewRequest,
    onChangeStatusRequest: onChangeStatus,
    onChangePriorityRequest: onChangePriority,
    onChangeAssigneeRequest: onChangeAssignee,
    onChangeSortOption: (newOption) => {
      if (newOption == sortOption.value) {
        refetchData();
        return;
      }
      setSortOption(newOption);
    },
    selectedSortOption: sortOption.value,
    total: pagination.total,
    onMergeAction: onBulkMergeAction,
  });

  /// Refetch on socket event
  useUserFeedbackAndCrashesSocketConsumer({
    socket,
    refetchItems: refetchData,
  });

  return {
    items: pagination.mappedData,
    loading: pagination.isLoading,
    error: pagination.error,
    hasMore: pagination.hasMore,
    total: pagination.total,
    unreadNotifications,
    refetchData,
    feedbackTableProps,
    spaceToPreviewDialogProps,
  };
}

export const mapIssueToTableRowItem = (
  issue: Issue,
  appId: string,
  unreadNotifications?: Record<string, AppNotification[]>,
  memoryStatus?: MemoryStatus,
  memoryPriority?: MemoryPriority,
  memoryAssignee?: MemoryAssignee,
  members?: Member[],
): IssueTableRowItem => {
  return {
    id: issue.id,
    key: issue.key,
    prettyTitle: issue.pretty_title,
    appId,
    orientation: issue.pretty_device_orientation,
    status: (memoryStatus?.[issue.id] || issue?.status?.status) as Exclude<TicketStatus, TicketStatus.LOCKED>,
    tags: issue.tags ? issue.tags?.sort((a, b) => a.name.localeCompare(b.name)) : [],
    priority: (memoryPriority?.[issue.id] || issue?.priority?.priority) as TicketPriority,
    issue_reported_time: getTimeSinceLong(issue.issue_reported_time),
    showNotificationIndicator: unreadNotifications
      ? unreadNotifications?.[appId]?.some((notification) => notification.issue_id === issue.id)
      : false,
    row_type: RowType.USER_FEEDBACK,
    original: issue,
    assignee: {
      name:
        memoryAssignee?.[issue.id] !== null
          ? getAssignee(memoryAssignee?.[issue.id] ? memoryAssignee[issue.id] : issue.assignee?.assignee_id, members)
              ?.user.name ?? ""
          : "",
      id:
        memoryAssignee?.[issue.id] !== null
          ? getAssignee(memoryAssignee?.[issue.id] ? memoryAssignee[issue.id] : issue.assignee?.assignee_id, members)
              ?.user.id ?? null
          : null,
      picture:
        memoryAssignee?.[issue.id] !== null
          ? getAssignee(memoryAssignee?.[issue.id] ? memoryAssignee[issue.id] : issue.assignee?.assignee_id, members)
              ?.user.picture ?? null
          : null,
    },
    app_user_id: issue.app_user_id,
    linked_issues_count: issue.linked_issues_count,
  };
};

const getDeletedToastMessage = (issuesLength: number): string => {
  if (issuesLength === 1) {
    return `${issuesLength} ticket has been successfully deleted.`;
  }
  return `${issuesLength} tickets have been successfully deleted.`;
};

const getAssignee = (assigneeId: string | null, members?: Member[]) => {
  if (!members) return;
  return members.find((member) => member.id === assigneeId);
};

import React from "react";

import Paragraph from "components/Paragraph/Paragraph";

import { vars } from "styles";

import { usefulLinks } from "../helpers/usefulLinksHelper";
import * as Styled from "./UsefulLinks.styles";

export default function UsefulLinks() {
  return (
    <Styled.UsefulLinksContainer>
      {usefulLinks.map((link, index) => {
        return (
          <Styled.UsefulLink
            key={index}
            href={link.link}
            target={!link.link.includes("javascript") ? "_blank" : undefined}
            rel="noreferrer"
          >
            <img
              width={36}
              height={36}
              src={link.image}
            />
            <Paragraph
              color={vars.colors.grey30}
              style={{ paddingTop: "2rem", paddingBottom: "0.4rem" }}
            >
              {link.title}
            </Paragraph>
            <Paragraph
              fontSize={14}
              style={{ maxWidth: "19.7rem", textAlign: "center" }}
            >
              {link.subtitle}
            </Paragraph>
          </Styled.UsefulLink>
        );
      })}
    </Styled.UsefulLinksContainer>
  );
}

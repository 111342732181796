import React from "react";

import { Route, Routes } from "react-router-dom";

import AuthProtected from "layouts/components/AuthProtected";

import { RouteConfig, RouteConfigType, routesConfig } from "./config/routesConfig";

export default function Router() {
  return <Routes>{mapConfigsToRoutes(routesConfig)}</Routes>;
}

function mapConfigsToRoutes(routeConfigs: RouteConfig[]) {
  return routeConfigs.map((config, key) => mapConfigToRoute(config, key));
}

function mapConfigToRoute(config: RouteConfig, key?: number) {
  const Component = config.element as React.ElementType;
  const element = config.element ? <Component /> : null;
  const protectedElement = <AuthProtected>{element ?? <></>}</AuthProtected>;

  switch (config.type) {
    case RouteConfigType.INDEX:
      return (
        <Route
          key={key}
          index
          element={element}
        />
      );
    case RouteConfigType.PATH:
      return (
        <Route
          key={key}
          path={config.path}
          element={config.protected ? protectedElement : element}
        >
          {config.subRoutes && mapConfigsToRoutes(config.subRoutes)}
        </Route>
      );
    case RouteConfigType.LAYOUT:
      return (
        <Route
          key={key}
          element={element}
        >
          {config.subRoutes && mapConfigsToRoutes(config.subRoutes)}
        </Route>
      );
  }
}

import React, { Fragment } from "react";

import { generatePath, useNavigate, useParams } from "react-router-dom";

import ErrorImage from "assets/images/FTUX/not-found-graphic.png";

import EmptyStateElement from "components/EmptyStateElement/components/EmptyStateElement";
import { Spinner } from "components/Spinner/Spinner";
import Tooltip from "components/Tooltip/Tooltip";

import { useAppSelectionContext } from "context/App/AppSelectionContext";

import { useCustomElementInjection } from "hooks/useCustomElementInjection";

import NavigationCustomElement from "layouts/components/PageNavigation/NavigationCustomElement/NavigationCustomElement";
import { ELEMENT_CONTAINER_ID } from "layouts/components/PageNavigation/PageNavigation";
import { NAV_LIST } from "layouts/components/PageNavigation/PageNavigation";

import { getActivityHistoryHelperText } from "pages/shared/components/ActivityHistoryTable/ActivityHistoryHelpers";
import { ActivityHistoryTable } from "pages/shared/components/ActivityHistoryTable/ActivityHistoryTable";

import { RoutePaths } from "router/config/routePaths";

import { Flex } from "styles/reusable/Flex/Flex.styles";

import useCrashEventApiConsumer from "../consumers/useCrashEventApiConsumer";
import useCrashOverviewApiConsumer from "../consumers/useCrashOverviewApiConsumer";
import * as Styled from "./Crashes.styles";

export default function CrashEventActivityHistoryPageView() {
  const { selectedWorkspace, selectedApp } = useAppSelectionContext();

  const navigate = useNavigate();
  const params = useParams<{
    workspaceSlug: string;
    appKey: string;
    crashReportKey: string;
    crashReportEventId: string;
  }>();

  const consumer = useCrashOverviewApiConsumer({
    selectedWorkspaceId: selectedWorkspace?.id,
    selectedAppId: selectedApp?.id,
    crashReportKey: params.crashReportKey,
  });

  const eventConsumer = useCrashEventApiConsumer({
    teamId: selectedWorkspace?.id,
    appId: selectedApp?.id,
    crashReportKey: params.crashReportKey,
    crashReportEventId: params.crashReportEventId,
  });

  const CustomElement = consumer.crashOverview ? (
    <Fragment>
      <NavigationCustomElement>
        <Styled.NavigationTitle
          $isButton
          onClick={() =>
            navigate(
              generatePath(RoutePaths.CRASH_REPORT_OVERVIEW, {
                workspaceSlug: selectedWorkspace?.slug,
                appKey: selectedApp?.key,
                crashReportKey: params.crashReportKey,
              }),
            )
          }
        >
          <div>
            <p>{consumer.crashOverview?.title ?? "No description"}</p>
          </div>
        </Styled.NavigationTitle>
        <Styled.NavigationTitle $isButton={false}>
          <Flex>
            <p>Activity history</p>

            <div>
              <Tooltip
                text={!eventConsumer.crashEvent?.log ? "No logs to download" : "Download as .log file"}
                position="bottom"
              >
                <Styled.DownloadIcon
                  disabled={!eventConsumer.crashEvent?.log}
                  onClick={() => {
                    if (eventConsumer.crashEvent?.log) eventConsumer.downloadActivityHistory();
                  }}
                  $disabled={!eventConsumer.crashEvent?.log}
                />
              </Tooltip>
            </div>
          </Flex>
        </Styled.NavigationTitle>

        {getActivityHistoryHelperText(selectedApp?.platform)}
      </NavigationCustomElement>
    </Fragment>
  ) : (
    <Fragment />
  );

  useCustomElementInjection({
    parentElementId: NAV_LIST,
    element: CustomElement,
    containerId: ELEMENT_CONTAINER_ID,
    tagName: "li",
  });

  if (consumer.error) {
    return (
      <EmptyStateElement
        title="There was an error loading crash overview. Try refreshing."
        subtitle="The link you followed may be broken, or the app may have been removed."
        imageSrc={ErrorImage}
      />
    );
  }

  if (!params.crashReportEventId) return <Fragment />;

  if (!eventConsumer.crashEvent) {
    return <Spinner />;
  }

  return (
    <ActivityHistoryTable
      ticketKey={params.crashReportEventId}
      hasLogs={Boolean(eventConsumer.crashEvent?.log)}
      type="crash"
    />
  );
}

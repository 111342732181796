import React from "react";

import { generatePath, Link } from "react-router-dom";

import GoogleIcon from "assets/icons/google_icon.svg";

import Button from "components/Button/Button";
import Input from "components/Input/Input";

import FormTitle from "pages/shared/components/Forms/FormTitle/FormTitle";
import { useSignUpFormConsumer } from "pages/SignUp/consumers/useSignUpFormConsumer";

import { RoutePaths } from "router/config/routePaths";

import { t } from "util/translator";

import identifiers from "../../../util/identifiers.json";
import { EmailPrefillState } from "../../ForgotPassword/consumers/useForgotPasswordFormConsumer";
import * as Styled from "./SignUpForm.styles";

interface Props {
  setSuccessEmail: (email: string) => void;
}

export default function SignUpForm({ setSuccessEmail }: Props) {
  const { redirectToGoogle, formState, formError, handleFormChange, handleFormSubmit, loading, isErrorOnSubmit } =
    useSignUpFormConsumer({
      setSuccessEmail,
    });

  const { email, password } = formState;
  const { email: emailError, password: passwordError } = formError;

  function handleChange(e: React.ChangeEvent<HTMLInputElement>): void {
    handleFormChange(e.currentTarget.name, e.currentTarget.value);
  }
  const isButtonDisabled = !email || !password;

  return (
    <>
      <FormTitle
        title="Sign up"
        subtitle="Bug and crash reporting tool for mobile apps that tells you everything — so you don’t have to ask the user."
        titleTestId={identifiers["signUp.header.text"]}
        subtitleTestId={identifiers["signUp.header.paragraph"]}
      />

      <Styled.GoogleSignUpButton
        size="tallerFull"
        onClick={() => redirectToGoogle({})}
        testId={identifiers["signUp.button.continueWithGoogle"]}
      >
        <img
          src={GoogleIcon}
          alt="Google Icon"
          width={20}
        />
        Continue with Google
      </Styled.GoogleSignUpButton>

      <Styled.SignUpForm
        onSubmit={handleFormSubmit}
        noValidate
      >
        <Input
          name="email"
          type="email"
          label={t("Email")}
          value={email}
          error={!!emailError}
          helper={resolveEmailErrorMessage(emailError, isErrorOnSubmit, formState.email)}
          onChange={handleChange}
          testId={identifiers["signUp.input.email"]}
          autoFocus
        />
        <Input
          name="password"
          type="password"
          label={t("password")}
          disabled={loading}
          value={password}
          error={!!passwordError}
          helper={passwordError && passwordError.message}
          onChange={handleChange}
          testId={identifiers["signUp.input.password"]}
        />
        <Button
          size="tallerFull"
          styling="outline"
          type="submit"
          disabled={isButtonDisabled || loading}
          testId={identifiers["signUp.button.registerWithEmail"]}
          tooltip={{
            position: "top",
            text: "Please enter your email and password first",
            disabled: !isButtonDisabled,
          }}
          loadingText={loading && "Signing you up..."}
        >
          Continue with email
        </Button>
      </Styled.SignUpForm>

      <Styled.SignUpFormLinks>
        <p>
          Already have an account?&nbsp;
          <Link
            to={RoutePaths.SIGNIN}
            data-testid={identifiers["signUp.link.signInNow"]}
          >
            Sign in
          </Link>
        </p>

        <p>
          By signing up, you agree to our{" "}
          <a
            href="https://www.shakebugs.com/privacy"
            target="_blank"
            rel="noreferrer"
            data-testid={identifiers["signUp.link.privacyPolicy"]}
          >
            Privacy policy
          </a>{" "}
          and{" "}
          <a
            href="https://www.shakebugs.com/terms"
            target="_blank"
            rel="noreferrer"
            data-testid={identifiers["signUp.link.terms"]}
          >
            Terms
          </a>
          .
        </p>
      </Styled.SignUpFormLinks>
    </>
  );
}

const resolveEmailErrorMessage = (emailError?: Error, isErrorOnSubmit?: boolean, prefillEmail?: string) => {
  if (!emailError) return;

  if (isErrorOnSubmit) {
    return (
      <>
        {emailError.message} If that&apos;s you, you can&nbsp;
        <Link
          to={generatePath(RoutePaths.SIGNIN)}
          state={{ prefillEmail } as EmailPrefillState}
        >
          sign in here.
        </Link>
      </>
    );
  }

  return emailError.message;
};

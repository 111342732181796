import React from "react";

import { AdministrationCard } from "components/AdministrationCard/AdministrationCard";
import Button from "components/Button/Button";
import { GridTable } from "components/GridTable/GridTable";
import { useModal } from "components/Modal/Modal";

import { Customer, Invoice } from "models/billing";

import identifiers from "util/identifiers.json";

import CustomizeBillingModalBody from "../ModalBody/CustomizeBillingModalBody";
import { ReceiptRow } from "./ReceiptRow";

interface ReceiptsCardProps {
  receipts: Invoice[];
  customer: Customer;
}

export default function ReceiptsCard({ receipts, customer }: ReceiptsCardProps): JSX.Element {
  const { Modal: CustomizeBillingModal, toggle, modalProps } = useModal({ size: "narrow" });

  return (
    <AdministrationCard
      title="Receipts"
      subtitle="View and download all your previous receipts here."
      topLeftAccessory={
        <Button
          size="small"
          onClick={toggle}
          testId={identifiers["billing.button.customize"]}
        >
          Customize billing
        </Button>
      }
      testId={identifiers["billing.header.receipts"]}
      isEmpty={!receipts || !Boolean(receipts.length)}
    >
      <GridTable gridTemplateColumns="repeat(4, auto)">
        {receipts.map((receipt) => {
          return (
            <ReceiptRow
              key={receipt.id}
              receipt={receipt}
            />
          );
        })}
      </GridTable>

      <CustomizeBillingModalBody
        customer={customer}
        toggle={toggle}
        Modal={CustomizeBillingModal}
        internalProps={modalProps}
      />
    </AdministrationCard>
  );
}

import React, { useState } from "react";

import { generatePath, matchPath, NavLink, Outlet, useLocation, useParams } from "react-router-dom";

import AppPlaceholderPhoto from "assets/images/shared-icons/app-icon-placeholder.svg";

import Header from "components/Header/Header";
import Icon from "components/Icon/Icon";
import { SubNavigation } from "components/Navigation/SubNavigation/SubNavigation.styles";
import PageContent from "components/PageContent/PageContent";
import Paragraph from "components/Paragraph/Paragraph";
import { ScrollContainer } from "components/ScrollContainer/ScrollContainer";
import { Spinner } from "components/Spinner/Spinner";

import { useAppContext } from "context/App/App.context";
import { useAppSelectionContext } from "context/App/AppSelectionContext";

import RoleProtected from "layouts/components/RoleProtected";

import { App, Workspace } from "models";
import { isAdmin } from "models/Member.model";

import { RoutePaths } from "router/config/routePaths";
import { administrationRouteConfigs, PathRouteConfig, RouteConfigType } from "router/config/routesConfig";

import { vars } from "styles";
import { Flex } from "styles/reusable/Flex/Flex.styles";

import { getOSImage } from "../../util/contentResolvers";
import * as Styled from "./AppsLayout.styles";

export default function AdministrationLayoutView() {
  const { selectedWorkspace } = useAppSelectionContext();
  const location = useLocation();

  const [collapse, setCollapse] = useState(location.pathname.includes("apps") ?? false);

  const { apps } = useAppContext();
  const { appKey } = useParams();

  if (!selectedWorkspace) {
    return <Spinner />;
  }

  const administrationLinks = administrationRouteConfigs
    .filter((routeConfig) => routeConfig.type === RouteConfigType.PATH)
    .map((pathRouteConfig) => {
      const config = pathRouteConfig as PathRouteConfig;
      return mapRouteConfigToNavLink(
        config,
        selectedWorkspace,
        !!matchPath(config.path, location.pathname),
        isAdmin(selectedWorkspace?.role),
        apps,
        appKey,
        collapse,
        setCollapse,
      );
    });

  return (
    <>
      <Header>{selectedWorkspace?.name} workspace administration</Header>
      <Flex style={{ flex: 1, paddingLeft: "2rem", height: "100%" }}>
        <ScrollContainer>
          <Flex>
            <div style={{ marginBottom: "4rem" }}>
              <SubNavigation>
                <ul>{administrationLinks}</ul>
              </SubNavigation>
            </div>
            <RoleProtected>
              <div style={{ width: "100%" }}>
                <PageContent>
                  <Outlet />
                </PageContent>
              </div>
            </RoleProtected>
          </Flex>
        </ScrollContainer>
      </Flex>
    </>
  );
}

const mapRouteConfigToNavLink = (
  config: PathRouteConfig,
  workspace: Workspace,
  isActive: boolean,
  isUserAdmin: boolean,
  apps: App[],
  activeAppKey?: string,
  collapse?: boolean,
  setCollapse?: (collapse: boolean) => void,
) => {
  const to = generatePath(config.path, { workspaceSlug: workspace.slug });

  if (config.protected && !isUserAdmin) return;

  const isAppActive = config.path.includes("apps") && collapse;

  const hasApps = apps?.length > 0;
  const hasActiveApps = Boolean(apps.find((a) => a.active));
  const hasArchivedApps = Boolean(apps.find((a) => !a.active));

  return (
    <>
      <li
        key={config.name}
        className={isActive ? "active" : ""}
      >
        {!config.path.includes("apps") ? (
          <NavLink
            to={to}
            data-testid={config.testId}
          >
            {config.name}
            <Icon
              icon={isAppActive ? "caret-down" : "caret-right"}
              size={10}
            />
          </NavLink>
        ) : (
          <Styled.BaseParagraph
            onClick={() => setCollapse?.(!collapse)}
            $active={isAppActive}
          >
            Apps
            <Icon
              icon={isAppActive ? "caret-down" : "caret-right"}
              size={10}
            />
          </Styled.BaseParagraph>
        )}
      </li>

      {isAppActive && hasApps && (
        <Styled.Container>
          {hasActiveApps && (
            <>
              <Styled.Subtitle
                fontSize={12}
                color={vars.colors.grey70}
                $paddingTop
              >
                Active
              </Styled.Subtitle>
              {getAppList(
                apps.filter((a) => a.active),
                workspace.slug,
                activeAppKey,
              )}
            </>
          )}

          {hasArchivedApps && (
            <>
              <Styled.Subtitle
                fontSize={12}
                color={vars.colors.grey70}
                $paddingTop
              >
                Inactive
              </Styled.Subtitle>
              {getAppList(
                apps.filter((a) => !a.active),
                workspace.slug,
                activeAppKey,
              )}
            </>
          )}
        </Styled.Container>
      )}
      {isAppActive && !hasApps && (
        <Styled.Container>
          <Styled.Subtitle
            fontSize={12}
            color={vars.colors.grey70}
            $paddingTop
          >
            No apps
          </Styled.Subtitle>
        </Styled.Container>
      )}
    </>
  );
};

const getAppList = (apps: App[], workspaceSlug: string, activeAppKey?: string) => {
  return apps.map((app, index) => {
    return (
      <li
        key={index}
        className={app.key === activeAppKey ? "active" : ""}
      >
        <NavLink
          style={{ fontSize: "1.4rem", paddingTop: "0.9rem", paddingBottom: "1.1rem" }}
          to={generatePath(RoutePaths.ADMINISTRATION_APPS_GENERAL_KEY, {
            workspaceSlug: workspaceSlug,
            appKey: app.key,
          })}
        >
          <Flex $alignItems="center">
            <img
              src={app.logo_url ?? AppPlaceholderPhoto}
              width={28}
              height={28}
              style={{ borderRadius: "0.8rem", objectFit: "cover", marginRight: "0.8rem" }}
            />
            <Paragraph fontSize={14}>{app.name}</Paragraph>
            <img
              src={getOSImage(app.platform)}
              style={{ marginLeft: "0.4rem" }}
              width={20}
              height={20}
            />
          </Flex>
        </NavLink>
      </li>
    );
  });
};

import React from "react";

import { generatePath, matchPath, NavLink, Outlet, useLocation } from "react-router-dom";

import Header from "components/Header/Header";
import Icon from "components/Icon/Icon";
import { SubNavigation } from "components/Navigation/SubNavigation/SubNavigation.styles";
import PageContent from "components/PageContent/PageContent";
import Paragraph from "components/Paragraph/Paragraph";
import { ScrollContainer } from "components/ScrollContainer/ScrollContainer";
import { Spinner } from "components/Spinner/Spinner";

import { useAppContext } from "context/App/App.context";
import { useAppSelectionContext } from "context/App/AppSelectionContext";

import RoleProtected from "layouts/components/RoleProtected";
import { NotificationIndicator } from "layouts/components/Sidebar/Sidebar.styles";

import { Workspace } from "models";

import { RoutePaths } from "router/config/routePaths";
import { homeRouteConfigs, PathRouteConfig, RouteConfigType } from "router/config/routesConfig";

import { vars } from "styles";
import { Flex } from "styles/reusable/Flex/Flex.styles";

export default function HomeLayoutView() {
  const { selectedWorkspace } = useAppSelectionContext();
  const { unreadChangeLogNotifications } = useAppContext();
  const location = useLocation();

  if (!selectedWorkspace) {
    return <Spinner />;
  }

  const homeLinks = homeRouteConfigs
    .filter((routeConfig) => routeConfig.type === RouteConfigType.PATH)
    .map((pathRouteConfig) => {
      const config = pathRouteConfig as PathRouteConfig;
      return mapRouteConfigToNavLink(
        config,
        selectedWorkspace,
        !!matchPath(
          config.path,
          location.pathname.includes("home/how-to/")
            ? RoutePaths.HOW_TO_ROOT
            : location.pathname.includes("/home/features/")
            ? RoutePaths.FEATURES_ROOT
            : location.pathname,
        ),
        config.path.includes("features") ? unreadChangeLogNotifications.length : undefined,
      );
    });

  return (
    <>
      <Header>{selectedWorkspace?.name} home</Header>
      <Flex style={{ flex: 1, paddingLeft: "2rem", height: "100%" }}>
        <SubNavigation>
          <ul>{homeLinks}</ul>
        </SubNavigation>
        <RoleProtected>
          <ScrollContainer>
            <PageContent width={1200}>
              <div style={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
                <Outlet />
              </div>
            </PageContent>
          </ScrollContainer>
        </RoleProtected>
      </Flex>
    </>
  );
}

const mapRouteConfigToNavLink = (
  config: PathRouteConfig,
  workspace: Workspace,
  isActive: boolean,
  unreadNotif?: number,
) => {
  const to = generatePath(config.path, { workspaceSlug: workspace.slug });

  if (config.protected) return;

  return (
    <li
      key={config.name}
      className={isActive ? "active" : ""}
    >
      <NavLink
        to={to}
        data-testid={config.testId}
      >
        {config.name}

        {unreadNotif ? (
          <NotificationIndicator
            style={{
              top: 0,
              width: "2.2rem",
              height: "2.2rem",
              right: "4.4rem",
              position: "absolute",
              marginTop: "1.3rem",
            }}
          >
            <Paragraph
              color={vars.colors.white}
              fontSize={12}
            >
              {unreadNotif}
            </Paragraph>
          </NotificationIndicator>
        ) : undefined}

        <Icon
          icon="caret-right"
          size={10}
        />
      </NavLink>
    </li>
  );
};

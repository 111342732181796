import { ApiKey } from "./ApiKey.model";
import { Certificate } from "./Certificate.model";
import {
  Asana,
  Azure,
  Clickup,
  Github,
  Integration,
  Jira,
  Sentry,
  Slack,
  Trello,
  Youtrack,
  Zapier,
} from "./integrations";
import { Linear } from "./integrations/Linear.model";
import { Webhook } from "./integrations/Webhook.model";
import { Platform } from "./Platform.model";

export interface App {
  created: string;
  updated: string;
  id: string;
  bundle_id: string;
  team_id: string;
  name: string;
  title: string;
  description: string;
  key: string;
  platform: Platform;
  api_keys: Array<ApiKey>;
  jira?: Jira;
  slack?: Slack;
  youtrack?: Youtrack;
  azure?: Azure;
  github?: Github;
  asana?: Asana;
  clickup?: Clickup;
  linear?: Linear;
  zapier?: Zapier;
  trello?: Trello;
  notify_me: boolean;
  follow_app?: boolean;
  integrations: Array<Integration>;
  bug_reports_count: number;
  is_approved: boolean;
  is_sample: boolean;
  is_development: boolean;
  active: boolean;
  certificate: Certificate;
  logo_url?: string;
  webhook?: Webhook;
}

export interface BasicAppNotificationSettings {
  id: string;
  name: string;
  follow_app: boolean;
  logo_url?: string | null;
}

export const mapAppToBasicNotificationSettings = (app: App): BasicAppNotificationSettings => {
  return { id: app.id, name: app.name, follow_app: !!app.follow_app, logo_url: app.logo_url };
};

import React, { useEffect, useState } from "react";

import Button from "components/Button/Button";
import { useModal } from "components/Modal/Modal";
import Paragraph from "components/Paragraph/Paragraph";
import RoundedCanvas from "components/RoundedCanvas/RoundedCanvas";
import Tooltip from "components/Tooltip/Tooltip";

import UploadFileModalBody from "pages/Administration/components/ModalBody/UploadFileModalBody";

import { vars } from "styles";
import { Flex } from "styles/reusable/Flex/Flex.styles";

import * as Styled from "./LogoComponent.styles";

interface Props {
  title: string;
  subtitle: string;
  image?: string;
  placeholderImage?: string;
  backgroundColor?: string;
  textColor?: string;
  letters?: string;
  onUpdatePicture?: (picture: File, toggle: () => void) => void;
  onDeletePicture?: (toggle: () => void) => void;
  loading?: boolean;
  squareCanvas?: boolean;
  canChange?: boolean;
}

export default function LogoComponent({
  title,
  subtitle,
  image,
  placeholderImage,
  backgroundColor,
  textColor,
  letters,
  onUpdatePicture,
  onDeletePicture,
  loading,
  squareCanvas,
  canChange,
}: Props) {
  const { Modal: RemoveModal, modalProps: removeInternal, toggle: toggleRemove } = useModal({ size: "narrow" });

  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const {
    Modal: UploadPicture,
    toggle: toggleUpload,
    modalProps: uploadMappingInternal,
    active,
  } = useModal({ size: "narrow" });

  useEffect(() => {
    if (!active) setSelectedFile(null);
  }, [active]);

  return (
    <>
      <Styled.Component>
        <Paragraph color={vars.colors.grey30}>{title}</Paragraph>
        <Paragraph fontSize={14}>{subtitle}</Paragraph>

        <Flex
          style={{ paddingTop: "1.2rem" }}
          $alignItems="center"
        >
          <RoundedCanvas
            image={image ? image : placeholderImage ? placeholderImage : undefined}
            bgColor={backgroundColor}
            txtColor={textColor}
            letter={letters}
            size="large"
            imageSize={60}
            square={squareCanvas}
          />
          <Tooltip
            style={{ width: "9.9rem" }}
            disabled={canChange}
            text="Only admins can upload and change logo"
            position="top"
          >
            <Styled.Button
              size="xxSmall"
              onClick={toggleUpload}
              disabled={!canChange}
            >
              {image ? "Change" : "Upload"}
            </Styled.Button>
          </Tooltip>

          {image && (
            <Styled.DeleteButton
              size="xxSmall"
              color="red"
              onClick={toggleRemove}
              disabled={!canChange}
            >
              Delete
            </Styled.DeleteButton>
          )}
        </Flex>
      </Styled.Component>

      <RemoveModal
        {...removeInternal}
        heading={`Remove photo?`}
        subtitle={`If you're sure you want to remove photo, just click the magic button below.`}
        buttonElement={
          <Button
            onClick={() => onDeletePicture?.(toggleRemove)}
            size="small"
            color="red"
            disabled={loading}
            loadingText={loading && "Removing..."}
          >
            Yes, remove
          </Button>
        }
      />

      <UploadPicture
        {...uploadMappingInternal}
        heading={"Upload photo"}
        buttonElement={
          <Button
            onClick={() => selectedFile && onUpdatePicture?.(selectedFile, toggleUpload)}
            size="small"
            disabled={loading || !selectedFile}
            loadingText={loading && "Uploading..."}
            tooltip={{
              text:
                !selectedFile && image
                  ? "Please change a logo first"
                  : !selectedFile && !image
                  ? "Please choose a logo first"
                  : "",
              disabled: !!selectedFile,
              position: "top",
            }}
          >
            Upload
          </Button>
        }
      >
        <UploadFileModalBody
          handleSelectedFile={setSelectedFile}
          acceptedFileTypes={{ "image/jpeg": [".jpeg", ".png"] }}
          imageSrc={selectedFile ? URL.createObjectURL(selectedFile) : image ? image : undefined}
          dropzoneText={
            selectedFile ? selectedFile.name : "Click to choose a .jpeg or .png file or drag it here to upload"
          }
        />
      </UploadPicture>
    </>
  );
}

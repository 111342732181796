import React, { PropsWithChildren } from "react";

import { CSSProperties } from "styled-components";

import * as Styled from "./Paragraph.styles";

export interface ParagraphProps {
  fontWeight?: "normal" | 500 | "bold" | 400;
  p1?: boolean;
  p2?: boolean;
  p3?: boolean;
  fontSize?: number;
  lineHeight?: number;
  marginBottom?: number;
  marginTop?: number;
  color?: string;
  className?: string;
  testId?: string;
  size?: "tiny" | "small"; // remove when storybook is removed from the app
  style?: CSSProperties;
}

export default function Paragraph({
  p1,
  p2,
  p3,
  lineHeight,
  fontSize,
  fontWeight,
  marginBottom,
  marginTop,
  color,
  className,
  testId,
  children,
  style,
}: PropsWithChildren<ParagraphProps>) {
  return (
    <Styled.Paragraph
      style={style}
      className={className}
      $p1={p1}
      $p2={p2}
      $p3={p3}
      $fontSize={fontSize}
      $lineHeight={lineHeight}
      $color={color}
      $marginBottom={marginBottom}
      $marginTop={marginTop}
      $fontWeight={fontWeight}
      data-testid={testId}
    >
      {children}
    </Styled.Paragraph>
  );
}

import { darken } from "polished";

import { vars } from "../abstracts/vars";
import { Theme } from "./Theme.model";

export const ThemeLight: Theme = {
  // TODO: Light theme colors
  colorPrimary: vars.colors.purple,
  colorPrimaryDarken: darken(0.05, vars.colors.purple),
  colorSecondary: vars.colors.green,
  colorSecondaryDarken: darken(0.05, vars.colors.green),
  colorBackground: vars.colors.white,
  colorSurface: vars.colors.grey100,
  colorText: vars.colors.white,
  colorError: vars.colors.red,
};

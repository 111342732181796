import React from "react";

import Heading from "components/Heading/Heading";
import Input from "components/Input/Input";
import PageWrap from "components/PageWrap/PageWrap";
import Paragraph from "components/Paragraph/Paragraph";
import { ToggleSection } from "components/ToggleSection/ToggleSection";

import { Platform, PlatformName } from "models";

import { useAddAppConsumer } from "pages/AddApp/consumers/useAddAppConsumer";

import { Flex } from "styles/reusable/Flex/Flex.styles";

import * as Styled from "./AddAppCreateApp.styles";
import AddAppLimitBanner from "./LimitBanner/AddAppLimitBanner";

interface Props {
  platform?: Platform;
}

export function AddAppCreateApp({ platform }: Props) {
  const { formState, formError, handleFormChange, handleFormSubmit, loading } = useAddAppConsumer({
    platformId: platform?.id ?? "",
  });

  const { bundleId, appName, isDevelopment } = formState;
  const { bundleId: bundleIdError } = formError;

  function handleChange(e: React.KeyboardEvent<HTMLInputElement>): void {
    handleFormChange(e.currentTarget.name, e.currentTarget.value);
  }

  const isButtonDisabled = !bundleId;

  const isWeb = platform?.name === PlatformName.WEB;

  return (
    <PageWrap style={{ display: "flex", flex: 1, justifyContent: "center" }}>
      <Styled.Container>
        <Heading
          className={"center"}
          heading1
          marginBottom={24}
        >
          {isWeb ? "Lastly, your domain" : "Lastly, your bundle ID"}
        </Heading>
        <Paragraph
          marginBottom={28}
          fontWeight={500}
        >
          {`Shake servers will start listening for your ${isWeb ? "website" : "app"} to ping them.`}
        </Paragraph>
        {!isWeb ? <AddAppLimitBanner /> : null}
        <Styled.InputWrapper>
          <Input
            id="appName"
            name="appName"
            type="text"
            label={"App name"}
            value={appName ?? ""}
            placeholder="e.g. Uber, Pinterest, Yourapp"
            onChange={handleChange}
            helper={`Optional, but ${isWeb ? "a website" : "an app"} with a name looks nicer here on Dashboard`}
          />
        </Styled.InputWrapper>
        <Styled.InputWrapper>
          <Input
            id="bundleId"
            name="bundleId"
            type="text"
            label={isWeb ? "Domain" : "Bundle ID"}
            value={bundleId}
            placeholder={isWeb ? "e.g. docs.yoursite.com" : "e.g. com.yourdomain.appname"}
            onChange={handleChange}
            error={!!bundleIdError}
            helper={
              bundleIdError
                ? bundleIdError.message
                : isWeb
                ? "Add new website for every domain prefix: app., www., docs."
                : undefined
            }
          />
        </Styled.InputWrapper>
        {platform?.name === PlatformName.WEB && (
          <Flex
            $justifyContent="start"
            style={{ width: "100%", paddingBottom: "2.4rem" }}
          >
            <ToggleSection
              title="Localhost build"
              subtitle="Turn on  only  for localhost version. Otherwise leave turned off"
              disabled={loading}
              checked={isDevelopment}
              onChange={() => handleFormChange("isDevelopment", !isDevelopment)}
            />
          </Flex>
        )}
        <Styled.CreateButton
          loadingText={loading && "Adding..."}
          disabled={isButtonDisabled}
          tooltip={{
            position: "top",
            text: `${isWeb ? "Please enter domain first" : "Please enter bundle ID first"}`,
            disabled: !isButtonDisabled,
          }}
          testId="add-app"
          onClick={handleFormSubmit}
        >
          {isWeb ? "Add a website" : "Add an app"}
        </Styled.CreateButton>
      </Styled.Container>
    </PageWrap>
  );
}

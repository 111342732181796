import React, { Fragment } from "react";

import { CSSProperties } from "styled-components";

import { BaseDialog, useBaseDialog } from "components/Dialog/BaseDialog";
import Tooltip from "components/Tooltip/Tooltip";

import { Flex } from "styles/reusable/Flex/Flex.styles";

import * as Styled from "./ImageAttachment.styles";

interface Props {
  url?: string;
  style?: CSSProperties;
}

export const ImageAttachment = ({ url, style }: Props) => {
  const modal = useBaseDialog({});
  const showModal = () => {
    modal.setOpen(true);
  };

  const hideModal = () => {
    modal.setOpen(false);
  };
  if (!url) return <Fragment />;

  return (
    <Tooltip
      text="Zoom"
      position="top"
    >
      <Styled.ImageContainer style={style}>
        <Styled.Image
          onClick={() => url && showModal()}
          src={url}
          alt="screenshot"
        />

        <BaseDialog
          {...modal}
          noBackground
          fullHeight
          size="wide"
          noPadding
        >
          <Flex
            $alignItems="center"
            $justifyContent="center"
            onClick={() => hideModal()}
          >
            <Styled.ModalImage
              src={url}
              alt="screenshot"
            />
          </Flex>
        </BaseDialog>
      </Styled.ImageContainer>
    </Tooltip>
  );
};

import React, { createRef, useEffect, useState } from "react";

import Textarea, { TextareaAutosizeProps } from "react-textarea-autosize";

import Paragraph from "components/Paragraph/Paragraph";

import { vars } from "styles";
import { Flex } from "styles/reusable/Flex/Flex.styles";

import { omitObjectKeys } from "util/omitProps";

import * as Styled from "./Input.styles";

export enum InputElementType {
  INPUT = "input",
  TEXTAREA = "textarea",
}

export interface InputProps extends React.HTMLProps<HTMLInputElement> {
  elementType?: InputElementType.INPUT;
  innerRef?: React.RefObject<HTMLInputElement>;
  testId?: string;
}

export interface TextareaProps extends TextareaAutosizeProps {
  elementType?: InputElementType.TEXTAREA;
  innerRef?: React.RefObject<HTMLTextAreaElement>;
  testId?: string;
}

export type TextInputProps = InputProps | TextareaProps;

export interface InputWrapperProps {
  error?: boolean;
  withCounter?: boolean;
  label?: string;
  helper?: string | JSX.Element;
  withSendButton?: boolean;
  actionIcon?: JSX.Element;
  testId?: string;
  onSendButtonClick?: () => void;
  icontestid?: string;
  iconComponent?: JSX.Element;
  editableTitle?: boolean;
  toggleButton?: JSX.Element;
  sendButtonText?: string;
  maxHeight?: number;
  onCopyClick?: () => void;
}

const resolveInputElement = (textInputProps: TextInputProps) => {
  switch (textInputProps.elementType) {
    case InputElementType.TEXTAREA: {
      return (
        <Textarea
          {...omitObjectKeys(textInputProps, ["elementType", "innerRef"])}
          ref={textInputProps.innerRef}
          rows={textInputProps.rows}
          minRows={textInputProps.rows}
          data-testid={textInputProps.testId}
        />
      );
    }
    case InputElementType.INPUT:
      return (
        <input
          {...omitObjectKeys(textInputProps, ["elementType", "innerRef"])}
          ref={textInputProps.innerRef}
          data-testid={textInputProps.testId}
        />
      );
    default: {
      const inputProps = textInputProps as InputProps;
      return (
        <input
          {...omitObjectKeys(inputProps, ["elementType", "innerRef"])}
          ref={inputProps.innerRef}
          data-testid={textInputProps.testId}
        />
      );
    }
  }
};

type FocusEventIntersectionType = React.FocusEvent<HTMLInputElement> & React.FocusEvent<HTMLTextAreaElement>;

export default function Input(textInputProps: TextInputProps & InputWrapperProps) {
  const {
    label,
    error,
    helper,
    withCounter,
    onFocus,
    onBlur,
    withSendButton,
    onSendButtonClick,
    autoFocus,
    testId,
    readOnly,
    disabled,
    icontestid,
    iconComponent,
    editableTitle,
    actionIcon,
    toggleButton,
    sendButtonText,
    maxHeight,
    onCopyClick,
  } = textInputProps;

  const [focus, setFocus] = useState<boolean>(!!autoFocus);
  const [hover, setHover] = useState<boolean>(false);
  const [count, setCount] = useState<number>(0);
  const inputRef = createRef<HTMLInputElement>();

  const handleFocus = (e: FocusEventIntersectionType) => {
    e.target.value = e.target.value; // https://stackoverflow.com/questions/58201291/chrome-autocomplete-lock-inputs-like-they-are-not-clickable#:~:text=This%20is%20an,browser%2Drelated%20behavior.
    setFocus(true);
    onFocus?.(e);
  };

  const handleDefocus = (e: FocusEventIntersectionType) => {
    setFocus(false);
    onBlur?.(e);
  };

  const setInputFocus = () => {
    inputRef.current?.focus();
  };

  useEffect(() => {
    if (withCounter && inputRef.current) {
      setCount(inputRef.current.value.length);
    }
  }, [inputRef, withCounter]);

  const innerProps = omitObjectKeys(textInputProps, [
    "error",
    "withCounter",
    "label",
    "helper",
    "withSendIcon",
    "onSendIconClick",
  ]);

  return (
    <Styled.FormInputWrap
      $error={error}
      onClick={onCopyClick ? onCopyClick : setInputFocus}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onMouseOver={() => setHover(true)}
      className={`input ${textInputProps.className}`}
      $disabled={disabled}
    >
      <Styled.FormInput
        $error={error}
        $active={focus}
        $hover={hover}
        $withCounter={withCounter}
        $readOnly={readOnly}
        $disabled={disabled}
        $editableTitle={editableTitle}
        $maxHeight={maxHeight ? (maxHeight + 2).toString() : undefined}
      >
        <Flex $justifyContent="space-between">
          <Styled.InputContainer $noMargin={!!(toggleButton && withSendButton)}>
            {label && <label>{label}</label>}
            {withCounter && <span className="counter">{count}</span>}

            {resolveInputElement({
              innerRef: inputRef,
              ...innerProps,
              onFocus: handleFocus,
              onBlur: handleDefocus,
              testId: testId,
              autoFocus: autoFocus,
            })}
            <Flex
              $justifyContent="space-between"
              $alignItems={withSendButton ? "center" : undefined}
              style={{ cursor: "default" }}
            >
              {actionIcon && actionIcon}
              <Flex>
                {toggleButton && toggleButton}
                {withSendButton && sendButtonText && (
                  <Flex
                    $justifyContent="flex-end"
                    style={{ width: "10rem" }}
                  >
                    <Styled.Button
                      onClick={onSendButtonClick}
                      data-testid={icontestid}
                      onFocus={(e) => {
                        e.target.value = e.target.value;
                        setFocus(true);
                        e.stopPropagation();
                        e.preventDefault();
                      }}
                    >
                      <Paragraph
                        color={vars.colors.white}
                        fontSize={14}
                      >
                        {sendButtonText}
                      </Paragraph>
                    </Styled.Button>
                  </Flex>
                )}
              </Flex>
            </Flex>
          </Styled.InputContainer>

          {iconComponent && (
            <Flex
              $alignItems="center"
              $justifyContent="center"
            >
              {iconComponent}
            </Flex>
          )}
        </Flex>
      </Styled.FormInput>
      {helper && (
        <p
          className="tiny helper"
          data-testid={testId + "_error"}
        >
          {helper}
        </p>
      )}
    </Styled.FormInputWrap>
  );
}

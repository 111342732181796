import { Environment } from "../Environment.model";

export const demo: Environment = {
  production: true,
  name: "demo",
  apiHost: "https://api.demo.shakebugs.com/api/1.0/",
  socketioHost: "https://api.demo.shakebugs.com",
  shakeSDKBundleId: "app.shakebugs.com",
  shakeSDKApiKey: "pgJOrIGcjU7pgV6Vg1lSjZdmaggfjQ31BRL0yLfUYGc70L9YQEXYmxx",
  oAuthClientId: "shake_dashboard",
  oAuthClientSecret: "CJMhufrMiq1M3IKILF3emKi5Y06bFbtn",
  oAuthHost: "https://api.demo.shakebugs.com/auth/oauth2/",
  oAuthDemoClientId: "",
  oAuthDemoClientSecret: "",
  segmentWriteKey: "T1WcTo0VWBnxCNYuGx1kxy2zLAXboegS",
  stripeKey: "pk_test_yiG31OCFtb3oHUuAPXC53WOS",
  sentryDsn: "https://107ce352dff84220bd0ff18a652089dd@o257007.ingest.sentry.io/5312361",
  appURL: "https://demo.shakebugs.com",
  wwwURL: "https://www.shakebugs.com",
  documentationUrl: "https://www.shakebugs.com/docs",
  issuesLimit: 20,
  googleAuthEnabled: true,
  youTrackEnabled: false,
  azureEnabled: true,
  zapierEnabled: true,
  trelloEnabled: true,
  trelloAppKey: "1c2affc5f6df8f2b8e567309114f52f5",
  asanaEnabled: true,
  asanaClientId: "1185708886795986",
  githubEnabled: true,
  githubClientId: "120592cda38e22d430f0",
  clickupEnabled: true,
  clickupClientId: "5M4EA40TZA62PJ5JYZ434BNXKLBMMI43",
  cannyUrl: "https://feedback.shakebugs.com",
  logsEnabled: true,
  logsCustomLogsEnabled: true,
  logsNotificationsTrackingEnabled: true,
  blackboxEnabled: true,
  notificationsEnabled: true,
  unreadNotificationsEmailEnabled: false,
  tagsEnabled: true,
  deepSearchEnabled: true,
  billingEnabled: false,
  billingInvoicesEnabled: false,
  crashReportingEnabled: true,
  googleClientId: "846486073736-ss5r3c6tb91b1pdccdh2en1nl76pdv6a.apps.googleusercontent.com",
  jiraCloudClientId: "VnTxVsN1YETL3AL41gEJYIf7adHcElDy",
  linearClientId: "2b8038a74b9e80ffc1ca9bf66d8f73a4",
  sentryEnabled: false,
  sentryClientId: "",
};

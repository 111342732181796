import React, { useEffect } from "react";

import { Outlet, useLocation } from "react-router-dom";

export function AppLevelLayout() {
  usePageTracking();
  return <Outlet />;
}

const usePageTracking = () => {
  const location = useLocation();

  useEffect(() => {
    if (window.analytics) window.analytics.page(location);
  }, [location]);
};
